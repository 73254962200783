<template>
<div class="row" v-if="isValid('CanViewDraftOrder') || isValid('CanViewPostOrder')|| isValid('CanViewSupplierQuotation') || (isValid('CanViewInProcessOrder') && isValid('CanAllowOrderVersion'))">
    <div class="col-lg-12 col-sm-12">
        <div class="row">
            <div class="col-sm-12">
                <div class="page-title-box">
                    <div class="row">
                        <div class="col">
                            <h4 class="page-title" v-if="formName == 'SupplierQuotation'">{{ $t('PurchaseOrder.SupplierQuotation') }}</h4>
                            <h4 class="page-title" v-else>{{ $t('PurchaseOrder.PurchaseOrder') }}</h4>
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="javascript:void(0);">{{$t('PurchaseOrder.Home')}}</a></li>
                                <li class="breadcrumb-item active" v-if="formName == 'SupplierQuotation'">{{ $t('PurchaseOrder.SupplierQuotation') }}</li>
                                <li class="breadcrumb-item active" v-else>{{ $t('PurchaseOrder.PurchaseOrder') }}</li>
                            </ol>
                        </div>
                        <div class="col-auto align-self-center">
                            <a v-on:click="AddPurchaseOrder" v-if="isValid('CanViewDraftOrder') || isValid('CanAddPurchaseOrder') || (isValid('CanViewInProcessOrder') && isValid('CanAllowOrderVersion'))"  href="javascript:void(0);" class="btn btn-sm btn-outline-primary mx-1">
                                <i class="align-self-center icon-xs ti-plus"></i>
                                {{ $t('PurchaseOrder.AddNew') }}
                            </a>
                            <a v-on:click="GotoPage('/StartScreen')" href="javascript:void(0);" class="btn btn-sm btn-outline-danger">
                                {{ $t('PurchaseOrder.Close') }}
                            </a>
                        </div>
                    </div>
                   
                    </div>
                </div>
            </div>

            <div class="card ">

            </div>

            <div class="card">
                <div class="card-header">
                    <div class="row align-items-center">
                        <div class="col-md-8 col-sm-12 " style="padding-top:20px">
                            <div class="input-group">
                                <button class="btn btn-secondary" type="button" id="button-addon1">
                                    <i class="fas fa-search"></i>
                                </button>
                                <input v-model="search" type="text"  class="form-control" placeholder="Search By PO Number, Supplier Name or Date " aria-label="Example text with button addon" aria-describedby="button-addon1, button-addon2">
                                <a class="btn btn-soft-primary" v-on:click="AdvanceFilterFor()" id="button-addon2" value="Advance Filter">
                                    <i class="fa fa-filter"></i>
                                </a>
                            </div>
                        </div>

                        <div class=" col-lg-4 mt-1" v-if="!advanceFilters">

<button @keyup.enter="search22()" type="button" class="btn btn-outline-primary mt-3">
    {{ $t('Sale.ApplyFilter') }}
</button>
<button v-on:click="clearData(false)" type="button" class="btn btn-outline-primary mx-2 mt-3">
    {{ $t('Sale.ClearFilter') }}
</button>

</div>
                        <!-- <div class="col-md-4 col-sm-12">
                            <div class="form-group">
                                <label>{{ $t('PurchaseOrder.FromDate') }}</label>
                                <datepicker v-model="fromDate" />
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-12">
                            <div class="form-group">
                                <label>{{ $t('PurchaseOrder.ToDate') }}</label>
                                <datepicker v-model="toDate" />
                            </div>
                        </div> -->
                    </div>
                    <div class="row mt-3" v-if="advanceFilters">
                        <div class="col-xs-12  col-lg-3">
                            <div class="form-group">
                                <label>Month</label>
                                <month-picker-input style="z-index:9 !important;width: 100% !important;" v-bind:placeholder="$t('Sale.PickMonth')" v-bind:disabled="isDisableMonth" v-if="!isDisableMonth" v-on:input="GetMonth" :lang="isMonthPicker()" v-model="monthObj" :no-default="true" v-bind:key="randerforempty"></month-picker-input>
                                <input class="form-control" v-else disabled />
                            </div>
                        </div>

                        <div class="col-lg-3">
                            <div class="form-group">
                                <label>{{ $t('Sale.FromDate') }}</label>
                                <datepicker v-model="fromDate" v-bind:isDisable="isDisable" v-on:input="GetDate1" v-bind:key="randerforempty" />
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-group">
                                <label>{{ $t('Sale.ToDate') }}</label>
                                <datepicker v-model="toDate" v-bind:isDisable="isDisable" v-on:input="GetDate1" v-bind:key="randerforempty" />
                            </div>
                        </div>
                        <div class="col-lg-3 " v-bind:class="$i18n.locale == 'en' ? 'text-left' : 'arabicLanguage'">
                            <label>{{ $t('AddPurchase.Supplier') }}</label>
                            <supplierdropdown ref=supplierDropdown v-model="supplierForFilterId" />

                        </div>

                        <div class="col-lg-3">
                            <label> {{ $t('DailyExpense.TotalAmount') }}</label>
                            <input type="number" class="form-control" @focus="$event.target.select()" :placeholder="$t('DailyExpense.TotalAmount')" v-model="netAmount" />
                        </div>

                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                            <label class="text  font-weight-bolder"> {{$t('Sale.User1')}}</label>
                            <usersDropdown v-model="user" ref="userDropdown" :isloginhistory="isloginhistory" />
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                            <label class="text  font-weight-bolder"> {{$t('Supplier Type')}}</label>
                            <multiselect :options="['Cash', 'Credit']" v-model="customerType">
                            </multiselect>
                        </div>

                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">

                            <button v-on:click="FilterRecord(true)" type="button" class="btn btn-outline-primary mt-3">
                                Search
                            </button>
                            <button v-on:click="FilterRecord(false)" type="button" class="btn btn-outline-primary mx-2 mt-3">
                                Clear Filter
                            </button>

                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <div>
                                <ul class="nav nav-tabs" data-tabs="tabs">
                                    <li v-if="isValid('CanViewDraftOrder')" class="nav-item"><a class="nav-link" v-bind:class="{active:active == 'Draft'}" v-on:click="makeActive('Draft')" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">{{ $t('PurchaseOrder.Draft') }}</a></li>
                                    <li v-if="isValid('CanViewPostOrder')" class="nav-item"><a class="nav-link" v-bind:class="{active:active == 'Approved'}" v-on:click="makeActive('Approved')" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">{{ $t('PurchaseOrder.Post') }}</a></li>

                                </ul>
                            </div>
                            <div class="tab-content mt-3" id="nav-tabContent">
                                <div v-if="active == 'Draft'">
                                    <div class="row" v-if="selected.length > 0">
                                        <div class="col-md-3 ">
                                            <div class="dropdown">
                                                <button class="dropdown-toggle btn btn-primary  btn-block" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    {{ $t('PurchaseOrder.BulkAction') }}
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">

                                                    <a class="dropdown-item" href="javascript:void(0)" v-on:click="UpdateApprovalStatus('Approved')"> {{ $t('PurchaseOrder.Approve') }}</a>
                                                    <a class="dropdown-item" href="javascript:void(0)" v-on:click="UpdateApprovalStatus('Rejected')">{{ $t('PurchaseOrder.Reject') }}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="table-responsive">
                                        <table class="table mb-0">
                                            <thead class="thead-light table-hover">
                                                <tr>
                                                    <th>#</th>
                                                    <th v-if="formName == 'SupplierQuotation'">
                                                        {{ $t('PurchaseOrder.SQNumber') }}
                                                    </th>
                                                    <th v-else>
                                                        {{ $t('PurchaseOrder.PONumber') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('PurchaseOrder.CreatedDate') }}
                                                    </th>
                                                    <th v-if="english=='true'">
                                                        {{ $t('PurchaseOrder.SupplierName') }}
                                                    </th>
                                                    <th v-else-if="isOtherLang()">
                                                        {{ $t('PurchaseOrder.SupplierNameAr') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('Sale.CreatedBy') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('InvoiceNote')}}
                                                    </th>
                                                   
                                                    <th>
                                                        {{ $t('Reference')}}
                                                    </th>
                                                    <th>
                                                        {{ $t('PurchaseOrder.NetAmount') }}
                                                    </th>
                                                    <th>

                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(purchaseOrder,index) in purchaseOrderList" v-bind:key="purchaseOrder.id">
                                                    <td v-if="currentPage === 1">
                                                        {{index+1}}
                                                    </td>
                                                    <td v-else>
                                                        {{((currentPage*10)-10) +(index+1)}}
                                                    </td>
                                                    <td v-if="isValid('CanEditPurchaseOrder')">
                                                        <strong>
                                                            <a href="javascript:void(0)" v-on:click="EditPurchaseOrder(purchaseOrder.id,false)">{{purchaseOrder.registrationNumber}}</a>
                                                        </strong>
                                                       

                                                    </td>
                                                    <td v-else>
                                                        {{purchaseOrder.registrationNumber}}
                                                        <br />
                                                <div v-if="isValid('PremiumARAdvancePayment')">
                                                    <div class="badge bg-danger" v-if="purchaseOrder.partiallyReceived==1">
                                                        {{($i18n.locale == 'en' ||isLeftToRight())?'Un-Paid':'غير مدفوعة'}}
                                                    </div>
                                                    <div class="badge bg-primary" v-if="purchaseOrder.partiallyReceived==2">
                                                        {{($i18n.locale == 'en' ||isLeftToRight())?' Partially Paid':'المدفوعة جزئيا'}}

                                                    </div>
                                                    <div class="badge bg-success" v-if="purchaseOrder.partiallyReceived==3">

                                                        {{($i18n.locale == 'en' ||isLeftToRight())?' Fully Paid':'مدفوعة بالكامل'}}
                                                    </div>
                                                </div>
                                                    </td>
                                                    <td>
                                                        {{purchaseOrder.date}}
                                                    </td>
                                                    <td v-if="english=='true'">
                                                        <a href="javascript:void(0)" v-on:click="ViewCustomerInfo(purchaseOrder.supplierId)" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight2" aria-controls="offcanvasRight2">{{purchaseOrder.supplierName}}</a>
                                                    </td>
                                                    <td v-else-if="isOtherLang()">
                                                        <a href="javascript:void(0)" v-on:click="ViewCustomerInfo(purchaseOrder.supplierId)" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight2" aria-controls="offcanvasRight2">{{purchaseOrder.supplierNameArabic}}</a>
                                                    </td>
                                                    <td v-if="purchaseOrder.createdBy != null">
                                                        {{ purchaseOrder.createdBy }}
                                                    </td>
                                                    <td v-else>
                                                        ---
                                                    </td>
                                                    <td v-if="purchaseOrder.createdFrom != null">
                                                        <a href="javascript:void(0)" v-on:click="SaleIdForCanvas(purchaseOrder.registrationNumber, purchaseOrder.date, purchaseOrder.supplierQuotationId)" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight3" aria-controls="offcanvasRight">{{ purchaseOrder.createdFrom }}</a>
                                                    </td>
                                                    <td v-else>
                                                        ---
                                                    </td>
                                                   
                                                    <td v-if="purchaseOrder.reference != '' && purchaseOrder.reference != null"> 
                                                        {{ purchaseOrder.reference }}
                                                    </td>
                                                    <td v-else> 
                                                       ---
                                                    </td>
                                                    <td>
                                                        {{currency}} {{parseFloat(purchaseOrder.netAmount).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                                    </td>
                                                    <td class="text-end">
                                                        <button type="button" class="btn btn-light dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">{{ $t('PurchaseOrder.Action') }} <i class="mdi mdi-chevron-down"></i></button>
                                                        <div class="dropdown-menu">
                                                            <a class="dropdown-item" href="javascript:void(0)" v-on:click="EditPurchaseOrder(purchaseOrder.id,true)" >Clone</a>
                                                            <a class="dropdown-item" href="javascript:void(0)" v-on:click="PrintRdlc(purchaseOrder.id, false)">{{ $t('PurchaseOrder.A4Print') }}</a>
                                                        </div>
                                                    </td>
                                                    
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                    <hr />
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <span v-if="currentPage === 1 && rowCount === 0">
                                                {{ $t('Pagination.ShowingEntries') }}
                                            </span>
                                            <span v-else-if="currentPage === 1 && rowCount < 10">
                                                {{ $t('Pagination.Showing') }}
                                                {{ currentPage }} {{ $t('Pagination.to') }}
                                                {{ rowCount }}
                                                {{ $t('Pagination.of') }}
                                                {{ rowCount }}
                                                {{ $t('Pagination.entries') }}
                                            </span>
                                            <span v-else-if="currentPage === 1 && rowCount >= 11">
                                                {{ $t('Pagination.Showing') }}
                                                {{ currentPage }}
                                                {{ $t('Pagination.to') }}
                                                {{ currentPage * 10 }}
                                                {{ $t('Pagination.of') }}
                                                {{ rowCount }}
                                                {{ $t('Pagination.entries') }}
                                            </span>
                                            <span v-else-if="currentPage === 1">
                                                {{ $t('Pagination.Showing') }}
                                                {{ currentPage }}
                                                {{ $t('Pagination.to') }}
                                                {{ currentPage * 10 }} of {{ rowCount }}
                                                {{ $t('Pagination.entries') }}
                                            </span>
                                            <span v-else-if="currentPage !== 1 && currentPage !== pageCount">
                                                {{ $t('Pagination.Showing') }}
                                                {{ (currentPage * 10) - 9 }}
                                                {{ $t('Pagination.to') }}
                                                {{ currentPage * 10 }}
                                                {{ $t('Pagination.of') }}
                                                {{ rowCount }}
                                                {{ $t('Pagination.entries') }}
                                            </span>
                                            <span v-else-if="currentPage === pageCount">
                                                {{ $t('Pagination.Showing') }}
                                                {{ (currentPage * 10) - 9 }}
                                                {{ $t('Pagination.to') }}
                                                {{ rowCount }}
                                                {{ $t('Pagination.of') }}
                                                {{ rowCount }}
                                                {{ $t('Pagination.entries') }}
                                            </span>
                                        </div>
                                        <div class=" col-lg-6">
                                            <div class=" float-end" v-on:click="getPage()">
                                                <b-pagination pills size="sm" v-model="currentPage" :total-rows="rowCount" :per-page="10" :first-text="$t('Table.First')" :prev-text="$t('Table.Previous')" :next-text="$t('Table.Next')" :last-text="$t('Table.Last')">
                                                </b-pagination>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="active == 'InProcess'">
                                    <div class="row" v-if="selected.length > 0">
                                        <div class="col-md-3 ">
                                            <div class="dropdown">
                                                <button class="dropdown-toggle btn btn-primary  btn-block" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    {{ $t('PurchaseOrder.BulkAction') }}
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">

                                                    <a class="dropdown-item" href="javascript:void(0)" v-on:click="UpdateApprovalStatus('Approved')"> {{ $t('PurchaseOrder.Approve') }}</a>
                                                    <a class="dropdown-item" href="javascript:void(0)" v-on:click="UpdateApprovalStatus('Rejected')">{{ $t('PurchaseOrder.Reject') }}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="table-responsive">
                                        <table class="table mb-0">
                                            <thead class="thead-light table-hover">
                                                <tr>
                                                    <th>#</th>
                                                    <th>
                                                        {{ $t('PurchaseOrder.PONumber') }}
                                                    </th>
                                                    <th v-if="isValid('CanAllowOrderVersion') && formName != 'SupplierQuotation'">
                                                        {{ $t('PurchaseOrder.Version') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('PurchaseOrder.CreatedDate') }}
                                                    </th>
                                                    <th v-if="english=='true'">
                                                        {{ $t('PurchaseOrder.SupplierName') }}
                                                    </th>
                                                    <th v-if="isOtherLang()">
                                                        {{ $t('PurchaseOrder.SupplierNameAr') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('Sale.CreatedBy') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('InvoiceNote')}}
                                                    </th>
                                                   
                                                    <th>
                                                        {{ $t('Reference')}}
                                                    </th>
                                                    <th>
                                                        {{ $t('PurchaseOrder.NetAmount') }}
                                                    </th>
                                                    <th>

                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(purchaseOrder,index) in purchaseOrderList" v-bind:key="purchaseOrder.id">
                                                    <td v-if="currentPage === 1">
                                                        {{index+1}}
                                                    </td>
                                                    <td v-else>
                                                        {{((currentPage*10)-10) +(index+1)}}
                                                    </td>
                                                    <td v-if="isValid('CanEditPurchaseOrder') ">
                                                        <strong>
                                                            <a href="javascript:void(0)" v-on:click="EditPurchaseOrder(purchaseOrder.id,false)">{{purchaseOrder.registrationNumber}}</a>
                                                        </strong>

                                                    </td>
                                                    <td v-else>
                                                        {{purchaseOrder.registrationNumber}}
                                                    </td>
                                                    <td v-if="isValid('CanAllowOrderVersion')  && formName != 'SupplierQuotation'">
                                                        {{purchaseOrder.version}}
                                                    </td>
                                                    <td>
                                                        {{purchaseOrder.date}}
                                                    </td>
                                                    <td v-if="english=='true'">
                                                        <a href="javascript:void(0)" v-on:click="ViewCustomerInfo(purchaseOrder.supplierId)" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight2" aria-controls="offcanvasRight2">{{purchaseOrder.supplierName}}</a>
                                                    </td>
                                                    <td v-else-if="isOtherLang()">
                                                        <a href="javascript:void(0)" v-on:click="ViewCustomerInfo(purchaseOrder.supplierId)" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight2" aria-controls="offcanvasRight2">{{purchaseOrder.supplierNameArabic}}</a>
                                                    </td>
                                                    <td v-if="purchaseOrder.createdBy != null">
                                                        {{ purchaseOrder.createdBy }}
                                                    </td>
                                                    <td v-else>
                                                        ---
                                                    </td>
                                                    <td v-if="purchaseOrder.createdFrom != null">
                                                        <a href="javascript:void(0)" v-on:click="SaleIdForCanvas(purchaseOrder.registrationNumber, purchaseOrder.date, purchaseOrder.supplierQuotationId)" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight3" aria-controls="offcanvasRight">{{ purchaseOrder.createdFrom }}</a>
                                                    </td>
                                                    <td v-else>
                                                        ---
                                                    </td>
                                                   
                                                    <td v-if="purchaseOrder.reference != '' && purchaseOrder.reference != null"> 
                                                        {{ purchaseOrder.reference }}
                                                    </td>
                                                    <td v-else> 
                                                       ---
                                                    </td>
                                                    <td>
                                                        {{currency}} {{parseFloat(purchaseOrder.netAmount).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                                    </td>
                                                    <td class="text-end">
                                                        <button type="button" class="btn btn-light dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">{{ $t('PurchaseOrder.Action') }} <i class="mdi mdi-chevron-down"></i></button>
                                                        <div class="dropdown-menu">
                                                            <a class="dropdown-item" href="javascript:void(0)" v-on:click="paymentModel(purchaseOrder.id, purchaseOrder.netAmount, purchaseOrder.supplierAdvanceAccountId)" v-if="!purchaseOrder.isClose && internationalPurchase=='true' && isValid('CanAdvancePaymentFromList')">{{ $t('PurchaseOrder.Payment') }}</a>
                                                            <a class="dropdown-item" href="javascript:void(0)" v-on:click="PrintRdlc(purchaseOrder.id,false)" v-if="isValid('CanViewOrderDetail')">{{ $t('PurchaseOrder.ViewInvoice') }}</a>
                                                            <a class="dropdown-item" href="javascript:void(0)" v-on:click="PrintRdlc(purchaseOrder.id, false)">{{ $t('PurchaseOrder.A4Print') }}</a>
                                                            <a class="dropdown-item" href="javascript:void(0)" v-on:click="PrintRdlc(purchaseOrder.id, true)">{{ $t('PurchaseOrder.Pdf') }} </a>
                                                        </div>
                                                    </td>
                                                   
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                    <hr />
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <span v-if="currentPage === 1 && rowCount === 0">
                                                {{ $t('Pagination.ShowingEntries') }}
                                            </span>
                                            <span v-else-if="currentPage === 1 && rowCount < 10">
                                                {{ $t('Pagination.Showing') }}
                                                {{ currentPage }} {{ $t('Pagination.to') }}
                                                {{ rowCount }}
                                                {{ $t('Pagination.of') }}
                                                {{ rowCount }}
                                                {{ $t('Pagination.entries') }}
                                            </span>
                                            <span v-else-if="currentPage === 1 && rowCount >= 11">
                                                {{ $t('Pagination.Showing') }}
                                                {{ currentPage }}
                                                {{ $t('Pagination.to') }}
                                                {{ currentPage * 10 }}
                                                {{ $t('Pagination.of') }}
                                                {{ rowCount }}
                                                {{ $t('Pagination.entries') }}
                                            </span>
                                            <span v-else-if="currentPage === 1">
                                                {{ $t('Pagination.Showing') }}
                                                {{ currentPage }}
                                                {{ $t('Pagination.to') }}
                                                {{ currentPage * 10 }} of {{ rowCount }}
                                                {{ $t('Pagination.entries') }}
                                            </span>
                                            <span v-else-if="currentPage !== 1 && currentPage !== pageCount">
                                                {{ $t('Pagination.Showing') }}
                                                {{ (currentPage * 10) - 9 }}
                                                {{ $t('Pagination.to') }}
                                                {{ currentPage * 10 }}
                                                {{ $t('Pagination.of') }}
                                                {{ rowCount }}
                                                {{ $t('Pagination.entries') }}
                                            </span>
                                            <span v-else-if="currentPage === pageCount">
                                                {{ $t('Pagination.Showing') }}
                                                {{ (currentPage * 10) - 9 }}
                                                {{ $t('Pagination.to') }}
                                                {{ rowCount }}
                                                {{ $t('Pagination.of') }}
                                                {{ rowCount }}
                                                {{ $t('Pagination.entries') }}
                                            </span>
                                        </div>
                                        <div class=" col-lg-6">
                                            <div class=" float-end" v-on:click="getPage()">
                                                <b-pagination pills size="sm" v-model="currentPage" :total-rows="rowCount" :per-page="10" :first-text="$t('Table.First')" :prev-text="$t('Table.Previous')" :next-text="$t('Table.Next')" :last-text="$t('Table.Last')">
                                                </b-pagination>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="active == 'Approved'">
                                    <div class="row" v-if="selected.length > 0">
                                        <div class="col-md-3 ">
                                            <div class="dropdown">
                                                <button class="dropdown-toggle btn btn-primary  btn-block" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    {{ $t('PurchaseOrder.BulkAction') }}
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">

                                                    <a class="dropdown-item" href="javascript:void(0)" v-on:click="UpdateApprovalStatus('Approved')"> {{ $t('PurchaseOrder.Approve') }}</a>
                                                    <a class="dropdown-item" href="javascript:void(0)" v-on:click="UpdateApprovalStatus('Rejected')">{{ $t('PurchaseOrder.Reject') }}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="table-responsive">
                                        <table class="table mb-0">
                                            <thead class="thead-light table-hover">
                                                <tr>
                                                    <th>#</th>
                                                    <th v-if="formName == 'SupplierQuotation'">
                                                        {{ $t('PurchaseOrder.SQNumber') }}
                                                    </th>
                                                    <th v-else>
                                                        {{ $t('PurchaseOrder.PONumber') }}
                                                    </th>
                                                   
                                                    <th v-if="isValid('CanAllowOrderVersion') && formName != 'SupplierQuotation'">
                                                        {{ $t('PurchaseOrder.Version') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('PurchaseOrder.CreatedDate') }}
                                                    </th>
                                                    <th v-if="english=='true'">
                                                        {{ $t('PurchaseOrder.SupplierName') }}
                                                    </th>
                                                    <th v-else-if="isOtherLang()">
                                                        {{ $t('PurchaseOrder.SupplierNameAr') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('Sale.CreatedBy') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('InvoiceNote')}}
                                                    </th>
                                                   
                                                    <th>
                                                        {{ $t('Reference')}}
                                                    </th>
                                                    <th>
                                                        {{ $t('PurchaseOrder.NetAmount') }}
                                                    </th>
                                                    <th v-if="isValid('CanCloseOrder')">
                                                        {{ $t('PurchaseOrder.Status') }}
                                                    </th>
                                                    <th>

                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(purchaseOrder,index) in purchaseOrderList" v-bind:key="purchaseOrder.id">
                                                    <td v-if="currentPage === 1">
                                                        {{index+1}}
                                                    </td>
                                                    <td v-else>
                                                        {{((currentPage*10)-10) +(index+1)}}
                                                    </td>
                                                    <td>
                                                        <strong>
                                                            {{purchaseOrder.registrationNumber}}
                                                        </strong>
                                                        <br />
                                                        <div v-if="isValid('PremiumARAdvancePayment')">
                                                    <div class="badge bg-danger" v-if="purchaseOrder.partiallyReceived==1">
                                                        {{($i18n.locale == 'en' ||isLeftToRight())?'Un-Paid':'غير مدفوعة'}}
                                                    </div>
                                                    <div class="badge bg-primary" v-if="purchaseOrder.partiallyReceived==2">
                                                        {{($i18n.locale == 'en' ||isLeftToRight())?' Partially Closed':'المدفوعة جزئيا'}}

                                                    </div>
                                                    <div class="badge bg-success" v-if="purchaseOrder.partiallyReceived==3">

                                                        {{($i18n.locale == 'en' ||isLeftToRight())?' Fully Closed':'مدفوعة بالكامل'}}
                                                    </div>
                                                </div>

                                                    </td>
                                                   
                                                    <td v-if="isValid('CanAllowOrderVersion') && formName != 'SupplierQuotation'">
                                                        {{purchaseOrder.version}}
                                                    </td>
                                                    <td>
                                                        {{purchaseOrder.date}}
                                                    </td>
                                                    <td v-if="english=='true'">
                                                        <a href="javascript:void(0)" v-on:click="ViewCustomerInfo(purchaseOrder.supplierId)" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight2" aria-controls="offcanvasRight2">{{purchaseOrder.supplierName}}</a>
                                                    </td>
                                                    <td v-else-if="isOtherLang()">
                                                        <a href="javascript:void(0)" v-on:click="ViewCustomerInfo(purchaseOrder.supplierId)" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight2" aria-controls="offcanvasRight2">{{purchaseOrder.supplierNameArabic}}</a>
                                                    </td>
                                                    <td v-if="purchaseOrder.createdBy != null">
                                                        {{ purchaseOrder.createdBy }}
                                                    </td>
                                                    <td v-else>
                                                        ---
                                                    </td>
                                                    <td v-if="purchaseOrder.createdFrom != null">
                                                        <a href="javascript:void(0)" v-on:click="SaleIdForCanvas(purchaseOrder.registrationNumber, purchaseOrder.date, purchaseOrder.supplierQuotationId)" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight3" aria-controls="offcanvasRight">{{ purchaseOrder.createdFrom }}</a>
                                                    </td>
                                                    <td v-else>
                                                        ---
                                                    </td>
                                                  
                                                    <td v-if="purchaseOrder.reference != '' && purchaseOrder.reference != null"> 
                                                        {{ purchaseOrder.reference }}
                                                    </td>
                                                    <td v-else> 
                                                       ---
                                                    </td>
                                                    <td>
                                                        {{currency}} {{parseFloat(purchaseOrder.netAmount).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                                    </td>
                                                    <td>
                                                        <toggle-button v-on:change="openmodel(purchaseOrder.id)" v-if="!purchaseOrder.isClose && !purchaseOrder.isProcessed && isValid('CanCloseOrder')" class="ml-2 mt-2" color="#3178F6" v-bind:key="randerToogle" />
                                                        <div v-on:click="GetDocumentRecord(purchaseOrder.id)"  data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight3" aria-controls="offcanvasRight" class="tooltip badge rounded-pill badge-soft-success" v-if="purchaseOrder.isProcessed || purchaseOrder.isClose">
                                                            Processed
                                                            <span class="tooltiptext">{{ purchaseOrder.reason }}</span>
                                                        </div>

                                                    </td>
                                                    <td class="text-end">
                                                        <button type="button" class="btn btn-light dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">{{ $t('PurchaseOrder.Action') }} <i class="mdi mdi-chevron-down"></i></button>
                                                        <div class="dropdown-menu">
                                                            <a class="dropdown-item" href="javascript:void(0)" v-on:click="EditPurchaseOrder(purchaseOrder.id,true)" >Clone</a>
                                                            <a class="dropdown-item" href="javascript:void(0)" v-on:click="PrintRdlc(purchaseOrder.id, false)">{{ $t('PurchaseOrder.A4Print') }}</a>
                                                            <a class="dropdown-item" href="javascript:void(0);"  v-on:click="EditPurchaseOrder(purchaseOrder.id, true,'PurchaseInvoice')"  v-if="isValid('CanViewPurchasePost') && formName != 'SupplierQuotation' && !purchaseOrder.isClose  ">
                                                                Create Purchase Invoice
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                    <hr />
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <span v-if="currentPage === 1 && rowCount === 0">
                                                {{ $t('Pagination.ShowingEntries') }}
                                            </span>
                                            <span v-else-if="currentPage === 1 && rowCount < 10">
                                                {{ $t('Pagination.Showing') }}
                                                {{ currentPage }} {{ $t('Pagination.to') }}
                                                {{ rowCount }}
                                                {{ $t('Pagination.of') }}
                                                {{ rowCount }}
                                                {{ $t('Pagination.entries') }}
                                            </span>
                                            <span v-else-if="currentPage === 1 && rowCount >= 11">
                                                {{ $t('Pagination.Showing') }}
                                                {{ currentPage }}
                                                {{ $t('Pagination.to') }}
                                                {{ currentPage * 10 }}
                                                {{ $t('Pagination.of') }}
                                                {{ rowCount }}
                                                {{ $t('Pagination.entries') }}
                                            </span>
                                            <span v-else-if="currentPage === 1">
                                                {{ $t('Pagination.Showing') }}
                                                {{ currentPage }}
                                                {{ $t('Pagination.to') }}
                                                {{ currentPage * 10 }} of {{ rowCount }}
                                                {{ $t('Pagination.entries') }}
                                            </span>
                                            <span v-else-if="currentPage !== 1 && currentPage !== pageCount">
                                                {{ $t('Pagination.Showing') }}
                                                {{ (currentPage * 10) - 9 }}
                                                {{ $t('Pagination.to') }}
                                                {{ currentPage * 10 }}
                                                {{ $t('Pagination.of') }}
                                                {{ rowCount }}
                                                {{ $t('Pagination.entries') }}
                                            </span>
                                            <span v-else-if="currentPage === pageCount">
                                                {{ $t('Pagination.Showing') }}
                                                {{ (currentPage * 10) - 9 }}
                                                {{ $t('Pagination.to') }}
                                                {{ rowCount }}
                                                {{ $t('Pagination.of') }}
                                                {{ rowCount }}
                                                {{ $t('Pagination.entries') }}
                                            </span>
                                        </div>
                                        <div class=" col-lg-6">
                                            <div class=" float-end" v-on:click="getPage()">
                                                <b-pagination pills size="sm" v-model="currentPage" :total-rows="rowCount" :per-page="10" :first-text="$t('Table.First')" :prev-text="$t('Table.Previous')" :next-text="$t('Table.Next')" :last-text="$t('Table.Last')">
                                                </b-pagination>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>
            </div>

            <div class="offcanvas offcanvas-end px-0" tabindex="-1" id="offcanvasRight2" aria-labelledby="offcanvasRightLabel">
            <div class="offcanvas-header">
                <h5 id="offcanvasRightLabel" class="m-0">Supplier Info ({{customerInformation.runningBalance}})</h5>
                <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body">
                <div class="row">
                    <div class="col-lg-12 form-group" v-if="english=='true'">
                        <label>Supplier Name</label>
                        <input class="form-control" type="text" v-model="customerInformation.englishName" disabled />
                    </div>
                    <div class="col-lg-12 form-group" v-if="isOtherLang()">
                        <label>Supplier Name</label>
                        <input class="form-control" type="text" v-model="customerInformation.arabicName" disabled />
                    </div>
                    <div class="col-lg-12 form-group">
                        <label>{{ $t('AddCustomer.CustomerPhone') }}</label>
                        <input class="form-control" type="text" v-model="customerInformation.contactNo1" disabled />
                    </div>
                    <div class="col-lg-12 form-group">
                        <label>{{ $t('AddCustomer.CommercialRegistrationNo') }}</label>
                        <input class="form-control" type="text" v-model="customerInformation.commercialRegistrationNo" disabled />
                    </div>
                    <div class="col-lg-12 form-group">
                        <label>{{ $t('AddCustomer.VAT/NTN/Tax No') }}</label>
                        <input class="form-control" type="text" v-model="customerInformation.vatNo" disabled />
                    </div>
                    <div class="col-lg-12 form-group text-center">
                        <button v-if="expandSale" v-on:click="expandSale=false" type="button" class="btn btn-outline-info btn-icon-circle btn-icon-circle-sm"><i class="ti-angle-double-up"></i></button>
                        <button v-else v-on:click="expandSale=true" type="button" class="btn btn-outline-info btn-icon-circle btn-icon-circle-sm"><i class="ti-angle-double-down"></i></button>
                    </div>
                    <div v-if="expandSale" class="col-lg-12 form-group">
                        <h6 class="text-danger">Showing Last Three Month Invoices</h6>
                        <p v-for="(sale,index) in customerInformation.invoiceList" v-bind:key="index" style="border-bottom: 1px solid #cbcbcb; ">
                            <a href="javascript:void(0);" data-bs-dismiss="offcanvas" aria-label="Close" v-on:click="ViewInvoice(sale.id)">
                                <span>{{index+1}}- {{sale.registrationNumber}} </span>
                                <span class="float-end">{{currency}} {{parseFloat(sale.netAmount).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span>
                            </a>
                            <br />
                            <small>{{getDate(sale.date)}}</small>
                        </p>
                    </div>
                </div>
            </div>
            </div>

            <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight3" aria-labelledby="offcanvasRightLabel" style="width:600px !important">
                        <div class="offcanvas-header">
                            <h5 id="offcanvasRightLabel" class="m-0">{{ $t('Sale.MoreDetails') }} ({{ registrationNo }})</h5>
                            <button v-bind:style="($i18n.locale == 'en' ||isLeftToRight()) ? '' : 'margin-left:0px !important'" type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div class="offcanvas-body">
                            <!-- <div class="row">
                                <a class="btn btn-light my-2 col-md-4" href="javascript:void(0);" v-on:click="ViewDeliveryChallan(saleIdToCanvas)" data-bs-toggle="offcanvas" v-if="isValid('SaleToDeliveryNote') " data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"> {{ $t('Sale.ViewDeliveryNote') }}</a>
                            </div> -->
                            <div class="row">
                               
                                <div class="col-md-12">

                                    <div class="row" v-if="(isValid('CanViewSupplierQuotation')) && isList==true">
                                        <div class="row" v-for="item in documentRecord " v-bind:key="item.id"  >
                                        <div class="form-group text-right ">
                                            <b>{{ item.reference }}</b>
                                            <!-- <span>{{invoiceNote}}</span> -->
                                        </div>
                                        <div class="col-lg-12 form-group text-left d-flex">
                                            <p style="border-bottom: 1px solid #cbcbcb; ">
                                                <span>1- {{ item.registrationNo }}--{{getDate(item.date) }}</span>
                                            </p>
                                        </div>

                                        <div class="col-lg-12 form-group">
                                            <div class="table-responsive">
                                                <table class="table mb-0">
                                                    <thead class="thead-light table-hover">
                                                    <th>{{ $t('AddPurchase.DiscountType') }}</th>
                                                    <th>{{ $t('AddPurchase.TaxMethod') }}</th>
                                                    <th>{{ $t('StockLineDetails.VAT') }}</th>
                                                    </thead>
                                                    <tbody>
                                                    <td v-if="item.discountType">
                                                        At Transaction Level
                                                    </td>
                                                    <td v-else>At Line Item Level</td>
                                                    <td>{{ item.taxMethod }}</td>
                                                    <td>{{ item.taxRatesName }}</td>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="table-responsive mt-3">
                                                <table class="table mb-0">
                                                    <thead class="thead-light table-hover">
                                                    <th>#</th>
                                                    <th>{{ $t('AddBundles.ProductName') }}</th>
                                                    <th>{{ $t('StockLineDetails.Quantity') }}</th>
                                                    <th>{{ $t('StockLineDetails.UnitPrice') }}</th>
                                                    </thead>
                                                    <tbody v-for="(sale,index ) in item.purchaseOrderItems" v-bind:key="index">
                                                    <td>{{ index + 1 }}</td>
                                                    <td v-if="sale.productName=='' || sale.productName==null">
                                                        {{ sale.description }}
                                                    </td>
                                                    <td v-else>
                                                        {{ sale.displayName }}
                                                    </td>
                                                    <td>{{ sale.quantityforCanvas }}</td>
                                                    <td>{{ sale.unitPrice }}</td>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="table-responsive mt-3">
                                                <table class="table mb-0">
                                                    <thead class="thead-light table-hover">
                                                    <th>{{ $t('SaleItem.Subtotal') }}</th>
                                                    <th>{{ $t('SaleItem.Discount') }}</th>
                                                    <th>{{ $t('Vat Amount') }}</th>
                                                    <th>{{ $t('Amount After Discount') }}</th>
                                                    <th>{{ $t('SaleItem.TotalDue') }}</th>
                                                    </thead>
                                                    <tbody>
                                                    <td>{{currency}} {{parseFloat(item.grossAmount).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</td>
                                                    <td>
                                                        {{parseFloat(item.discountAmount).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                                    </td>
                                                    <td>
                                                        {{parseFloat(item.vatAmount).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                                    </td>
                                                    <td>{{parseFloat(item.totalAfterDiscount).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</td>
                                                    <td v-if="item.totalAmount>0"> {{currency}} {{parseFloat(item.totalAmount).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</td>
                                                    <td v-else> {{currency}} {{parseFloat(item.netAmount).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</td>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <hr>
                                    </div>


                                    </div>

                                    
                                    





                                   
                                    
                                    <div class="row" v-else-if="(isValid('CanViewSupplierQuotation')) && quotationNo != null && quotationNo != ''">
                                        <div class="form-group text-right ">
                                            <b>Supplier Quotation</b>
                                            <!-- <span>{{invoiceNote}}</span> -->
                                        </div>
                                        <div class="col-lg-12 form-group text-left d-flex">
                                            <p style="border-bottom: 1px solid #cbcbcb; ">
                                                <span>1- {{ quotationNo }}--{{getDate(canvasDate) }}</span>
                                            </p>
                                        </div>

                                        <div class="col-lg-12 form-group">
                                            <div class="table-responsive">
                                                <table class="table mb-0">
                                                    <thead class="thead-light table-hover">
                                                    <th>{{ $t('AddPurchase.DiscountType') }}</th>
                                                    <th>{{ $t('AddPurchase.TaxMethod') }}</th>
                                                    <th>{{ $t('StockLineDetails.VAT') }}</th>
                                                    </thead>
                                                    <tbody>
                                                    <td v-if="discountType">
                                                        At Transaction Level
                                                    </td>
                                                    <td v-else>At Line Item Level</td>
                                                    <td>{{ canvasTaxMethod }}</td>
                                                    <td>{{ vAT }}</td>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="table-responsive mt-3">
                                                <table class="table mb-0">
                                                    <thead class="thead-light table-hover">
                                                    <th>#</th>
                                                    <th>{{ $t('AddBundles.ProductName') }}</th>
                                                    <th>{{ $t('StockLineDetails.Quantity') }}</th>
                                                    <th>{{ $t('StockLineDetails.UnitPrice') }}</th>
                                                    </thead>
                                                    <tbody v-for="(sale,index ) in saleItem" v-bind:key="index">
                                                    <td>{{ index + 1 }}</td>
                                                    <td v-if="sale.productName=='' || sale.productName==null">
                                                        {{ sale.description }}
                                                    </td>
                                                    <td v-else>
                                                        {{ sale.displayName }}
                                                    </td>
                                                    <td>{{ sale.quantityforCanvas }}</td>
                                                    <td>{{ sale.unitPrice }}</td>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="table-responsive mt-3">
                                                <table class="table mb-0">
                                                    <thead class="thead-light table-hover">
                                                    <th>{{ $t('SaleItem.Subtotal') }}</th>
                                                    <th>{{ $t('SaleItem.Discount') }}</th>
                                                    <th>{{ $t('Vat Amount') }}</th>
                                                    <th>{{ $t('Amount After Discount') }}</th>
                                                    <th>{{ $t('SaleItem.TotalDue') }}</th>
                                                    </thead>
                                                    <tbody>
                                                    <td>{{currency}} {{parseFloat(grossAmount).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</td>
                                                    <td>
                                                        {{parseFloat(discountAmount).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                                    </td>
                                                    <td>
                                                        {{parseFloat(vatAmount).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                                    </td>
                                                    <td>{{parseFloat(afterDiscountAmount).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</td>
                                                    <td> {{currency}} {{parseFloat(totalAmount).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</td>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                               
                            </div>
                        </div>
                    </div>


            <loading :active.sync="loading" :can-cancel="true" :is-full-page="false"></loading>

            <purchaseInvoiceDownload :printDetails="printDetails" :headerFooter="headerFooter" v-if="printDetails.length != 0 && download" v-bind:key="pdfRander" :formName="'PurchaseOrder'" />
            <purchaseInvoice :printDetails="printDetails" :headerFooter="headerFooter" v-if="(printDetails.length != 0 && printShow)" v-bind:key="printRender" :formName="'PurchaseOrder'" />
         

            <purchaseinvoicereport :printDetails="printDetails" :isTouchScreen="'PurchaseOrder'" :headerFooter="headerFooter"
            @close="show = false" :show="show" v-if="printDetails.length != 0 && show && !download"
            v-bind:key="printRender" />


            <purchaseorder-payment :totalAmount="totalAmount" :customerAccountId="customerAccountId" :show="payment" v-if="payment" @close="paymentSave" :isPurchase="'true'" :isSaleOrder="'false'" :purchaseOrderId="purchaseId" :formName="'AdvancePay'" />
            <reasonsaleorder :show="show1" v-if="show1" @close="CloseRefresh" @SaveRecord="SaveRecord" />
        </div>
    </div>
    <div v-else>
        <acessdenied></acessdenied>
    </div>
</template>

<script>
import clickMixin from '@/Mixins/clickMixin'
import Multiselect from "vue-multiselect";
import moment from "moment";
import Loading from 'vue-loading-overlay';
import "vue-loading-overlay/dist/vue-loading.css";
import {
    MonthPickerInput
} from 'vue-month-picker'
export default {
    components: {
        Loading,
        Multiselect,
        MonthPickerInput,
    },
    mixins: [clickMixin],
    props: ['formName'],
    data: function () {
        return {
            purchaseOrderId:'',
            saleIdToCanvas:'',
            show1 : false,

            loading: false,
            show: false,
            reportsrc: '',
            customerInformation: '',
            expandSale:false,
            changereport: 0,
            randerToogle: 0,

           
            purchaseOrderIdToCanvas: '',
            canvasDate: '',
            purchaseOrderNo: '',
            documentRecord: [],
            isList: false,
            PurchaseOrderItems: [],
            historyList: [],
            vAT: '',
            canvasTaxMethod: '',

            registrationNo: '',
            quotationNo: '',
            discountType: false,
            grossAmount: 0,
            discountAmount: 0,
            vatAmount: 0,
            afterDiscountAmount: 0,


                //versionAllow: '',
                printRender: 0,
                pdfRander: 0,
                download: false,
                printShow: false,
                internationalPurchase: '',
                fromDate: '',
                toDate: '',
                arabic: '',
                english: '',
                active: 'Draft',
                search: '',
                searchQuery: '',
                purchaseOrderList: [],
                currentPage: 1,
                pageCount: '',
                rowCount: '',
                currency: '',
                purchaseId: '',
                totalAmount: 0,
                customerAccountId: '',
                payment: false,
                isMultiUnit: false,

                selected: [],
                selectAll: false,
                updateApprovalStatus: {
                    id: '',
                    approvalStatus: ''
                },
                counter: 0,
                randerList: 0,
                printDetails: [],
                headerFooter: {
                    footerEn: '',
                    footerAr: '',
                    company: ''
                },

            advanceFilters: false,
            isDisableMonth: false,
            monthObj: '',
            year: '',
            randerforempty: 0,
            month: 0,
            isDisable: false,
            supplierForFilterId: '',
            netAmount: '',
            isloginhistory: true,
            user: '',
            userId: '',
            customerType: '',
            request: 0,
            hold: 0,
            credit: 0,
            cash: 0,
            partially: 0,
            unPaid: 0,
            randerChart: 0,
            fullyPaid: 0,
            saleListModel: {
                hold: 0,
                totalHold: 0.0,
                unPaid: 0,
                totalUnPaid: 0.0,
                fullyPaid: 0,
                totalFullyPaid: 0.0,
                partially: 0,
                totalPartially: 0.0,
                credit: 0,
                totalCredit: 0.0,
                cash: 0,
                totalCash: 0.0
            },

                series: [],
                chartOptions: {
                    labels: ['Draft', 'In Processing', 'Approved']
                },
                series2: [],
                chartOptions2: {
                    labels: ['UnPaid ', 'Partially ', 'FullyPaid ']
                },
                series3: [],
                chartOptions3: {
                    labels: ['Draft', 'In Processing', 'Approved']
                },
                series4: [],
                chartOptions4: {
                    labels: ['Un-Paid ', 'Partially ', 'Fully-Paid ']
                },

                seriesOfCustomer: [{
                    name: "Amount",
                    data: []
                }],
                chartOptionsOfCustomer: {
                    chart: {
                        height: 350,
                        type: 'line',
                        zoom: {
                            enabled: false
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'straight'
                    },
                    title: {
                        text: 'Trending Supplier by Amount',
                        align: 'left'
                    },
                    grid: {
                        row: {
                            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.5
                        },
                    },
                    xaxis: {
                        categories: [],
                    }
                },

                earningSeries: [{
                    name: 'Cash Customer',
                    data: []
                }],
                earningChartOption: {
                    chart: {
                        height: 350,
                        type: 'area'
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '15%',
                            endingShape: 'rounded'
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    xaxis: {

                        categories: []
                    },

                },
                earningSeriesCash: [{
                    name: 'Cash Supplier',
                    data: []
                }],
                earningChartOptionCash: {
                    chart: {
                        height: 350,
                        type: 'area'
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '15%',
                            endingShape: 'rounded'
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    xaxis: {

                        categories: []
                    },

                },

            seriesPurchase: [{
                name: 'Credit Supplier',
                data: []
            },],
            chartOptionsPurchase: {
                chart: {
                    height: 350,
                    type: 'area'
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth'
                },
                xaxis: {
                    categories: []
                },

            },
        }
    },
    watch: {
        // search: function (val) {
        //     this.getData(val, 1, this.active, this.fromDate, this.toDate, this.userId, this.customerType);
        // },
        // fromDate: function (fromDate) {
        //     this.counter++;
        //     if (this.counter != 1) {
        //         localStorage.setItem('fromDate', fromDate);
        //         this.getData(this.search, this.currentPage, this.active, fromDate, this.toDate);
        //     }
        // },
        // toDate: function (toDate) {
        //     this.counter++;
        //     if (this.counter != 2) {
        //         localStorage.setItem('toDate', toDate);
        //         this.getData(this.search, this.currentPage, this.active, this.fromDate, toDate);
        //     }
        // },
        formName: function () {

            if (this.formName == 'SupplierQuotation') {
                this.active = 'SupplierQuotation';
                this.currentPage = 1;
                this.makeActive("Approved");
            } else {

                this.active = 'PurchaseOrder';
                this.currentPage = 1;
                this.makeActive("Approved");
            }
        },
    },
    methods: {
        ViewCustomerInfo: function (id) {
            
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                var isSupplierQuotation=false;
                if(this.formName=='SupplierQuotation')
                {
                    isSupplierQuotation=true;
                }

                
                root.$https.get('/Contact/ContactLedgerDetail?id=' + id + '&documentType=' + "PurchaseOrders" + '&isSupplierQuotation=' + isSupplierQuotation  + '&isService=' + false + '&lastThreeMonth=' + true, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
                    .then(function (response) {
                        if (response.data != null) {
                            root.customerInformation = response.data;
                        }
                    },
                        function (error) {
                            console.log(error);
                        });
            },
        getDate: function (date) {
                return moment(date).format('LLL');
            },
            GetDocumentRecord: function (documentId) {
                
                var root = this;
                var token = '';
                this.expandHistory = false;
                this.randerExpand++;
                if (documentId != null) {
                    if (this.$session.exists()) {
                        token = localStorage.getItem('token');
                    }

                    let supplierQuotation='';
                    if(this.formName=='SupplierQuotation')
                    {
                        supplierQuotation= 'SupplierQuotation'
                    }
                    else
                    {
                        supplierQuotation='PurchaseOrder';

                    }
                    


                    root.$https.get('/Purchase/ProccessedByDocumentId?id=' + documentId + '&documentName='+ supplierQuotation, {
                        headers: {
                            "Authorization": `Bearer ${token}`
                        }
                    })
                        .then(function (response) {
                            if (response.data != null) {
                                
                                if(response.data.lookups.length>0)
                                {
                                    root.isList=true;
                                    root.registrationNo='';
                                    root.documentRecord = response.data.lookups;

                                }
                                else
                                {
                                    root.registrationNo='';

                                    root.isList=false;

                                }
                               
                            }
                        },
                            function (error) {
                                console.log(error);
                            });
                }
            },
       
        SaleIdForCanvas: function ( registrationNumber, date, saleId) {
            this.isList=false;

                var root = this;
                var token = '';
                root.saleIdToCanvas = saleId;
                this.expandHistory = false;
                this.randerExpand++;
                if (saleId != null) {
                    if (this.$session.exists()) {
                        token = localStorage.getItem('token');
                    }



                    root.$https.get('/Purchase/PurchaseOrderDetail?id=' + saleId + '&simpleQuery=' + true, {
                        headers: {
                            "Authorization": `Bearer ${token}`
                        }
                    })
                        .then(function (response) {
                            if (response.data != null) {
                                root.registrationNo = registrationNumber + ' - ' + root.getDate(date);
                                root.canvasDate = response.data.date;
                                root.quotationNo = response.data.registrationNo;
                                root.saleItem = response.data.purchaseOrderItems;
                                root.grossAmount = response.data.grossAmount;
                                root.discountAmount = response.data.discountAmount;
                                root.vatAmount = response.data.vatAmount;
                                root.afterDiscountAmount = response.data.totalAfterDiscount;
                                root.totalAmount = response.data.totalAmount;
                                root.vAT = response.data.taxRateName;
                                root.canvasTaxMethod = response.data.taxMethod;
                                root.discountType = response.data.discountType;
                            }
                        },
                            function (error) {
                                console.log(error);
                            });
                }
                 
            },
        CloseRefresh: function () {
            this.show1 = false;
            this.getPage();
        },
        openmodel: function (id) {
            this.purchaseOrderId = id;
            this.show1 = true;
        },
        SaveRecord: function (x) {
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }

            var purchaseOrder = {
                id: this.purchaseOrderId,
                isClose: true,
                reason: x
            };
            this.$https.post('/Purchase/UpdatePurchaseOrderCloseReason', purchaseOrder, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }).then(function (response) {
                if (response.data.isSuccess) {
                    root.show1 = false;
                    root.$swal({
                        title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved!' : '!تم الحفظ',
                        text: "Sale Order Closed Successfully!",
                        type: 'success',
                        icon: 'success',
                        showConfirmButton: true
                    });

                    root.getPage();
                }
                else
                {
                    root.$swal({
                        title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                        text: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Something Went Wrong!' : 'هل هناك خطب ما!',
                        type: 'error',
                        confirmButtonClass: "btn btn-danger",
                        icon: 'error',
                        timer: 1500,
                        timerProgressBar: true,
                    });
                }
                
            });
        },
        ReceiptGenerationandViewing: function(id)
        {
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }

            var forms = '';
            if(this.formName == undefined)
            {
                forms = 'PurchaseOrder';
            }
            else
            {
                forms = this.formName;
            }

            root.$https.get('/PaymentVoucher/GetSaleToVoucher?Id=' + id + '&formName=' + forms, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
                .then(function (response) {
                    if (response.data != null) {
                        root.$router.push({
                            path: '/addPaymentVoucher?formName=AdvancePurchase',
                            query: {
                                data: response.data,
                            }
                        })
                    } else {
                        root.$swal({
                            title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                            text: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Something Went Wrong!' : 'هل هناك خطب ما!',
                            type: 'error',
                            confirmButtonClass: "btn btn-danger",
                            icon: 'error',
                            timer: 1500,
                            timerProgressBar: true,
                        });
                    }
                });
        },
        search22: function () {
            this.getData(this.search, this.currentPage, this.active, this.fromDate, this.toDate, this.userId, this.customerType);
        },
        clearData: function () {
                this.search="";
                this.getData(this.search, this.currentPage, this.active,this.fromDate, this.toDate, this.userId, this.customerType); 

        },
        GetDate1: function () {

if (this.fromDate != '' || this.toDate != '') {
    this.isDisableMonth = true;
    this.year = '';
    this.month = '';
    this.monthObj = '';

} else {
    this.isDisableMonth = false;
}

        },
        GetMonth: function () {

            if (this.monthObj.month != undefined) {
                this.isDisable = true;
                this.fromDate = '';
                this.toDate = '';

                this.month = this.monthObj.monthIndex;
                this.year = this.monthObj.selectedYear;

            }

        },
        AdvanceFilterFor: function () {
                this.advanceFilters = !this.advanceFilters;
                if (this.advanceFilters == false) {
                    this.FilterRecord(false);
                }
        },
        FilterRecord: function (type) {
                if (type) {
                    if (this.fromDate != '') {
                        if (this.toDate == '') {
                            this.$swal({
                                title: 'Error',
                                text: "Please Select To Date ",
                                type: 'error',
                                icon: 'error',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                            });
    
                            return;
    
                        }
                    }
                    if (this.toDate != '') {
                        if (this.fromDate == '') {
                            this.$swal({
                                title: 'Error',
                                text: "Please Select From Date ",
                                type: 'error',
                                icon: 'error',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                            });
    
                            return;
    
                        }
                    }
                    if (this.paymentMode == 'السيولة النقدية') {
                        this.paymentMode = 'Cash';
                    }
                    if (this.paymentMode == 'مصرف') {
                        this.paymentMode = 'Bank';
                    }
    
                    if (this.monthObj.month != undefined) {
                        this.month = this.monthObj.monthIndex;
                        this.year = this.monthObj.selectedYear;
                    }
    
                    if (this.user.id != undefined) {
                        this.userId = this.user.id;
                    }
    
                } else {
                    this.isDisable = false;
                    this.isDisableMonth = false;
                    if (this.$refs.userDropdown != null) {
                        this.$refs.userDropdown.EmptyRecord();
                    }
                    if (this.$refs.supplierDropdown != null) {
                        this.$refs.supplierDropdown.EmptyRecord();
                    }
                    this.user = '';
                    this.supplierForFilterId = '';
    
                    this.year = '';
                    this.fromDate = '';
                    this.toDate = '';
                    this.month = '';
                    this.monthObj = '';
                    this.netAmount = '';
                    this.paymentMode = '';
                    this.customerType = '';
    
                    this.randerforempty++;
    
                }
                this.getData(this.search, this.currentPage, this.active,this.fromDate, this.toDate, this.userId, this.customerType); 
                
    
        },
        IsSave: function () {
            this.show = false;
        },
        EditPurchaseOrder: function (id,clone, isPurchaseOrder) {
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            var formName = this.formName == undefined ? '' : this.formName;
            root.$https.get('/Purchase/PurchaseOrderDetail?Id=' + id + '&isMultiUnit=' + this.isMultiUnit + '&documentType=' + formName, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
                .then(function (response) {
                    if (response.data != null) {
                        
                        if(isPurchaseOrder == "IsPurchaseOrder")
                        {
                            root.$router.push({
                                path: '/addpurchaseorder?clone=' + false +'&isConversion=true' ,
                                query: {
                                    data: response.data,
                                    formName:'PurchaseOrder'
                                }
                            })
                        }
                        else if(isPurchaseOrder == "PurchaseInvoice")
                        {
                            root.$router.push({
                                path: '/addpurchase?clone=PurchaseOrder'  +'&isConversion=true' ,
                                query: {
                                    data: response.data,
                                }
                            })
                        }
                        else if(isPurchaseOrder == "GoodReceive")
                        {
                            root.$router.push({
                                path: '/AddGoodReceive?clone='+clone    +'&isConversion=true' ,
                                query: {
                                    data: response.data,
                                }
                            })
                        }
                        else if (root.formName == 'SupplierQuotation') {
                            root.$router.push({
                                path: '/addpurchaseorder?formName=' + 'SupplierQuotation'+ '&clone=' + clone,
                                query: {
                                    data: response.data
                                }
                            })
                        } else {
                            root.$router.push({
                                path: '/addpurchaseorder?clone=' + clone,
                                query: {
                                    data: response.data
                                }
                            })
                        }

                    }
                },
                    function (error) {
                        root.loading = false;
                        console.log(error);
                    });
        },
        PrintRdlc: function (id) {
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            var formName = this.formName == undefined ? '' : this.formName;
            root.$https.get('/Purchase/PurchaseOrderDetail?Id=' + id + '&isMultiUnit=' + this.isMultiUnit + '&documentType=' + formName, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
                .then(function (response) {
                    if (response.data != null) {
                        
                        root.printDetails = response.data;
                        root.show = true;
                        root.printRender++;

                    }
                },
                    function (error) {
                        root.loading = false;
                        console.log(error);
                    });
        },

        GotoPage: function (link) {
            this.$router.push({
                path: link
            });
        },
       
       
        
        changeStatus: function (id) {
                    var root = this;
                    var token = '';
                    if (this.$session.exists()) {
                        token = localStorage.getItem('token');
                    }

                    var purchase = {
                        id: id,
                        isClose: true
                    };
                    this.$https.post('/Purchase/SavePurchaseOrderInformation', purchase, {
                        headers: {
                            "Authorization": `Bearer ${token}`
                        }
                    }).then(function (response) {
                        if (response.data.isSuccess == true) {
                            root.$swal({
                                title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved!' : '!تم الحفظ',
                                text: "Purchase Order Closed Successfully!",
                                type: 'success',
                                icon: 'success',
                                showConfirmButton: true
                            });
                            root.getPage();
                        }
                    });
        },
        paymentSave: function () {
            this.payment = false;
        },
        paymentModel: function (purchaseId, totalAmount, customerAccountId) {

            this.purchaseId = purchaseId;
            this.totalAmount = totalAmount;
            this.customerAccountId = customerAccountId;
            this.payment = true;
        },
        DeleteFile: function () {
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            this.$https.get('/Purchase/DeletePo', this.selected, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
                .then(function (response) {
                    if (response.data != null) {
                        if (response.data.message.id != '00000000-0000-0000-0000-000000000000') {
                            root.$swal({
                                title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Deleted!' : 'تم الحذف!',
                                text: response.data.message.isAddUpdate,
                                type: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonsStyling: false
                            }).then(function (result) {
                                if (result) {
                                    root.$router.push('/purchase');
                                }
                            });
                        } else {
                            root.$swal({
                                title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                                text: response.data.message.isAddUpdate,
                                type: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                        }
                    }
                },
                    function () {

                        root.$swal({
                            title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                            text: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Update UnSuccessfully' : 'التحديث غير ناجح',
                            type: 'error',
                            confirmButtonClass: "btn btn-danger",
                            buttonsStyling: false
                        });
                    });
        },
        select: function () {
            this.selected = [];
            if (!this.selectAll) {
                for (let i in this.purchaseOrderList) {
                    this.selected.push(this.purchaseOrderList[i].id);
                }
            }
        },
        getPage: function () {
            this.getData(this.search, this.currentPage, this.active, this.fromDate, this.toDate, this.userId, this.customerType);
        },

        makeActive: function (item) {

            this.active = item;
            this.selectAll = false;
            this.selected = [];
            this.getData(this.search, 1, item, this.fromDate, this.toDate, this.userId, this.customerType);
        },
        getData: function (search, currentPage, status, fromDate, toDate, userId, customerType) {
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }

            var formName = this.formName == undefined ? '' : this.formName;
            this.$https.get('/Purchase/PurchaseOrderList?status=' + status + '&searchTerm=' + search + '&pageNumber=' + currentPage + '&fromDate=' + fromDate + '&toDate=' + toDate + '&documentType=' + formName + '&supplierForFilterId=' + this.supplierForFilterId + '&netAmount=' + this.netAmount + '&month=' + this.month + '&year=' + this.year + '&userId=' + userId + '&customerType=' + customerType , {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
                .then(function (response) {
                    if (response.data != null) {
                        root.$store.dispatch('GetPurchaseOrderList', response.data.results);
                        root.purchaseOrderList = response.data.results;
                        root.pageCount = response.data.pageCount;
                        root.rowCount = response.data.rowCount;
                        root.currentPage = currentPage;
                        root.randerList++;
                    }
                });
        },
        RemovePurchaseOrder: function (id) {
            var root = this;
            // working with IE and Chrome both
            this.$swal({
                title: (this.$i18n.locale == 'en' || this.isLeftToRight()) ? 'Are you sure?' : 'هل أنت متأكد؟',
                text: (this.$i18n.locale == 'en' || this.isLeftToRight()) ? 'You will not be able to recover this!' : 'لن تتمكن من استرداد هذا!',
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: (this.$i18n.locale == 'en' || this.isLeftToRight()) ? 'Yes, delete it!' : 'نعم ، احذفها!',
                closeOnConfirm: false,
                closeOnCancel: false
            }).then(function (result) {
                if (result) {

                    var token = '';
                    if (root.$session.exists()) {
                        token = localStorage.getItem('token');
                    }
                    root.$https.get('/Purchase/DeletePurchaseOrder?Id=' + id, {
                        headers: {
                            "Authorization": `Bearer ${token}`
                        }
                    })
                        .then(function (response) {
                            if (response.data.message.id != '00000000-0000-0000-0000-000000000000') {

                                root.$store.state.purchaseOrderList.splice(root.$store.state.purchaseOrderList.findIndex(function (i) {
                                    return i.id === response.data.message.id;
                                }), 1);
                                root.$swal({
                                    title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Deleted!' : 'تم الحذف!',
                                    text: response.data.message.isAddUpdate,
                                    type: 'success',
                                    confirmButtonClass: "btn btn-success",
                                    buttonsStyling: false
                                });
                            } else {
                                root.$swal({
                                    title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                                    text: response.data.message.isAddUpdate,
                                    type: 'error',
                                    confirmButtonClass: "btn btn-danger",
                                    buttonsStyling: false
                                });
                            }
                        },
                            function () {

                                        root.$swal({
                                            title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                                            text: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Delete UnSuccessfully' : 'حذف غير ناجح',
                                            type: 'error',
                                            confirmButtonClass: "btn btn-danger",
                                            buttonsStyling: false
                                        });
                                    });
                        } else {
                            this.$swal((this.$i18n.locale == 'en' || this.isLeftToRight()) ? 'Cancelled!' : 'ألغيت!', (this.$i18n.locale == 'en' || this.isLeftToRight()) ? 'Your file is still intact!' : 'ملفك لا يزال سليما!', (this.$i18n.locale == 'en' || root.isLeftToRight()) ? 'info' : 'معلومات');
                        }
                    });
        },
        AddPurchaseOrder: function () {
            var root = this
            if (this.formName == 'SupplierQuotation') {
                root.$router.push({
                    path: '/addpurchaseorder?formName=' + 'SupplierQuotation',
                })
                localStorage.setItem('IsService', true);
            } else {
                this.$router.push('/addpurchaseorder')
            }

        },
        ViewPurchaseOrder: function (id) {
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            root.$https.get('/Purchase/PurchaseOrderDetail?Id=' + id + '&isPoView=' + true + '&isMultiUnit=' + this.isMultiUnit, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
                .then(function (response) {
                    if (response.data != null) {
                        root.$router.push({
                            path: '/PurchaseOrderView',
                            query: {
                                data: response.data
                            }
                        })
                    }
                },
                    function (error) {
                        root.loading = false;
                        console.log(error);
                    });
        },
       
       
    },
    created: function () {
               
                if (this.$route.query.data == 'purchaseorders') {
                    this.$emit('input', 'purchaseorders');

                } else {
                    this.$emit('input', this.$route.name);

                }

              
    },
    mounted: function () {
                
                this.english = 'true';
                this.arabic = localStorage.getItem('Arabic');
                this.internationalPurchase = localStorage.getItem('InternationalPurchase');
                this.isMultiUnit = localStorage.getItem('IsMultiUnit') == 'true' ? true : false;
                this.makeActive("Approved");
                this.currency = localStorage.getItem('currency');


    },
    updated: function () {
        if (this.selected.length < this.purchaseOrderList.length) {
            this.selectAll = false;
        } else if (this.selected.length == this.purchaseOrderList.length) {
            if (this.selected.length == 0) {
                this.selectAll = false;
            } else {
                this.selectAll = true
            }
        }
    }
}
</script>
<style scoped>
    /* Tooltip container */
    .tooltip {
        position: relative;
        display: inline-block;
        opacity: 1 !important;
        z-index: 1 !important;
        /* If you want dots under the hoverable text */
    }

        /* Tooltip text */
        .tooltip .tooltiptext {
            visibility: hidden;
            width: 150px;
            background-color: #555;
            color: #fff;
            text-align: center;
            padding: 5px 0;
            border-radius: 6px;
            /* Position the tooltip text */
            position: absolute;
            z-index: 1 !important;
            bottom: 125%;
            left: 50%;
            margin-left: -60px;
            font-weight: 700 !important;
            /* Fade in tooltip */
        }

            /* Tooltip arrow */
            .tooltip .tooltiptext::after {
                content: "";
                position: absolute;
                top: 100%;
                left: 40%;
                margin-left: -5px;
                border-width: 5px;
                border-style: solid;
                border-color: #555 transparent transparent transparent;
            }

        /* Show the tooltip text when you mouse over the tooltip container */
        .tooltip:hover .tooltiptext {
            visibility: visible;
            opacity: 1;
        }
</style>
