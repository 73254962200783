<template>
    <div class="row" v-if="isValid('CanViewProductInventoryReport')">

        <div class="col-lg-12">
            <div class="row">
                <div class="col-sm-12">
                    <div class="page-title-box">
                        <div class="row">
                            <div class="col">
                                <h4 class="page-title">{{ $t('TheProductInventoryReport.ProductInventoryReport') }}</h4>
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="javascript:void(0);">{{ $t('TheProductInventoryReport.Home') }}</a></li>
                                    <li class="breadcrumb-item active">{{$t('TheProductInventoryReport.ProductInventoryReport')}}</li>
                                </ol>
                            </div>
                            <div class="col-auto align-self-center">

                                <a v-on:click="PrintDetails" href="javascript:void(0);"
                                    class="btn btn-sm btn-outline-primary mx-1">
                                    <i class="far fa-file-excel font-14"></i>
                                    Print
                                </a>
                                <a v-on:click="GotoPage('/StartScreen')" href="javascript:void(0);"
                                   class="btn btn-sm btn-outline-danger">
                                    {{ $t('Categories.Close') }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                        <div class=" col-lg-3   form-group">
                            <label>{{ $t('TheProductInventoryReport.FromDate') }}</label>
                            <datepicker v-model="fromDate" :key="render" />
                        </div>
                        <div class=" col-lg-3   form-group">
                            <label>{{ $t('TheProductInventoryReport.ToDate') }}</label>
                            <datepicker v-model="toDate" :key="render" />
                        </div>
                        <!-- <div class=" col-lg-3   form-group">
                            <label>{{ $t('TheProductInventoryReport.WareHouseName') }}</label>
                            <warehouse-dropdown v-model="wareHouseId" v-on:input="getByWarehouse(wareHouseId)" />
                        </div> -->
                        <div class=" col-lg-3   form-group">
                            <label>{{ $t('TheProductInventoryReport.SearchByProductName') }} </label>
                            <input type="text" class="form-control" v-model="searchQuery" name="search" id="search"
                                :placeholder="$t('TheProductInventoryReport.Search')" />

                        </div>
                    </div>
            <div class="card">

                
                <div class="card-body">
                    <div v-for="(product) in resultQuery" v-bind:key="product.id">
                        <h5>{{ product.productName }} &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
                            {{ product.productNameAr == null || '' ? '' : product.productNameAr }}</h5>
                        <div class="table-responsive">
                            <table class="table mb-0 ">
                                <thead class="thead-light table-hover">
                                    <tr>
                                        <th>#</th>

                                        <th class="text-center">
                                            {{ $t('TheProductInventoryReport.Date') }}
                                        </th>

                                        <th class="text-center">
                                            {{ $t('TheProductInventoryReport.DocumentNumber') }}
                                        </th>

                                        <th class="text-center">
                                            {{ $t('TheProductInventoryReport.TransactionType') }}
                                        </th>
                                        <th class="text-center">
                                            {{ $t('TheProductInventoryReport.Price') }}
                                        </th>
                                        <!-- <th class="text-center">
                                            {{ $t('TheProductInventoryReport.AveragePrice') }}
                                        </th> -->
                                        <th class="text-center">
                                            {{ $t('TheProductInventoryReport.Quantity') }}
                                        </th>
                                        <th class="text-center">
                                            {{ $t('TheProductInventoryReport.CurrentQuantity') }}
                                        </th>

                                        <th class="text-center">
                                            {{ $t('TheProductInventoryReport.SalePrice') }}
                                        </th>

                                    </tr>
                                </thead>
                                <tbody>

                                    <tr v-for="(inventory, index) in product.productInventoryReport"
                                        v-bind:key="inventory.id">
                                        <td class="text-center">
                                            {{ index + 1 }}
                                        </td>
                                        <td class="text-center">
                                            {{ inventory.date }}
                                        </td>

                                        <td class="text-center">
                                            {{ inventory.documentNumber }}
                                            <!--{{ ($i18n.locale == 'en' ||isLeftToRight()) ? (inventory.productName != '' && inventory.productName != null) ? inventory.productName : inventory.productNameAr : (inventory.productNameAr != '' && inventory.productNameAr != null) ? inventory.productNameAr : inventory.productName}}-->
                                        </td>
                                        <td class="text-center">
                                            {{ getTransactionType(inventory.transactionType) }}
                                        </td>

                                        <td class="text-center">
                                            {{ inventory.price }}
                                        </td>
                                        <!-- <td class="text-center">
                                            {{ inventory.averagePrice }}
                                        </td> -->
                                        <td class="text-center">
                                            {{ inventory.transactionType == 'SaleReturn' ? '+' : inventory.transactionType == 'StockIn' ? '+' : inventory.transactionType == 'StockOut' ? '-' : inventory.transactionType == 'StockIn' ? '+' : inventory.transactionType == 'SaleInvoice' ? '-' : inventory.transactionType == 'PurchaseReturn' ? '-' : inventory.transactionType == 'PurchasePost' ? '+' : '-' }}
                                            {{ inventory.quantity }}
                                        </td>
                                        <td class="text-center">
                                            {{ inventory.currentQuantity }}
                                        </td>

                                        <td class="text-center">
                                            {{ inventory.salePrice }}
                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <hr />
                    </div>

                </div>
            </div>
            <product-inventory-Report-print  :isPrint="isShown" :fromDate="fromDate"
                :printDetails="resultQuery" :toDate="toDate" v-if="isShown"
                v-bind:key="printRender"></product-inventory-Report-print>

        </div>

    </div>
    <div v-else>
        <acessdenied></acessdenied>
    </div>
   </template>
<script>
import clickMixin from '@/Mixins/clickMixin'
import moment from "moment";

export default {
    mixins: [clickMixin],

    data: function () {
        return {
            render: 0,
            fromDate: '',
            toDate: '',
            searchQuery: '',
            currentPage: 1,
            pageCount: '',
            rowCount: '',
            invoice: '',
            productList: [],
            resultList: [],
            isShown: false,
            printRender: 0,
            advanceFilters: true,
            combineDate: '',
            openingCash: 0,
            totalBalance: 0,
            counter: 0,
            wareHouseId: '00000000-0000-0000-0000-000000000000',

            language: 'Nothing',
            headerFooter: {
                footerEn: '',
                footerAr: '',
                company: ''
            },
        }
    },
    computed: {
        resultQuery: function () {
            var root = this;
            if (this.searchQuery) {
                return root.productList.filter((inventory) => {

                    return root.searchQuery.toLowerCase().split(' ').every(v => inventory.productName.toLowerCase().includes(v) || inventory.productNameAr.toLowerCase().includes(v))
                })
            } else {
                return root.productList;
            }
        },
    },
    watch: {

        fromDate: function (fromDate) {
            this.isShown = false;
            {
                this.fromDate = fromDate;
                this.GetProductRecord(this.fromDate, this.toDate, this.wareHouseId);

            }
        },
        toDate: function (toDate) {
            this.isShown = false;

             {
                this.toDate = toDate;
                this.GetProductRecord(this.fromDate, this.toDate, this.wareHouseId);
            }
        }

    },
    methods: {
        GotoPage: function (link) {
            this.$router.push({ path: link });
        },
        PrintCsv: function () {

           
        },
        getTransactionType(transactionType) {
            if (transactionType == 'StockOut') return this.$t('TheProductInventoryReport.StockOut')
            if (transactionType == 'JournalVoucher') return 'Journal Voucher'
            if (transactionType == 'ExpenseVoucher') return 'Expense Voucher'
            if (transactionType == 'Expense') return 'Expense'
            if (transactionType == 'BankPay') return 'Bank Pay'
            if (transactionType == 'BankReceipt') return 'Bank Receipt'
            if (transactionType == 'StockIn') return this.$t('TheProductInventoryReport.StockIn')
            if (transactionType == 'SaleInvoice') return this.$t('TheProductInventoryReport.SaleInvoice')
            if (transactionType == 'PurchaseReturn') return this.$t('TheProductInventoryReport.PurchaseReturn')
            if (transactionType == 'PurchasePost') return this.$t('TheProductInventoryReport.Purchase')
            if (transactionType == 'CashReceipt') return this.$t('TheProductInventoryReport.Cash')
            if (transactionType == 'SaleReturn') return 'Sale Return'
        },
       
       
        getByWarehouse: function (x) {


            var wareHouseId = x;
            //this.GetHeaderDetail()
            this.GetProductRecord(this.fromDate, this.toDate, wareHouseId);
        },
        getDate: function (date) {
            return moment(date).format('l');
        },
        GetProductRecord: function (fromdate, todate) {
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            var url;

            url = '/Report/ProductInventoryReport?fromDate=' + fromdate + '&toDate=' + todate ;



            this.$https.get(url, { headers: { "Authorization": `Bearer ${token}` } })
                .then(function (response) {
                    if (response.data != null) {

                        root.productList = response.data;
                    }
                }).catch(error => {

                    console.log(error)
                    root.$swal.fire(
                        {
                            icon: 'error',
                            title: 'No Record Exist!',
                            text: error.response.data,
                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                        });

                   // root.loading = false
                });
        },
        PrintDetails: function () {

            var root = this;
            root.isShown = true;
            root.printRender++;
        },
        

    },
    mounted: function () {
        this.headerFooter.footerEn = localStorage.getItem('TermsInEng');
        this.headerFooter.footerAr = localStorage.getItem('TermsInAr');
        this.language = this.$i18n.locale;
        this.fromDate = moment().startOf('year').format('DD MMM YYYY');
        this.toDate = moment().format("DD MMM YYYY");
        this.render++;
    }
}
</script>
