<template>
    <modal show="show" :modalLarge="true">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title m-0" id="exampleModalDefaultLabel">View Document</h6>
                <button type="button" class="btn btn-primary" v-on:click="printInvoice()">&nbsp; Print</button>
                <button type="button" class="btn-close" v-on:click="close()"></button>
            </div>
            <div class="modal-body text-center">
                <main>
                    <div class="box box-border" ref="printable" id="printable">


                        <table style="width: 100%">
                            <thead v-if="isHeaderFooter=='true'">
                                <tr>
                                    <th>
                                        <HeaderComponent></HeaderComponent>
                                        
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div>

                                            <div class="invoice-box" style="padding-top: 16px">
                                                <table cellpadding="0" cellspacing="0" clas="invoice-services">
                                                    <tr class="heading">
                                                        <td style="border-right:1px dashed  black !important;border-left:1px solid  black !important;width: 10px"
                                                            class="text-left">
                                                            SL </td>
                                                        <td style="border-right:1px dashed  black !important;border-left:1px dashed  black !important;width: 210px"
                                                            class="text-left">
                                                            {{ $t('StockReport.Product') }}
                                                        </td>

                                                        <td style="border-right:1px dashed  black !important;border-left:1px dashed  black !important;width: 80px"
                                                            class="text-center">{{ $t('StockReport.StockValue') }}</td>
                                                        
                                                        <td style="border-right:1px dashed  black !important;border-left:1px dashed  black !important;width: 80px"
                                                            class="text-center">Average Price</td>
                                                        <td style="border-right:1px solid  black !important;border-left:1px dashed  black !important;width: 80px"
                                                            class="text-right">Total Price</td>
                                                    </tr>
                                                    <tr class="item invoice-item first"
                                                        v-for="(inventory, index) in printDetails" v-bind:key="inventory.id">
                                                        <td
                                                            style="border-right:1px dashed  black !important;border-left:1px solid  black !important">
                                                            {{ index + 1 }}</td>
                                                        <td style="border-right:1px dashed  black !important;border-left:1px dashed  black !important"
                                                            class="text-left" >{{ inventory.productName }}</td>
                                                        <td style="border-right:1px dashed  black !important;border-left:1px dashed  black !important"
                                                            class="text-right"> {{ inventory.balance }}
                                                        </td>
                                                        <td   style="border-right:1px dashed  black !important;border-left:1px dashed  black !important"
                                                            class="text-center">{{currency}} {{ inventory.avgPrice }}</td>
                                                        <td 
                                                            style="border-right:1px solid  black !important;border-left:1px dashed  black !important"
                                                            class="text-right">
                                                            {{currency}} {{(parseFloat(inventory.balance * inventory.avgPrice)).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                                        </td>
                                                    </tr>
                                                    <tr style="border-top: 1px solid;">
                                                        <td></td>
                                                        <td class="text-right" style="font-weight: bold;">Total</td>
                                                        <td class="text-right" >{{ totalQuantity }}</td>
                                                        <td class="text-right" >
                                                            {{currency}} {{(parseFloat(totalAvgPrice)).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</td>
                                                            <td class="text-right" >
                                                                {{currency}} {{(parseFloat(totalPrice)).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                            </tfoot>
                        </table>

                    </div>
                </main>
                <iframe ref="printFrame" style="display: none;"></iframe>
            </div>
        </div>
    </modal>
</template>
   


<script>
const options = {
    name: '',
    specs: [
        'fullscreen=no',
        'titlebar=yes',
        'scrollbars=yes'
    ],
    styles: [
        '/assets/css/bootstrap.min.css',
    ],
    timeout: 700,
    autoClose: true,
    windowTitle: window.document.title,

}

export default {
    props: ['printDetails', 'show'],

    data: function () {
        return {
            isHeaderFooter: '',
            isPrint: false,
            currency: '',
            invoicePrint: '',
            IsDeliveryNote: '',
            arabic: '',
            english: '',
            userName: '',
            isMultiUnit: '',
            itemTotal: '',
            listItemP1: [],
            listItemP2: [],
            listItemP3: [],
            isBank: false,

            list: {
                number: 0,

                listItemTotal: [],
                registrationNo: '',
                date: '',
                dueDate: '',
                companyName: '',
                companyPhoneNo: '',
                companyAddress: '',
                discountAmount: '',
                cashCustomer: '',
                creditCustomer: '',
                customerPhoneNo: '',
                customerAddress: '',
                paymentMethod: '',
                paymentMethodNo: '',
                invocieType: '',
                saleItems:
                    [

                    ]
            },
            render: 0,
            company: {
                logoPath: '',
                companyNameEnglish: '',
                nameInEnglish: '',
                phoneNo: '',
                addressInEnglish: '',
            },

        }
    },

    computed: {
            
            totalAvgPrice: function () {
                return this.printDetails.reduce(function (a, c) {


                    return a + Number((c.avgPrice.toFixed(3).slice(0, -1)) || 0)


                }, 0)
            },

            totalQuantity: function () {
                return this.printDetails.reduce(function (a, c) {


                    return a + Number((c.balance.toFixed(3).slice(0, -1)) || 0)


                }, 0)
            },
            totalPrice: function () {
                return this.printDetails.reduce(function (a, c) {


                    return a + (Number((c.balance.toFixed(3).slice(0, -1)) || 0) * Number((c.avgPrice.toFixed(3).slice(0, -1)) || 0))


                }, 0)
            },

        },
    methods: {
        printInvoice12: function () {

            var root = this;
            this.$htmlToPaper('purchaseInvoice', options, () => {

                root.$router.push({
                    path: 'SaleService'
                });




            });
        },
        printInvoice() {
            debugger;
            this.isPrint = true;

            var cssPath = window.location.origin + '/assets/css/A5Print.css';
            let printContents = this.$refs.printable.innerHTML;

            let printFrame = this.$refs.printFrame;

            if (printFrame) {
                let doc = printFrame.contentDocument || printFrame.contentWindow.document;
                doc.open();
                doc.write('<html><head><title>Invoice</title>');
                doc.write('<link rel="stylesheet" href="' + cssPath + '" type="text/css" />');
                doc.write('</head><body>');
                doc.write(printContents);
                doc.write('</body></html>');
                doc.close();

                printFrame.contentWindow.onload = function () {
                    printFrame.contentWindow.print();
                    this.isPrint = false;
                };
            } else {
                this.isPrint = false;
                // Handle if iframe reference is not available
            }
        },
       
        close: function () {
            debugger;
            this.$emit('close');
        },





    },
    mounted: function () {
        debugger;
        this.currency = localStorage.getItem('currency');
        this.isHeaderFooter = localStorage.getItem('IsHeaderFooter');
        

       
        //this.isMultiUnit = localStorage.getItem('IsMultiUnit'); 
        // if (this.printDetails.saleItems.length > 0) {
        //     this.list = this.printDetails;


        //     {




        //         this.list.date = moment(this.list.date).format('DD MMM YYYY');
                
        //     }

        // }
        // console.log('amb');
        // console.log(this.list)
    },

}
</script>

<style scoped>
.logowrapper img {
    height: 100%;
}


* {
    line-height: unset
}

.box {
    margin: auto;
    padding: 12px;
    font-size: 16px;
    color: #555;
}

.invoice-box table {
    width: 100%;
    line-height: inherit;
    text-align: left;
}

.invoice-box table td {
    padding: 5px;
    vertical-align: top;
}


.invoice-box table tr.top table td {
    padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
    font-size: 45px;
    line-height: 45px;
    color: #333;
}

.invoice-box table tr.information table td {
    padding-bottom: 40px;
}


.invoice-box table tr.details td {
    padding-bottom: 20px;
}





td.all-remark p,
td.all-remark h2,
td.all-remark h3 {
    margin: 2px 0;
}

td.all-remark blockquote {
    border-left: 4px solid #d8dbe1;
    padding-left: 4px;
    margin: 4px 2px;
}

td.all-remark pre {
    background: white;
    padding: 8px 12px;
    border-radius: 10px;
}


table.transaction-summary {
    border-collapse: collapse
}

.invoice-box table tr.heading td {
    background: #eee;
    font-weight: bold;
}

.bank-table,
.invoice-box table {
    width: 100%;
    border-collapse: collapse;
}

.bank-table td,
.invoice-box table td {
    border: 1px solid;
    border-collapse: collapse;
}




.bank-table td {
    padding: 4px 8px;
}

table.transaction-summary tr td {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.float-right {
    float: right;
}

.vertical-top {
    vertical-align: top
}

.font-bold {
    font-weight: bold;
}


.icon-info-table tr td:first-child.info {
    padding-right: 4px;
}

.icon-info-table tr td:last-child {
    padding-left: 4px;
}

.icon-info-table tr td.icon {
    width: 20px;
    vertical-align: top
}

.icon-info-table tr td.info {
    font-size: 14px;
}

.page-break {
    page-break-after: always;
}

.button {
    gap: .5rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: none;
    color: #306eb6;
    padding: 8px 0px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    border-bottom: 1px solid #306eb6;
}

.button svg {
    height: 20px;
}

thead th {
    height: 150px;
}

tfoot td {
    height: 30px;
}

.report-footer {
    position: fixed;
    bottom: 0px;
    height: 30px;
    display: block;
    width: 100%;
    text-align: center;
    /*border-top: solid 1px #ccc;*/
}

.box {
    width: 1000px;
}

.logo {
    max-height: 100px;
    object-fit: contain;
    object-position: top left;
}

.icon-info-table tr td.icon img {
    width: 15px;
    height: 15px;
    /* position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%) */
}
</style>