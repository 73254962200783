<template>
    <div class="row">
        <div class="col-lg-12 ">
            <div class="row">
                <div class="col-4">
                    <h4 class="mb-0" v-if="newCustomer.id == '00000000-0000-0000-0000-000000000000'">{{
                        'New ' + newCustomer.customerType }}
                        <span>{{ contactCode }}</span>
                    </h4>
                    <h4 class="mb-0" v-else>{{ 'Update ' + newCustomer.customerType }}</h4>
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">{{ $t('AddCustomer.Home') }}</a></li>
                        <li class="breadcrumb-item active">{{ newCustomer.customerType }}</li>
                    </ol>
                </div>
                <div class="col-lg-4 mt-2" v-if="!isCaseUser && newCustomer.isCustomer">
                    <div class="form-check form-check-inline">
                        <input v-model="newCustomer.isCashCustomer" name="contact-sub-type12" id="a49946498"
                            class=" form-check-input" type="radio" v-bind:value="true">
                        <label class="form-check-label pl-0" for="a49946498">Regular</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input v-model="newCustomer.isCashCustomer" name="contact-sub-type12" id="a9ff8eb36"
                            class=" form-check-input" type="radio" v-bind:value="false">
                        <label class="form-check-label pl-0" for="a9ff8eb36">Retailer</label>
                    </div>
                </div>

            </div>

        </div>
        <div class="card">
            <div class="card-body">


                <div class="row ">
                    <div class="col-lg-12">
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" data-bs-toggle="tab" href="#home" role="tab"
                                    aria-selected="true">Primary Information</a>
                            </li>
                            <!-- <li class="nav-item">
                                <a class="nav-link" data-bs-toggle="tab" href="#profile" role="tab" aria-selected="false">{{
                                    $t('AddCustomer.PaymentTerms') }}</a>
                            </li> -->
                            <!-- <li class="nav-item">
                                <a class="nav-link" data-bs-toggle="tab" href="#settings" role="tab"
                                    aria-selected="false">{{ $t('AddCustomer.OtherDetails') }}</a>
                            </li> 
                             <li class="nav-item">
                                <a class="nav-link" data-bs-toggle="tab" href="#Address" role="tab" aria-selected="false">{{ $t('AddCustomer.Address') }}</a>
                            </li>  -->




                            <li class="nav-item"
                                v-if="havePermission('Contact Attachment') || (isCaseUser && newCustomer.isActive)">
                                <a class="nav-link" data-bs-toggle="tab" href="#Remarks" role="tab"
                                    aria-selected="false">Attachements</a>
                            </li>
                            <li class="nav-item"
                                v-if="newCustomer.id != '00000000-0000-0000-0000-000000000000' && havePermission('Contact Contract') && !isCaseUser">
                                <a class="nav-link" data-bs-toggle="tab" href="#contract" role="tab"
                                    aria-selected="false">Contract</a>
                            </li>
                            <li class="nav-item"
                                v-if="newCustomer.id != '00000000-0000-0000-0000-000000000000' && !isCaseUser">
                                <a class="nav-link" v-if="newCustomer.isCustomer" data-bs-toggle="tab" href="#Sale"
                                    role="tab" v-on:click="getPage()" aria-selected="false">Sale</a>
                                <a class="nav-link" v-else data-bs-toggle="tab" href="#Sale" role="tab"
                                    v-on:click="getPurchase()" aria-selected="false">Purchase</a>
                            </li>
                            <li class="nav-item"
                                v-if="newCustomer.id != '00000000-0000-0000-0000-000000000000' && !isCaseUser">
                                <a class="nav-link" data-bs-toggle="tab" href="#PaymentVoucher" role="tab"
                                    v-on:click="paymentVoucherClick()" aria-selected="false">Balance</a>
                            </li>
                            <li class="nav-item"
                                v-if="newCustomer.id != '00000000-0000-0000-0000-000000000000' && havePermission('Contact Email') && !isCaseUser">
                                <a class="nav-link" data-bs-toggle="tab" href="#email" role="tab"
                                    aria-selected="false">Email</a>
                            </li>

                        </ul>
                        <div class="tab-content">
                            <div class="tab-pane p-3 active" id="home" role="tabpanel">
                                <div class="row">
                                    <div class="col-lg-4">
                                        <label>Name: <span class="text-danger"> *</span></label>

                                        <input v-model="newCustomer.englishName"
                                            :placeholder="$t('AddCustomer.EnglishName')" class="form-control"
                                            type="text">

                                    </div>
                                    <div class="col-lg-4">


                                        <label v-if="isCaseUser">Date of Birth: <span class="text-danger">
                                                *</span></label>
                                        <label v-else>Registration Date: <span class="text-danger"> *</span></label>

                                        <datepicker v-model="newCustomer.registrationDate" v-bind:key="randerDate">
                                        </datepicker>

                                    </div>
                                    <div class="col-lg-4">


                                        <label>Phone No: </label>

                                        <input placeholder="" v-model="newCustomer.contactNo1" class="form-control"
                                            type="text">

                                    </div>
                                    <div class="col-lg-4">


                                        <label>Telephone No: </label>

                                        <input placeholder="" v-model="newCustomer.telephone" class="form-control"
                                            type="text">

                                    </div>
                                    <div class="col-lg-4" v-if="isCaseUser">
                                        <label>{{ $t('AddCustomer.Email') }}</label>
                                        <input v-model="newCustomer.email" disabled class="form-control" type="text">


                                    </div>
                                    <div class="col-lg-4" v-else>
                                        <label>{{ $t('AddCustomer.Email') }}</label>
                                        <input v-model="newCustomer.email" class="form-control" type="text">


                                    </div>
                                    <div class="col-lg-4">
                                        <label>{{ $t('AddCustomer.Address') }}</label>
                                        <textarea v-model="newCustomer.billingAddress" rows="3"
                                            class="form-control "></textarea>
                                    </div>
                                    <div class="col-lg-4" v-if="!isCaseUser">
                                        <div class="checkbox form-check-inline mx-2">
                                            <input type="checkbox" id="inlineCheckbox1" v-model="newCustomer.isActive">
                                            <label for="inlineCheckbox1"> {{ $t('AddCustomer.Active') }} </label>
                                        </div>
                                    </div>


                                    <!-- <div class="col-lg-4">
                                        <label>{{ $t('AddCustomer.VAT/NTN/Tax No') }} </label>
                                        <input v-model="newCustomer.vatNo" class="form-control" type="text">
                                    </div>
                                    <div class="col-lg-4">
                                        <label>CommercialRegistrationNo </label>
                                        <input v-model="newCustomer.commercialRegistrationNo" class="form-control"
                                            type="text">
                                    </div> -->
                                    <!-- <div class="col-lg-4" v-if="formName == 'Customer'">
                                        <label>Cutomer Category </label>
                                        <multiselect v-model="newCustomer.category" :options="['Sale', 'Purchase', 'Ticket']"
                                            :show-labels="false" placeholder="Seletct Category">
                                        </multiselect>
                                    </div> -->

                                </div>

                            </div>
                            <div class="tab-pane p-3" id="profile" role="tabpanel">
                                <div class="row">
                                    <div class="col-lg-8">
                                        <div class="row form-group">
                                            <label class="col-lg-3">{{ $t('AddCustomer.PaymentTerms') }}</label>
                                            <div class="col-lg-3 form-group">
                                                <multiselect v-model="newCustomer.paymentTerms"
                                                    v-bind:disabled="newCustomer.isCashCustomer" :preselect-first="true"
                                                    v-if="($i18n.locale == 'en' || isLeftToRight())"
                                                    :options="['Cash', 'Credit']" :show-labels="false"
                                                    :placeholder="$t('AddCustomer.SelectType')">
                                                </multiselect>
                                                <multiselect v-else v-model="newCustomer.paymentTerms"
                                                    v-bind:disabled="newCustomer.isCashCustomer" :preselect-first="true"
                                                    :options="['نقد', 'آجل']" :show-labels="false"
                                                    v-bind:placeholder="$t('AddCustomer.SelectOption')">
                                                </multiselect>
                                            </div>
                                        </div>
                                        <div class="row form-group" v-if="newCustomer.paymentTerms == 'Credit'">
                                            <label class="col-lg-3"></label>
                                            <div class="col-lg-3 form-group">
                                                <input v-model="newCustomer.creditLimit"
                                                    :placeholder="$t('AddCustomer.CreditLimit')" class="form-control"
                                                    type="number">
                                            </div>
                                            <div class="col-lg-3 form-group">
                                                <input v-model="newCustomer.creditPeriod"
                                                    :placeholder="$t('AddCustomer.CreditPeriod')" class="form-control"
                                                    type="number">
                                            </div>
                                        </div>
                                        <div class="row form-group">
                                            <label class="col-lg-3">{{ $t('AddCustomer.DeliveryTerm') }}</label>
                                            <div class="col-lg-6">
                                                <input v-model="newCustomer.deliveryTerm" class="form-control"
                                                    type="text">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="tab-pane p-3" id="settings" role="tabpanel">
                                <div class="row">
                                    <div class="col-lg-8">

                                        <div class="row form-group">
                                            <label class="col-lg-3 ">{{ $t('AddCustomer.Address') }}</label>
                                            <div class="col-lg-6">
                                                <textarea v-model="newCustomer.billingAddress" rows="3"
                                                    class="form-control "></textarea>
                                            </div>
                                        </div>
                                        <div class="row form-group">
                                            <label class="col-lg-3 ">{{ $t('AddCustomer.Currency') }}</label>
                                            <div class="col-lg-6">
                                                <input v-model="newCustomer.prefix">
                                            </div>
                                        </div>


                                        <div class="row form-group mt-4">
                                            <label class="col-lg-3">{{ $t('AddCustomer.Facebook') }}</label>
                                            <div class="col-lg-6">

                                                <div class="input-group ">
                                                    <button class="btn btn-secondary" type="button"
                                                        id="button-addon1"><i
                                                            class="fab fa-facebook-square"></i></button>
                                                    <input type="text" class="form-control" placeholder=""
                                                        aria-label="Example text with button addon"
                                                        aria-describedby="button-addon1">
                                                </div>

                                            </div>
                                        </div>

                                        <div class="row form-group">
                                            <label class="col-lg-3">{{ $t('AddCustomer.Twitter') }}</label>
                                            <div class="col-lg-6">
                                                <div class="input-group ">
                                                    <button class="btn btn-secondary" type="button"
                                                        id="button-addon1"><i
                                                            class="fab fa-twitter-square"></i></button>
                                                    <input type="text" class="form-control" placeholder=""
                                                        aria-label="Example text with button addon"
                                                        aria-describedby="button-addon1">
                                                </div>
                                                <!--<div class="input-group mb-0">
                                                    <div class="input-group-prepend">
                                                        <span style="background- : #e3ebf1;border: 1px solid #ffffff00;" class="input-group-text"><i class="fab fa-twitter-square"></i></span>
                                                    </div>
                                                    <input type="text" class="form-control" style="border-left: 1 !important;">
                                                </div>
                                                <span class="text-muted font-xs">http://www.twitter.com/<strong></strong></span>-->

                                            </div>
                                        </div>
                                        <div class="row form-group">
                                            <label class="col-lg-3">{{ $t('AddCustomer.Website') }}</label>
                                            <div class="col-lg-6">
                                                <input v-model="newCustomer.website" class="form-control" type="text">
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div class="tab-pane p-3" id="Address" role="tabpanel">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="row mb-3">
                                            <label class="add-header col-lg-12">{{ $t('AddCustomer.BillingAddress')
                                                }}</label>
                                        </div>
                                        <fieldset class="form-group">
                                            <div class="row">
                                                <label class="col-lg-4 ">{{ $t('AddCustomer.Attention') }}</label>
                                                <div class="col-lg-7 ">
                                                    <input v-model="newCustomer.billingAttention" class="form-control "
                                                        type="text">
                                                </div>
                                            </div>
                                        </fieldset>
                                        <fieldset class="form-group">
                                            <div class="row">
                                                <label class="col-lg-4 ">{{ $t('AddCustomer.Country') }}</label>
                                                <div class="col-lg-7 ">
                                                    <input v-model="newCustomer.billingCountry" class="form-control "
                                                        type="text">
                                                    <!-- <countrydropdown v-model="newCustomer.billingCountry"
                                                        :values="newCustomer.billingCountry" /> -->
                                                </div>
                                            </div>
                                        </fieldset>

                                        <fieldset class="form-group">
                                            <div class="row">
                                                <label class="col-lg-4 ">{{ $t('AddCustomer.City') }}</label>
                                                <div class="col-lg-7 ">
                                                    <input v-model="newCustomer.billingCity" class="form-control "
                                                        type="text">
                                                </div>
                                            </div>
                                        </fieldset>
                                        <fieldset class="form-group">
                                            <div class="row">
                                                <label class="col-lg-4 ">{{ $t('AddCustomer.State') }}</label>
                                                <div class="col-lg-7 ">
                                                    <input v-model="newCustomer.billingArea" class="form-control "
                                                        type="text">
                                                </div>
                                            </div>
                                        </fieldset>
                                        <fieldset class="form-group">
                                            <div class="row">
                                                <label class="col-lg-4 ">{{ $t('AddCustomer.ZipCode') }}</label>
                                                <div class="col-lg-7 ">
                                                    <input v-model="newCustomer.billingZipCode" class="form-control "
                                                        type="text">
                                                </div>
                                            </div>
                                        </fieldset>
                                        <fieldset class="form-group">
                                            <div class="row">
                                                <label class="col-lg-4 ">{{ $t('AddCustomer.Phone') }}</label>
                                                <div class="col-lg-7 ">
                                                    <input v-model="newCustomer.billingPhone" class="form-control "
                                                        type="text">
                                                </div>
                                            </div>
                                        </fieldset>
                                        <fieldset class="form-group">
                                            <div class="row">
                                                <label class="col-lg-4 ">{{ $t('AddCustomer.Fax') }}</label>
                                                <div class="col-lg-7 ">
                                                    <input v-model="newCustomer.billingFax" class="form-control "
                                                        type="text">
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="row  mb-3">
                                            <label class="add-header col-lg-6">{{ $t('AddCustomer.ShippingAddress')
                                                }}</label>

                                        </div>
                                        <fieldset class="form-group">
                                            <div class="row">
                                                <label class="col-lg-4 ">{{ $t('AddCustomer.Attention') }}</label>
                                                <div class="col-lg-7 ">
                                                    <input v-model="newCustomer.shippingAttention" class="form-control "
                                                        type="text">
                                                </div>
                                            </div>
                                        </fieldset>
                                        <fieldset class="form-group">
                                            <div class="row">
                                                <label class="col-lg-4 ">{{ $t('AddCustomer.Country') }}</label>
                                                <div class="col-lg-7 ">
                                                    <input v-model="newCustomer.shippingCountry" class="form-control "
                                                        type="text">
                                                    <!-- <countrydropdown v-model="newCustomer.shippingCountry"
                                                        :values="newCustomer.shippingCountry" /> -->
                                                </div>
                                            </div>
                                        </fieldset>
                                        <fieldset class="form-group">
                                            <div class="row">
                                                <label class="col-lg-4 ">{{ $t('AddCustomer.Address') }}</label>
                                                <div class="col-lg-7 ">
                                                    <textarea v-model="newCustomer.shippingAddress" rows="3"
                                                        class="form-control "></textarea>
                                                </div>
                                            </div>
                                        </fieldset>

                                        <fieldset class="form-group">
                                            <div class="row">
                                                <label class="col-lg-4 ">{{ $t('AddCustomer.City') }}</label>
                                                <div class="col-lg-7 ">
                                                    <input v-model="newCustomer.shippingCity" class="form-control "
                                                        type="text">
                                                </div>
                                            </div>
                                        </fieldset>
                                        <fieldset class="form-group">
                                            <div class="row">
                                                <label class="col-lg-4 ">{{ $t('AddCustomer.State') }}</label>
                                                <div class="col-lg-7 ">
                                                    <input v-model="newCustomer.shippingArea" class="form-control "
                                                        type="text">
                                                </div>
                                            </div>
                                        </fieldset>
                                        <fieldset class="form-group">
                                            <div class="row">
                                                <label class="col-lg-4 ">{{ $t('AddCustomer.ZipCode') }}</label>
                                                <div class="col-lg-7 ">
                                                    <input v-model="newCustomer.shippingZipCode" class="form-control "
                                                        type="text">
                                                </div>
                                            </div>
                                        </fieldset>
                                        <fieldset class="form-group">
                                            <div class="row">
                                                <label class="col-lg-4 ">{{ $t('AddCustomer.Phone') }}</label>
                                                <div class="col-lg-7 ">
                                                    <input v-model="newCustomer.shippingPhone" class="form-control "
                                                        type="text">
                                                </div>
                                            </div>
                                        </fieldset>
                                        <fieldset class="form-group">
                                            <div class="row">
                                                <label class="col-lg-4 ">{{ $t('AddCustomer.Fax') }}</label>
                                                <div class="col-lg-7 ">
                                                    <input v-model="newCustomer.shippingFax" class="form-control "
                                                        type="text">
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>

                            </div>


                            <div class="tab-pane p-3" id="Remarks" role="tabpanel">

                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="page-title-box">
                                            <div class="row">
                                                <div class="col">
                                                    <h4 class="page-title">Files</h4>

                                                </div><!--end col-->
                                                <div class="col-auto align-self-center">
                                                    <button class="btn btn-outline-primary btn-sm add-file mx-2"
                                                        v-on:click="OpenFolder()"><i
                                                            class="fas fa-plus me-2 "></i>Create
                                                        Folder</button>
                                                    <div class="add-file btn btn-outline-primary btn-sm position-relative overflow-hidden"
                                                        v-on:click="Attachment(false)">
                                                        <i class="las la-cloud-upload-alt me-2 font-15"></i>Upload File
                                                    </div>


                                                </div><!--end col-->
                                            </div><!--end row-->
                                        </div><!--end page-title-box-->
                                    </div><!--end col-->
                                </div><!--end row-->
                                <!-- end page title end breadcrumb -->
                                <div class="row">
                                    <div class="col-lg-3">
                                        <div class="card">
                                            <div class="card-header">
                                                <div class="row align-items-center">
                                                    <div class="col">
                                                        <h4 class="card-title">Categories</h4>
                                                    </div><!--end col-->
                                                    <div class="col-auto">
                                                        <div class="dropdown">
                                                            <a href="#"
                                                                class="btn btn-sm btn-outline-light dropdown-toggle"
                                                                data-bs-toggle="dropdown" aria-haspopup="true"
                                                                aria-expanded="false">
                                                                <!-- <i class="las la-menu align-self-center text-muted icon-xs"></i>  -->
                                                                <i class="mdi mdi-dots-horizontal text-muted"></i>
                                                            </a>

                                                        </div>
                                                    </div><!--end col-->
                                                </div> <!--end row-->
                                            </div><!--end card-header-->
                                            <div class="card-body">
                                                <div class="files-nav">
                                                    <div class="nav flex-column nav-pills" id="files-tab"
                                                        aria-orientation="vertical">
                                                        <a class="nav-link "
                                                            v-bind:class="item.isActive ? 'active' : ''"
                                                            v-for=" item in folderList" v-bind:key="item.name"
                                                            id="files-projects-tab" data-bs-toggle="pill"
                                                            href="#files-projects" aria-selected="true">

                                                            <i data-feather="folder"
                                                                class="align-self-center icon-dual-file icon-sm me-3"></i>
                                                            <div class="d-inline-block align-self-center"
                                                                v-on:click="GetCurrentFolder(item.name)">
                                                                <h5 class="m-0">{{ item.name }}</h5>
                                                            </div>
                                                        </a>


                                                    </div>
                                                </div>
                                            </div><!--end card-body-->
                                        </div><!--end card-->


                                    </div><!--end col-->

                                    <div class="col-lg-9">
                                        <div class="">
                                            <div class="tab-content" id="files-tabContent">
                                                <div class="tab-pane fade show active" id="files-projects">
                                                    <div class="file-box-content">
                                                        <div class="file-box"
                                                            v-for=" attchments in attachmentListFolder"
                                                            v-bind:key="attchments.path">
                                                            <!-- <a v-on:click="ViewAttachment(attchments.path)"
                                                                class="download-icon-link">
                                                                <i class="dripicons-preview"></i>
                                                            </a> -->
                                                            <a v-on:click="DownloadAttachment(attchments.path)"
                                                                class="download-icon-link">
                                                                <i class="dripicons-download file-download-icon"></i>
                                                            </a>
                                                            <div class="text-center">
                                                                <i :class="getFileIconClass(attchments.fileName)"></i>
                                                                <h6 class="text-truncate">{{ attchments.fileName }}</h6>
                                                                <small class="text-muted">{{ attchments.date }}</small>
                                                            </div>
                                                        </div>

                                                    </div>


                                                </div><!--end tab-pane-->


                                            </div> <!--end tab-content-->
                                        </div><!--end card-body-->
                                    </div><!--end col-->
                                </div><!--end row-->
                                <div class="row">

                                    <div class="card">

                                        <bulk-attachment :attachmentList="newCustomer.attachmentList" :show="show"
                                            v-if="show" @close="attachmentSave" />
                                    </div>

                                </div>
                            </div>
                            <div class="tab-pane p-3" id="contract" role="tabpanel">
                                <div class="row">

                                    <a v-on:click="PrintView()" href="javascript:void(0);"
                                        class="btn btn-sm btn-outline-primary mx-1">
                                        View
                                    </a>
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-6">
                                                    <colordropdown v-model="colorObj" @input="GetRecord()">
                                                    </colordropdown>
                                                </div>

                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <VueEditor v-model="colorObj.description" ref="editor" />
                                                </div>


                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div class="tab-pane p-3" id="Sale" role="tabpanel">
                                <div class="row">
                                    <div class="row">
                                        <div class="col-lg-3">
                                            <div class="form-group">
                                                <label>{{ $t('Sale.FromDate') }}</label>
                                                <datepicker v-model="fromDate" v-bind:isDisable="isDisable"
                                                    v-on:input="GetDate1" v-bind:key="randerforempty" />
                                            </div>
                                        </div>
                                        <div class="col-lg-3">
                                            <div class="form-group">
                                                <label>{{ $t('Sale.ToDate') }}</label>
                                                <datepicker v-model="toDate" v-bind:isDisable="isDisable"
                                                    v-on:input="GetDate1" v-bind:key="randerforempty" />
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">

                                            <button v-on:click="FilterRecord(true)" type="button"
                                                class="btn btn-outline-primary mt-3">
                                                {{ $t('Sale.ApplyFilter') }}
                                            </button>
                                            <button v-on:click="FilterRecord(false)" type="button"
                                                class="btn btn-outline-primary mx-2 mt-3">
                                                {{ $t('Sale.ClearFilter') }}
                                            </button>
                                            <button v-on:click="GetInvoiceRecord()" type="button"
                                                class="btn btn-outline-primary mx-2 mt-3">
                                                Print
                                            </button>

                                        </div>
                                    </div>

                                    <div class="table-responsive">
                                        <table class="table mb-0">
                                            <thead class="thead-light table-hover">
                                                <tr>
                                                    <th>#</th>
                                                    <th>
                                                        {{ $t('Sale.InvoiceNo') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('Sale.Date') }}
                                                    </th>
                                                    <th>
                                                        Due Amount
                                                    </th>
                                                    <th>
                                                        Received Amount
                                                    </th>

                                                    <th>
                                                        {{ $t('Sale.NetAmount') }}
                                                    </th>
                                                    <th class="text-end">

                                                    </th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(sale, index) in saleList" v-bind:key="index">
                                                    <td>
                                                        {{ index + 1 }}
                                                    </td>

                                                    <td>
                                                        {{ sale.registrationNumber }}

                                                    </td>
                                                    <td>
                                                        {{ getDate(sale.date) }}
                                                    </td>
                                                    <td>
                                                        {{ sale.dueAmount }}
                                                    </td>
                                                    <td>
                                                        {{ sale.receivedAmount }}
                                                    </td>

                                                    <td>
                                                        {{ currency }}
                                                        {{ parseFloat(sale.netAmount).toFixed(3).slice(0,
                        -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                            "$1,") }}
                                                    </td>
                                                    <td class="text-end">
                                                        <button type="button" class="btn btn-light dropdown-toggle"
                                                            data-bs-toggle="dropdown" aria-expanded="false">{{
                        $t('Sale.Action') }}
                                                            <i class="mdi mdi-chevron-down"></i></button>
                                                        <div class="dropdown-menu">



                                                            <a class="dropdown-item" href="javascript:void(0)"
                                                                v-on:click="PrintRdlc(sale.id, 'print')"
                                                                v-if="newCustomer.isCustomer">{{ $t('Sale.A4Print') }}
                                                            </a>

                                                            <a class="dropdown-item" href="javascript:void(0)"
                                                                v-on:click="PrintPurchaseRdlc(sale.id, 'print')"
                                                                v-else>{{ $t('Sale.A4Print') }} </a>


                                                        </div>
                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>
                                        <hr />
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <span v-if="currentPage === 1 && rowCount === 0">
                                                    {{
                        $t('Pagination.ShowingEntries')
                    }}
                                                </span>
                                                <span v-else-if="currentPage === 1 && rowCount < 10">
                                                    {{ $t('Pagination.Showing') }}
                                                    {{ currentPage }} {{ $t('Pagination.to') }} {{ rowCount }} {{
                        $t('Pagination.of') }}
                                                    {{ rowCount }} {{ $t('Pagination.entries') }}
                                                </span>
                                                <span v-else-if="currentPage === 1 && rowCount >= 11">
                                                    {{ $t('Pagination.Showing') }}
                                                    {{ currentPage }} {{ $t('Pagination.to') }} {{ currentPage * 10 }}
                                                    {{
                        $t('Pagination.of')
                    }} {{ rowCount }} {{ $t('Pagination.entries') }}
                                                </span>
                                                <span v-else-if="currentPage === 1">
                                                    {{ $t('Pagination.Showing') }} {{ currentPage }} {{
                        $t('Pagination.to')
                    }} {{ currentPage * 10 }} of {{ rowCount }} {{
                        $t('Pagination.entries')
                    }}
                                                </span>
                                                <span v-else-if="currentPage !== 1 && currentPage !== pageCount">
                                                    {{
                        $t('Pagination.Showing')
                    }} {{ (currentPage * 10) - 9 }} {{ $t('Pagination.to') }}
                                                    {{ currentPage * 10 }} {{ $t('Pagination.of') }} {{ rowCount }} {{
                        $t('Pagination.entries')
                    }}
                                                </span>
                                                <span v-else-if="currentPage === pageCount">
                                                    {{ $t('Pagination.Showing') }}
                                                    {{ (currentPage * 10) - 9 }} {{ $t('Pagination.to') }} {{ rowCount
                                                    }} {{
                        $t('Pagination.of')
                    }} {{ rowCount }} {{ $t('Pagination.entries') }}
                                                </span>
                                            </div>
                                            <div class=" col-lg-6">
                                                <div class=" float-end" v-on:click="getPage()">
                                                    <b-pagination pills size="sm" v-model="currentPage"
                                                        :total-rows="rowCount" :per-page="10"
                                                        :first-text="$t('Table.First')"
                                                        :prev-text="$t('Table.Previous')" :next-text="$t('Table.Next')"
                                                        :last-text="$t('Table.Last')">
                                                    </b-pagination>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div class="tab-pane p-3" id="PaymentVoucher" role="tabpanel">
                                <div class="row">
                                    <!-- <div class="col-8" v-if="newCustomer.runningBalance > 0">
                                        <label>{{ newCustomer.runningBalance.startsWith('Dr') ? 'Due Balance' : 'Pay Balance' }}: <span style="font-weight: bold;font-size: larger;"> {{ newCustomer.runningBalance }}</span></label>


                                    </div> -->
                                    <div class="col-8"></div>
                                    <div class="col-4">
                                        <div class="col-auto align-self-center">
                                            <a v-on:click="showPV = true" href="javascript:void(0);"
                                                class="btn btn-sm btn-outline-primary mx-1">
                                                <i class="align-self-center icon-xs ti-plus"></i>
                                                {{ $t('PaymentVoucherList.AddNew') }}
                                            </a>

                                        </div>
                                    </div>
                                    <div class="table-responsive">
                                        <table class="table mb-0">
                                            <thead class="thead-light table-hover">
                                                <tr>
                                                    <th style="width:40px;">#</th>
                                                    <th style="width:150px;">
                                                        {{ $t('PaymentVoucherList.VoucherNumber') }}
                                                    </th>
                                                    <th style="width:130px;">
                                                        {{ $t('PaymentVoucherList.CreatedDate') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('Voucher Date') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('PaymentVoucherList.CreatedBy') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('PaymentVoucherList.DraftBy') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('PaymentVoucherList.PaymentMode') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('PaymentVoucherList.PaymentType') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('PaymentVoucherList.BankCashAccount') }}

                                                    </th>
                                                    <th>
                                                        <span
                                                            v-if="formName == 'CashReceipt' || formName == 'AdvanceReceipt' || formName == 'BankReceipt' || formName == 'PettyCash'">
                                                            {{ $t('PaymentVoucherList.CustomerAccount') }}
                                                        </span>
                                                        <span
                                                            v-if="formName == 'BankPay' || formName == 'AdvancePurchase' || formName == 'CashPay'">
                                                            {{ $t('PaymentVoucherList.SupplierAccount') }}
                                                        </span>
                                                    </th>
                                                    <th v-if="allowBranches">
                                                        {{ $t('DailyExpense.BranchCode') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('PaymentVoucherList.NetAmount') }}
                                                    </th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(voucher, index) in vouchersList" v-bind:key="voucher.id">

                                                    <td>
                                                        {{ index + 1 }}
                                                    </td>

                                                    <td
                                                        v-if="(isValid('CanEditPettyCash') && formName == 'PettyCash') || (isValid('CanEditCPR') && (formName == 'BankReceipt' || formName == 'AdvanceReceipt') || formName == 'AdvanceReceipt') || (isValid('CanEditSPR') && formName == 'BankPay' || formName == 'AdvancePurchase')">
                                                        <strong>
                                                            <a href="javascript:void(0)"
                                                                v-on:click="EditPaymentVoucher(voucher.id)">
                                                                {{ voucher.voucherNumber }}
                                                            </a>
                                                        </strong>
                                                    </td>
                                                    <td v-else>
                                                        {{ voucher.voucherNumber }}
                                                    </td>
                                                    <td>
                                                        {{ getDate(voucher.date) }}
                                                    </td>
                                                    <td>
                                                        {{ getDate(voucher.paymentDate) }}
                                                    </td>
                                                    <td>
                                                        {{ voucher.draftBy }}
                                                    </td>
                                                    <td>
                                                        {{ voucher.draftBy }}
                                                    </td>
                                                    <td>
                                                        <div class="badge badge-soft-primary"
                                                            v-if="voucher.paymentMode == 0">
                                                            {{ ($i18n.locale == 'en' || isLeftToRight()) ? ' Cash' : ''
                                                            }}

                                                        </div>
                                                        <div class="badge badge-soft-success"
                                                            v-if="voucher.paymentMode == 1">
                                                            {{ ($i18n.locale == 'en' || isLeftToRight()) ? ' Bank' :
                        'مصرف' }}
                                                        </div>
                                                        <div class="badge badge-soft-info"
                                                            v-if="voucher.paymentMode == 5">
                                                            {{ ($i18n.locale == 'en' || isLeftToRight()) ? ' Advance' :
                        'يتقدم' }}
                                                        </div>
                                                    </td>
                                                    <td v-if="voucher.paymentMethods == 'Default'">
                                                        ---
                                                    </td>
                                                    <td v-else>
                                                        {{ voucher.paymentMethods }}
                                                    </td>
                                                    <td>
                                                        {{ voucher.bankCashAccountName }}
                                                    </td>

                                                    <td>
                                                        {{ voucher.contactAccountName }}
                                                    </td>

                                                    <td v-if="allowBranches">
                                                        {{ voucher.branchCode }}
                                                    </td>
                                                    <td>
                                                        {{ currency }}
                                                        {{ parseFloat(voucher.amount).toFixed(3).slice(0,
                        -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") }}
                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                            <div class="tab-pane p-3" id="email" role="tabpanel">
                                <div class="row ">
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <label>{{ $t('EmailCompose.To') }}: <span class="text-danger"> *</span></label>
                                        <multiselect v-model="emailCompoese.EmailTo" tag-placeholder="Add Email"
                                            placeholder="Search or add a tag" label="cc" track-by="id"
                                            :options="ccOptions" :multiple="true" :taggable="true" @tag="AddCCToList">
                                        </multiselect>

                                    </div>


                                    <div class="form-group has-label col-sm-12 ">
                                        <label class="text  font-weight-bolder"> {{ $t('EmailCompose.Subject')
                                            }}:</label>
                                        <input class="form-control" v-model="emailCompoese.subject" type="text" />

                                    </div>
                                    <div class="form-group has-label col-sm-12 ">
                                        <label class="text  font-weight-bolder"> {{ $t('EmailCompose.Description') }}:
                                        </label>
                                        <textarea class="form-control" v-model="emailCompoese.description"
                                            type="text" />

                                    </div>



                                </div>
                                <div class="modal-footer justify-content-right">
                                    <button type="button" class="btn btn-primary  " v-on:click="SendEmail"> {{
                        $t('EmailCompose.Send') }}</button>

                                </div>
                            </div>

                        </div>
                        <div class="tab-content tab-space tab-subcategories">

                        </div>

                    </div>
                </div>
                <paymentVoucherModel v-if="showPV" :formName="formName == 'Customer' ? 'BankReceipt' : 'BankPay'"
                    :show="showPV" @close="showPV = false" :accId="newCustomer.accountId"
                    :runningBalanceProp="newCustomer.runningBalance"></paymentVoucherModel>
                <contractor-view :contractor="colorObj" v-bind:selectedCutomer="newCutomer" :show="show2" v-if="show2"
                    @close="show2 = false"></contractor-view>
                <attachment-view :documentpath="documentpath" :show="showView" v-if="showView" @close="CloseModel" />


                <AddAddress :address="newAddress" :show="show1" :isSale="false" :list="newCustomer.deliveryAddressList"
                    v-if="show1" @close="show1 = false" @IsSave="IsSave" :type="type" />

                <loading :active.sync="loading" :can-cancel="true" :is-full-page="true"></loading>
                <bulk-attachment :folderList="folderList" :attachmentList="newCustomer.attachmentList"
                    :isFolder="isFolder" :show="show" v-if="show" @close="attachmentSave"
                    @SaveFolder="SaveFolderRecord" />


            </div>
            <div v-if="!loading" class=" col-lg-12 invoice-btn-fixed-bottom">
                <div class="row">
                    <div v-if="!loading" class=" col-md-12">
                        <div class="button-items"
                            v-if="newCustomer.id == '00000000-0000-0000-0000-000000000000' && isValid('CanAddCustomer')">
                            <button class="btn btn-outline-primary" v-bind:disabled="$v.newCustomer.$invalid"
                                v-if="newCustomer.id == '00000000-0000-0000-0000-000000000000' && isValid('CanAddCustomer')"
                                v-on:click="SaveCustomer"><i class="far fa-save "></i> {{ $t('AddCustomer.btnSave')
                                }}</button>
                            <button class="btn btn-danger" v-on:click="Cancel">{{ $t('AddCustomer.Cancel') }}</button>
                        </div>
                        <div class="button-items" v-else-if="roleName == 'User' && formName == 'Profile'">
                            <button class="btn btn-outline-primary" v-bind:disabled="$v.newCustomer.$invalid"
                                v-if="newCustomer.id != '00000000-0000-0000-0000-000000000000' && isValid('CanEditCustomer')"
                                v-on:click="SaveCustomer"><i class="far fa-save "></i> {{ $t('AddCustomer.btnUpdate')
                                }}</button>
                        </div>
                        <div class="button-items" v-else>

                            <button class="btn btn-outline-primary" v-bind:disabled="$v.newCustomer.$invalid"
                                v-if="newCustomer.id != '00000000-0000-0000-0000-000000000000' && isValid('CanEditCustomer')"
                                v-on:click="SaveCustomer"><i class="far fa-save "></i> {{ $t('AddCustomer.btnUpdate')
                                }}</button>
                            <button class="btn btn-danger" v-on:click="Cancel">{{ $t('AddCustomer.Cancel') }}</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <InvoiceReportPrint :headerFooter="headerFooter" :isPrint="isShown1" :formName="formName"
            :printDetails="invoiceList" :fromDate="fromDate" :toDate="toDate" v-if="isShown1" v-bind:key="printRender1" />
        <invoice-report-print :printDetails="printDetails" :isSaleOrder="false" :isTouchScreen="sale"
            :headerFooter="headerFooter" @close="isPrint = false" :show="isPrint"
            v-if="printDetails.length != 0 && isPrint && !download" v-bind:key="printRender" />
        <purchaseinvoicereport :printDetails="printDetails" @close="showPurchasePrint = false" :show="show"
            v-if="printDetails.length != 0 && showPurchasePrint" v-bind:key="printPurchaseRender" />
    </div>
</template>

<script>
import clickMixin from '@/Mixins/clickMixin'

import moment from 'moment'
import Multiselect from 'vue-multiselect'
import {
    required,
    maxLength,
} from 'vuelidate/lib/validators';
import Loading from 'vue-loading-overlay';
import { VueEditor } from "vue2-editor";
import 'vue-loading-overlay/dist/vue-loading.css';

export default ({
    mixins: [clickMixin],
    components: {
        Multiselect,
        Loading,
        VueEditor
    },
    data: function () {
        return {
            search: '',
            fromDate: moment().startOf('year').format('DD MMM YYYY'),
            toDate: moment().format("DD MMM YYYY"),
            fromTime: '',
            toTime: '',
            terminalId: '',
            userId: '',
            active: 'Paid',
            customerType: '',
            printPurchaseRender: 0,
            showPurchasePrint: false,
            allowBranches: false,
            showPV: false,
            saleList: [],
            vouchersList: [],
            rendr: 0,
            folderList: [{
                name: 'All',
                isActive: true,
            }],
            attachmentListFolder: [],
            currentPage: 1,
            pageCount: '',
            rowCount: '',

            roleName: '',
            formName: '',
            priceLabelId: '',
            isCustomerPriceLabel: false,
            arabic: '',
            show: false,
            isFolder: false,
            show1: false,
            show2: false,
            multipleAddress: false,
            type: '',
            isCashCustomer: false,
            b2b: false,
            b2c: false,
            english: '',
            contactCode: '',
            cashCustomerCode: '',
            colorObj: '',
            download: false,
            isPrint: false,
            printDetails: [],
            invoiceList: [],
            isSkype: false,
            printRender: 0,
            printRender1: 0,
            isShown1: false,
            salutatioRender: 0,
            randerInput: 0,
            randerDate: 0,
            newAddress: {
                id: '',
                area: '',
                address: '',
                city: '',
                country: '',
                billingZipCode: '',
                latitude: '',
                langitutue: '',
                fromTime: '',
                toTime: '',
                billingPhone: '',
                deliveryHolidays: [],
                type: '',
                add: 'Add',
                isActive: false,
                isDefault: false,
                isOffice: false,
                allHour: false,
                allDaySelection: false,
            },

            newCustomer: {
                id: '00000000-0000-0000-0000-000000000000',
                code: '',
                multipleAddress: false,
                isAddressOnAll: false,
                priceLabelId: '',
                prefix: '',
                englishName: '',
                arabicName: '',
                companyNameEnglish: '',
                companyNameArabic: '',
                customerDisplayName: '',
                telephone: '',
                email: '',
                registrationDate: '',
                category: '',
                customerType: '',
                customerGroup: '',
                contactNo1: '',
                accountId: '',

                commercialRegistrationNo: '',
                vatNo: '',
                customerGroupId: '',
                currencyId: '',
                regionId: '',
                taxRateId: '',
                customerCode: '',
                website: '',

                billingAttention: '',
                billingCountry: '',
                billingZipCode: '',
                billingPhone: '',
                billingArea: '',
                billingAddress: '',
                billingCity: '',
                billingFax: '',

                shippingAttention: '',
                shippingCountry: '',
                shippingZipCode: '',
                shippingPhone: '',
                shippingArea: '',
                shippingAddress: '',
                shippingCity: '',
                shippingFax: '',

                contactPersonList: [{
                    prefix: '',
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    mobile: ''
                }],
                deliveryAddressList: [],
                productList: [],
                remarks: '',
                isCustomer: true,
                isVendor: false,
                isActive: true,
                isCashCustomer: true,

                paymentTerms: '',
                deliveryTerm: '',
                creditLimit: '',
                creditPeriod: '',

                attachmentList: []
            },
            loading: false,
            language: 'Nothing',
            showView: false,
            documentpath: '',
            emailCompoese: {
                EmailTo: [],
                subject: '',
                description: '',
                companyName: '',
                buttonName: '',
                emailPath: '',
            },
            ccOptions: [],
            isCaseUser: false
        }
    },
    validations: {

        newCustomer: {
            customerType: {
            },
            englishName: {
                required,

                maxLength: maxLength(250)
            },
            arabicName: {
                maxLength: maxLength(250)
            },
            vatNo: {

                maxLength: maxLength(250)
            },
            paymentTerms: {
            },
            customerDisplayName: {
            },
        }
    },

    methods: {
        GetInvoiceRecord: function () {
            let customerId=this.newCustomer.id;

            debugger;
            var root = this;
            var token = '';
            if (token == '') {
                token = localStorage.getItem('token');
            }
            

            var url;
            if (this.formName == 'Supplier') {
                url = '/Report/PurchaseInvoice?fromDate=';

            }
            else {
                url = '/Report/SaleInvoiceReport?fromDate=';


            }







            this.$https.get(url + this.fromDate + '&toDate=' + this.toDate + '&customerId=' + customerId, { headers: { "Authorization": `Bearer ${token}` } })
                .then(function (response) {
                    if (response.data != null) {
                        root.invoiceList = response.data;
                        root.printRender1++;
                        root.isShown1 = true;
                    }
                }).catch(error => {

                    console.log(error)
                    root.$swal.fire(
                        {
                            icon: 'error',
                            title: 'No Record Exist!',
                            text: error.response.data,
                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                        });

                    root.loading = false
                });
        },
        PrintPurchaseRdlc: function (id) {

            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }

            root.$https.get('/PurchasePost/PurchasePostDetail?Id=' + id, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
                .then(function (response) {

                    if (response.data != null) {
                        root.printDetails = response.data;
                        root.showPurchasePrint = true;
                        root.printPurchaseRender++;



                    }
                },
                    function (error) {
                        this.loading = false;
                        console.log(error);
                    });
        },
        PrintRdlc: function (value, prop) {
            debugger;

            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            root.$https.get("/Sale/SaleDetail?id=" + value + '&simpleQuery=true', {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            })
                .then(function (response) {
                    if (response.data != null) {
                        debugger;

                        root.printDetails = response.data;

                        if (prop == 'download') {
                            root.download = true;
                            root.isPrint = false;
                        } else {
                            root.download = false;
                            root.isPrint = true;
                            root.printRender++;
                        }
                    }
                });
        },
        onClose: function () {
            this.showPv = false;
            this.paymentVoucherClick();
        },
        SendEmail: function () {

            this.loading = true;
            var root = this

            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }

            this.emailCompoese.emailPath = this.$ClientIP + '/SaleEmail?id=' + this.documentId + '&companyId='
                + localStorage.getItem('CompanyID') + '&multiUnit=' + localStorage.getItem('IsMultiUnit') + '&decimal=' +
                localStorage.getItem('decimalQuantity') + '&fifo=' + localStorage.getItem('fIFO') +
                '&currency' + localStorage.getItem('currency') + '&invoiceWoInventory='
                + localStorage.getItem('InvoiceWoInventory') + '&lang=' + localStorage.getItem('locales')
                + '&b2b=' + localStorage.getItem('b2b') + '&b2c=' + localStorage.getItem('b2c')
                + '&taxInvoiceLabel=' + localStorage.getItem('taxInvoiceLabel') + '&taxInvoiceLabelAr=' + localStorage.getItem('taxInvoiceLabelAr')
                + '&simplifyTaxInvoiceLabel=' + localStorage.getItem('simplifyTaxInvoiceLabel') + '&simplifyTaxInvoiceLabelAr=' + localStorage.getItem('simplifyTaxInvoiceLabelAr')
                + '&invoicePrint=' + localStorage.getItem('InvoicePrint') + '&isHeaderFooter=' + localStorage.getItem('IsHeaderFooter')
                + '&IsDeliveryNote=' + localStorage.getItem('IsDeliveryNote') + '&userName=' + localStorage.getItem('FullName')
                + '&english=' + 'true' + '&arabic=' + localStorage.getItem('Arabic')

            this.emailCompoese.buttonName = 'Sale Invoice'

            root.$https.post('/Sale/SendEmail', this.emailCompoese, { headers: { "Authorization": `Bearer ${token}` } })
                .then(function (response) {
                    if (response.data != null) {
                        if (root.formName === 'SimpleEmail') {
                            root.$emit('input', root.emailCompoese)
                        }
                        else {
                            root.$emit('close');
                        }
                        root.$swal({
                            title: "Success",
                            text: "Email Send Successfully",
                            type: 'success',
                            confirmButtonClass: "btn btn-success",
                            buttonStyling: false,
                            icon: 'success',
                            timer: 1500,
                            timerProgressBar: true,

                        })

                    }
                    root.loading = false;
                }).catch(error => {
                    console.log(error)
                    root.$swal.fire(
                        {
                            icon: 'error',
                            title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Something Went Wrong!' : 'هل هناك خطب ما!',
                            text: error.response.data,
                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                        });

                    root.loading = false
                })
                .finally(() => root.loading = false)
        },
        FilterRecord: function (type) {

            if (type) {
                if (this.fromDate != '') {
                    if (this.toDate == '') {
                        this.$swal({
                            title: 'Error',
                            text: "Please Select To Date ",
                            type: 'error',
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true,
                        });

                        return;

                    }
                }
                if (this.toDate != '') {
                    if (this.fromDate == '') {
                        this.$swal({
                            title: 'Error',
                            text: "Please Select From Date ",
                            type: 'error',
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true,
                        });

                        return;

                    }
                }



            } else {
                this.isDisable = false;
                this.isDisableMonth = false;
                if (this.$refs.userDropdown != null) {
                    this.$refs.userDropdown.EmptyRecord();
                }
                this.user = '';
                this.userId = '';

                this.year = '';
                this.fromDate = '';
                this.toDate = '';
                this.month = '';
                this.monthObj = '';
                this.terminalId = '';
                this.customerType = '';
                this.search = "";
                this.randerforempty++;





            }

            this.getData(this.search, this.currentPage, this.active, this.fromDate, this.toDate, this.fromTime, this.toTime, this.terminalId, this.userId, this.customerId, this.customerType);

        },
        AddCCToList: function (newEmail) {
            var uid = this.createUUID()
            const email = {
                cc: newEmail,
                id: uid
            }
            this.emailCompoese.EmailTo.push(email)
            this.ccOptions.push(email)
        },
        ViewAttachment: function (path) {
            if (path != '' && path != undefined && path != null) {
                this.documentpath = path;
                this.showView = true;
            }
        },
        CloseModel: function () {
            this.showView = false;
        },
        getDate: function (date) {
            return moment(date).format('LLL');
        },
        getPurchase: function () {
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }


            this.$https.get('/PurchasePost/PurchasePostList?status=' + 'post' + '&supplierId=' + this.newCustomer.id, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
                .then(function (response) {

                    if (response.data != null) {
                        root.saleList = response.data.results;
                        root.rendr++;
                    }
                }).catch(error => {
                    root.$swal.fire({
                        icon: 'error',
                        title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                        text: error.response.data,
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    });

                });

        },

        getPage: function () {

            this.customerId = this.newCustomer.id;
            this.getData(this.search, this.currentPage, 'Paid', this.fromDate, this.toDate, '', '', '', '', this.customerId, '');
        },

        getData: function (search, currentPage, status, fromDate, toDate, fromTime, toTime, terminalId, userId, customerId, customerType) {

            debugger;
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }


            {

                this.$https.get('/Sale/SaleList?status=' + status + '&searchTerm=' + search + '&pageNumber=' + currentPage + '&fromDate=' + fromDate + '&toDate=' + toDate + '&fromTime=' + fromTime + '&toTime=' + toTime + '&terminalId=' + terminalId + '&userId=' + userId + '&isService=false' + '&CustomerId=' + customerId + '&customerType=' + customerType, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
                    .then(function (response) {

                        if (response.data != null) {
                            debugger;
                            root.currentPage = response.data.currentPage;
                            root.pageCount = response.data.pageCount;
                            root.rowCount = response.data.rowCount;
                            root.saleList = response.data.results.sales;
                            root.currentPage = currentPage;
                            root.rendr++;
                        }
                    }).catch(error => {
                        root.$swal.fire({
                            icon: 'error',
                            title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                            text: error.response.data,
                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                        });

                    });
            }

        },

        paymentVoucherClick: function () {


            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            let receiptName = this.formName == 'Customer' ? 'BankReceipt' : 'BankPay'
            root.$https.get('PaymentVoucher/PaymentVoucherList?paymentVoucherType=' + receiptName + '&status=' + 'Approved' + '&contactId=' + this.newCustomer.accountId + '&isDashboard=true', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                if (response.data != null) {
                    root.vouchersList = response.data.results.paymentVouchers;

                }
            });
        },
        GetCurrentFolder: function (folderName) {

            if (folderName === 'All') {
                this.attachmentListFolder = this.newCustomer.attachmentList;
            }
            else {
                this.attachmentListFolder = this.newCustomer.attachmentList.filter(attachment => {
                    return attachment.description === folderName;
                });
            }

        },
        getFileIconClass(fileName) {
            const extension = fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2); // Extract file extension
            if (extension === 'png') {
                return 'lar la-file-alt text-primary';
            } else if (extension === 'pdf') {
                return 'lar la-file-code text-danger';
            }
            else if (extension === 'zip') {
                return 'lar la-file-archive text-warning';
            }
            else {
                // Default icon class for other file types
                return 'lar la-file text-secondary';
            }
        },
        DownloadAttachment(path) {

            var root = this;
            var token = '';
            if (root.$session.exists()) {
                token = localStorage.getItem('token');
            }
            var ext = path.split('.')[1];
            root.$https.get('/Contact/DownloadFile?filePath=' + path, { headers: { "Authorization": `Bearer ${token}` }, responseType: 'blob' })
                .then(function (response) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'file.' + ext);
                    document.body.appendChild(link);
                    link.click();
                });
        },

        SaveFolderRecord: function (folder) {

            this.isFolder = false;
            this.show = false;

            this.folderList.push({
                name: folder,
                isActive: true,

            });

            this.folderList.forEach(item => {
                if (item.name === folder) {
                    item.isActive = true; // Set the clicked folder as active
                } else {
                    item.isActive = false; // Set other folders as inactive
                }
            });
            this.GetCurrentFolder(folder);

        },
        PrintView: function () {
            this.show2 = true;

        },

        GetRecord: function () {


            debugger;


            let placeholders = /(\$Name|\$RegistrationDate|\$PhoneNo|\$TelephoneNo|\$Currency|\$Email|\$VatNo|\$Address)/g;

            // Replace structured placeholders with values from newCustomer
            this.colorObj.description = this.colorObj.description.replace(placeholders, match => {
                switch (match) {

                    case '$Name':
                        return this.newCustomer.englishName !== null && this.newCustomer.englishName !== undefined ? this.newCustomer.englishName : ' ';
                    case '$RegistrationDate':
                        return this.newCustomer.registrationDate !== null && this.newCustomer.registrationDate !== undefined ? this.newCustomer.registrationDate : ' ';
                    case '$PhoneNo':
                        return this.newCustomer.contactNo1 !== null && this.newCustomer.contactNo1 !== undefined ? this.newCustomer.contactNo1 : ' ';
                    case '$TelephoneNo':
                        return this.newCustomer.telephoneNo !== null && this.newCustomer.telephoneNo !== undefined ? this.newCustomer.telephoneNo : ' ';
                    case '$Currency':
                        return this.newCustomer.prefix !== null && this.newCustomer.prefix !== undefined ? this.newCustomer.prefix : ' ';
                    case '$Email':
                        return this.newCustomer.email !== null && this.newCustomer.email !== undefined ? this.newCustomer.email : ' ';
                    case '$VatNo':
                        return this.newCustomer.vatNo !== null && this.newCustomer.vatNo !== undefined ? this.newCustomer.vatNo : ' ';
                    case '$Address':
                        return this.newCustomer.address !== null && this.newCustomer.address !== undefined ? this.newCustomer.address : ' ';
                    default:
                        return match;
                }
            });




        },



        GetPriceLabeling: function (val) {
            this.newCustomer.priceLabelId = val.id;
        },
        toDecimal: function (price) {
            return parseFloat(price).toFixed(3).slice(0, -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
        },

        isCashCustomerFunc: function () {

            if (this.newCustomer.isCashCustomer) {

                this.newCustomer.customerType = 'Individual';
                this.newCustomer.paymentTerms = 'Cash';

            } else {
                this.newCustomer.customerType = '';
                this.newCustomer.paymentTerms = 'Credit';
            }
        },
        OpenFolder: function () {
            debugger;

            this.isFolder = true;
            this.show = true;
        },
        Attachment: function () {
            debugger;
            this.isFolder = false;
            this.show = true;
        },

        attachmentSave: function (attachment) {
            debugger;
            this.newCustomer.attachmentList = attachment;
            this.attachmentListFolder = attachment;
            this.show = false;
            this.isFolder = false;
            this.GetCurrentFolder('All');
        },

        languageChange: function (lan) {
            if (this.language == lan) {
                if (this.newCustomer.id == '00000000-0000-0000-0000-000000000000') {

                    var getLocale = this.$i18n.locale;
                    this.language = getLocale;

                    this.$router.go('/addCustomer');
                } else {

                    this.$swal({
                        title: (this.$i18n.locale == 'en' || this.isLeftToRight()) ? 'Error!' : 'خطأ',
                        text: (this.$i18n.locale == 'en' || this.isLeftToRight()) ? 'You cannot Change language during update, otherwise your current page data will be lose!' : 'لا يمكنك تغيير اللغة أثناء التحديث ، وإلا ستفقد بيانات صفحتك الحالية!',
                        type: 'error',
                        confirmButtonClass: "btn btn-danger",
                        icon: 'error',
                        timer: 4000,
                        timerProgressBar: true,
                    });
                }
            }

        },

        GetAutoCodeGenerator: function () {
            var root = this;
            var token = '';
            if (root.$session.exists()) {
                token = localStorage.getItem('token');
            }

            root.$https
                .get('/Contact/AutoGenerateCode?customerType=' + this.formName, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                }).then(function (response) {
                    if (response.data != null) {

                        root.contactCode = response.data;
                        root.randerInput++;

                    }
                });
        },



        SaveCustomer: function () {

            this.newCustomer.code = this.contactCode;
            this.newCustomer.customerDisplayName = this.newCustomer.englishName;

            if (this.newCustomer.id == '00000000-0000-0000-0000-000000000000') {
                localStorage.setItem('ContactPage', 1);
                localStorage.setItem('CurrentActiveContact', this.newCustomer.isCustomer ? true : false);
            }
            this.loading = true;
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }




            root.$https
                .post('/Contact/SaveContact', this.newCustomer, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then(response => {
                    if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.action == "Add") {
                        root.loading = false
                        root.info = response.data.bpi

                        root.$swal({
                            title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved Successfully' : 'حفظ بنجاح',
                            text: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved' : 'تم الحفظ',
                            type: 'success',
                            confirmButtonClass: "btn btn-success",
                            buttonStyling: false,
                            icon: 'success',
                            timer: 1500,
                            timerProgressBar: true,

                        }).then(function (ok) {
                            if (ok != null) {
                                if (root.isValid('CanViewCustomer')) {
                                    root.$router.push({
                                        path: '/Customer',
                                        query: { formName: root.formName }
                                    })
                                } else {
                                    root.$router.go();
                                }
                            }
                        });
                    } else if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.action == "Update") {
                        root.loading = false
                        root.info = response.data.bpi

                        root.$swal({
                            title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved Successfully' : 'حفظ بنجاح',
                            text: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved' : 'تم الحفظ',
                            type: 'success',
                            confirmButtonClass: "btn btn-success",
                            buttonStyling: false,
                            icon: 'success',
                            timer: 1500,
                            timerProgressBar: true,

                        }).then(function (ok) {
                            if (ok != null) {
                                if (root.isValid('CanViewCustomer')) {
                                    root.$router.push({
                                        path: '/Customer',
                                        query: { formName: root.formName }
                                    })
                                } else {
                                    root.$router.go();
                                }
                            }
                        });
                    } else {
                        root.$swal({
                            title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                            text: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'There is something wrong. Please contact to support.' : 'هناك شيء ما خاطئ. يرجى الاتصال للدعم.',
                            type: 'error',
                            confirmButtonClass: "btn btn-danger",
                            icon: 'error',
                            timer: 1500,
                            timerProgressBar: true,
                        });
                    }

                })
                .catch(error => {
                    console.log(error)
                    root.$swal.fire({
                        icon: 'error',
                        title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                        text: error.response.data,
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    });

                    root.loading = false
                })
                .finally(() => root.loading = false)
        },

        AddRow: function () {

            this.newCustomer.contactPersonList.push({
                prefix: '',
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                mobile: ''
            });

        },

        createUUID: function () {

            var dt = new Date().getTime();
            var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = (dt + Math.random() * 16) % 16 | 0;
                dt = Math.floor(dt / 16);
                return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
            return uuid;
        },
        RemoveRow: function (index) {
            this.newCustomer.contactPersonList.splice(index, 1);
        },



        RemoveRow1: function (index) {
            this.newCustomer.deliveryAddressList.splice(index, 1);
        },

        Cancel: function () {
            this.$router.push({
                path: '/Customer',
                query: { formName: this.formName }
            })

        },
        EditCustomer: function (email) {
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }

            this.$https.get('/Contact/ContactDetail?caseCustomer=true' + '&email=' + email, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                if (response.data != null) {
                    root.newCustomer = response.data;
                    root.cashCustomerCode = root.newCustomer.code;
                    root.formName = 'Profile';
                    root.newCustomer.customerType = 'Profile'
                    root.contactCode = root.newCustomer.code;
                    root.newCustomer.attachmentList.forEach(x => {
                        const existingFolder = root.folderList.find(folder => folder.name === x.description);

                        // If not found, push to folderList
                        if (!existingFolder) {
                            root.folderList.push({
                                name: x.description,
                                isActive: false
                            });
                        }

                    });
                    root.GetCurrentFolder('All');
                    root.randerInput++;
                    root.randerDate++;
                }

            });
        },

    },
    created: function () {
        this.showPV = false;
        this.roleName = localStorage.getItem('RoleName');
        let email = localStorage.getItem('UserName');
        if (this.$route.query.formName == 'CaseCustomer' && this.roleName == 'User') {
            this.EditCustomer(email);



        }
        else {
            this.formName = this.$route.query.formName;
            this.newCustomer.customerType = this.formName
            this.$emit('input', this.$route.name)
            if (this.$route.query.data != undefined) {
                this.newCustomer = this.$route.query.data;
                this.cashCustomerCode = this.newCustomer.code;
                this.contactCode = this.newCustomer.code;
                this.newCustomer.attachmentList.forEach(x => {
                    const existingFolder = this.folderList.find(folder => folder.name === x.description);

                    // If not found, push to folderList
                    if (!existingFolder) {
                        this.folderList.push({
                            name: x.description,
                            isActive: false
                        });
                    }

                });
                this.GetCurrentFolder('All');
                this.randerInput++;

            } else {
                this.GetAutoCodeGenerator();
                this.newCustomer.registrationDate = moment().format('llll');
                if (localStorage.getItem('IsCustomeCredit') != 'true') {
                    this.newCustomer.paymentTerms = (this.$i18n.locale == 'en' || this.isLeftToRight()) ? 'Cash' : 'نقد'
                } else {
                    this.newCustomer.paymentTerms = (this.$i18n.locale == 'en' || this.isLeftToRight()) ? 'Credit' : 'آجل'
                }


            }

        }


    },
    mounted: function () {
        this.showPV = false;

        this.newCustomer.isCustomer = this.formName == 'Customer' ? true : false;
        this.newCustomer.isCashCustomer = this.newCustomer.isCustomer ? this.newCustomer.isCashCustomer : false
        this.isCaseUser = localStorage.getItem('IsCaseUser') == 'true' ? true : false;
        this.language = this.$i18n.locale;
        this.english = 'true';
        this.arabic = localStorage.getItem('Arabic');

        this.multipleAddress = localStorage.getItem('MultipleAddress') == 'true' ? true : false;

        this.isCustomerPriceLabel = localStorage.getItem('IsCustomerPriceLabel') == 'true' ? true : false;

    }
})
</script>
