<template>
    <body>
        <!--Left Sidenav-->
        <div class="left-sidenav">
            <!-- LOGO -->
            <div class="brand">
                <a  class="logo">
                    <span>
                        <img src="logo-sm.png" alt="logo-small" class="logo-sm">
                    </span>
                    <span>
                        <img src="logo.png" alt="logo-large" class="logo-lg logo-dark">
                    </span>
                </a>
            </div>
            <!--end logo-->

            <div class="menu-content h-100" data-simplebar>
                <ul class="metismenu left-sidenav-menu" v-if="isCaseUser">
                    <li v-if="isCaseUser && role=='User'">
                        <a href="javascript:void(0);" v-on:click="GoTo('/CaseUser', 'Sale_token', 'true', 'CaseCustomer')">

                            <i data-feather="trending-up" class="align-self-center menu-icon"></i><span>Profile</span>
                        </a>

                    </li>
                    <li v-else-if="isCaseUser">
                        <a href="javascript:void(0);" v-on:click="GoTo('/customer', 'Sale_token', 'true', 'Customer')">

                            <i data-feather="trending-up" class="align-self-center menu-icon"></i><span>Profiles</span>
                        </a>

                    </li>
                    <li>
                        <a href="javascript:void(0);" v-on:click="GoTo('/DesignForAuthority', 'Sale_token', 'true', 'Customer')">

                            <i data-feather="trending-up" class="align-self-center menu-icon"></i><span>Design For Authority</span>
                        </a>

                    </li>
                    <li>
                        <a href="javascript:void(0);" v-on:click="AddCurrency">

                            <i data-feather="trending-up" class="align-self-center menu-icon"></i><span>Create Authority</span>
                        </a>

                    </li>
                </ul>
                <ul class="metismenu left-sidenav-menu" v-else-if="isCaseSystem">
                   
                    <li >
                        <a href="javascript:void(0);" v-on:click="GoTo('/customer', 'Sale_token', 'true', 'Customer')">

                            <i data-feather="trending-up" class="align-self-center menu-icon"></i><span>Profiles</span>
                        </a>

                    </li>
                    <li v-if="havePermission('CaseInvoice')">
                        <a href="javascript:void(0);">
                            <i data-feather="package" class="align-self-center menu-icon"></i><span>
                                Case Invoice
                            </span><span class="menu-arrow"><i class="mdi mdi-chevron-right"></i></span>
                        </a>
                        

                        <ul class="nav-second-level" aria-expanded="false">


                            <li>
                                <a v-on:click="GoTo('/ServiceProformaInvoice', 'Sales_token', 'true', 'ServiceProformaInvoice', true)"
                                    href="javascript:void(0);">
                                    <i data-feather="plus-square" class="align-self-center menu-icon"></i>

                                    Case Invoice
                                </a>
                            </li>
                            <li v-if="havePermission('Service')">
                                <a v-on:click="GoTo('/products', 'Product And Inventory Management_token', 'true', 'Case', true)"
                                    href="javascript:void(0);">
                                    <i data-feather="plus-square" class="align-self-center menu-icon"></i>

                                    Case Product
                                </a>
                            </li>
                          

                        </ul>
                    </li>
                    <li v-if="isValid('CanViewUserRole') || isValid('CanViewUserPermission') || isValid('CanViewSignUpUser') || isValid('CanViewTerminal')
                        || isValid('CanViewPosTerminal') || isValid('CanUpdateCompanyInfo') || isValid('CanPushRecord') || isValid('CanPullRecord') || isValid('CanFlushDatabase')
                        || isValid('CanResetDatabase')">

                        <a href="javascript:void(0);">
                            <i data-feather="settings" class="align-self-center menu-icon"></i><span>
                                {{ $t('Dashboard.SystemManagement') }}
                            </span><span class="menu-arrow"><i class="mdi mdi-chevron-right"></i></span>
                        </a>

                        <ul class="nav-second-level" aria-expanded="false">

                            <li v-if="isValid('CanUpdateCompanyInfo')">
                                <a v-on:click="GoTo('/CompanyProfile', 'Setups And Configuration_token', 'true')"
                                    href="javascript:void(0);"> {{ $t('Dashboard.CompanyInfo') }}</a>
                            </li>
                            <li v-if="isValid('CanUpdateCompanyInfo')">
                                <a v-on:click="GoTo('/Permission')" href="javascript:void(0);"> Company Permission</a>
                            </li>


                            <li>
                                <a v-on:click="GoTo('/PrintSetting', 'Sales_token', 'true')"
                                    href="javascript:void(0);">Print Setting</a>
                            </li>
                            <li>
                                <a v-on:click="GoTo('/warehouse', 'Sales_token', 'true')" href="javascript:void(0);">Ware
                                    House</a>
                            </li>



                            <li
                                v-if="isValid('CanViewUserRole') || isValid('CanViewUserPermission') || isValid('CanViewSignUpUser')">
                                <a href="javascript:void(0);">
                                    <i class="ti-control-record"></i>{{ $t('Dashboard.UserSetup') }}<span
                                        class="menu-arrow left-has-menu">
                                        <i class="mdi mdi-chevron-right"></i>
                                    </span>
                                </a>

                                <ul class="nav-second-level" aria-expanded="false">
                                    <li v-if="isValid('CanViewSignUpUser')">
                                        <a v-on:click="GoTo('/signUp', 'Settings And Permission_token', 'true')"
                                            href="javascript:void(0);"> {{ $t('Dashboard.SignUpUser') }}</a>
                                    </li>
                                    <li v-if="isValid('CanViewUserRole')">
                                        <a v-on:click="GoTo('/Roles', 'Settings And Permission_token', 'true')"
                                            href="javascript:void(0);"> {{ $t('Dashboard.UserRoles') }}</a>
                                    </li>
                                    <!-- <li v-if="isValid('CanViewPermission')">
                                            <a v-on:click="GoTo('/CompanyOption', 'Settings And Permission_token', 'true')" href="javascript:void(0);">  {{ $t('Dashboard.CompanyOption') }}</a>
                                        </li> -->

                                </ul>
                            </li>
                        </ul>
                    </li>
                    
                  
                </ul>
             
                <ul class="metismenu left-sidenav-menu" v-else-if="role == 'Noble Admin'">
                    <li>
                        <a href="javascript:void(0);" v-on:click="GoTo('/company')">
                            <i data-feather="trending-up" class="align-self-center menu-icon"></i><span>Company</span>
                        </a>

                    </li>
                </ul>

                <ul class="metismenu left-sidenav-menu" v-else>

                    <li>
                        <a href="javascript:void(0);" v-on:click="GoTo('/StartScreen')">
                            <i data-feather="trending-up" class="align-self-center menu-icon"></i><span>Dashboard</span>
                        </a>

                    </li>



                    <li v-if="havePermission('Sale')">
                        <a href="javascript:void(0);">
                            <i data-feather="package" class="align-self-center menu-icon"></i><span>
                                Sale
                            </span><span class="menu-arrow"><i class="mdi mdi-chevron-right"></i></span>
                        </a>

                        <ul class="nav-second-level" aria-expanded="false">


                            <li>
                                <a v-on:click="GoTo('/SaleService', 'Sales_token', 'true', 'SimpleInvoice', false)"
                                    href="javascript:void(0);">
                                    <i data-feather="plus-square" class="align-self-center menu-icon"></i>
                                    Sale
                                </a>
                            </li>
                            <li>
                                <a v-on:click="GoTo('/addSaleService', 'Sales_token', 'true', 'SimpleInvoice', false)"
                                    href="javascript:void(0);">
                                    <i data-feather="plus-square" class="align-self-center menu-icon"></i>

                                    Add Sale
                                </a>
                            </li>
                            <li>
                                <a v-on:click="GoTo('/SaleReturn', 'Sales_token', 'true', 'SimpleInvoice', false)"
                                    href="javascript:void(0);">
                                    <i data-feather="plus-square" class="align-self-center menu-icon"></i>

                                    Sale Return
                                </a>
                            </li>

                        </ul>
                    </li>

                    <li v-if="havePermission('Service')">
                        <a href="javascript:void(0);">
                            <i data-feather="package" class="align-self-center menu-icon"></i><span>
                                Sale Service
                            </span><span class="menu-arrow"><i class="mdi mdi-chevron-right"></i></span>
                        </a>

                        <ul class="nav-second-level" aria-expanded="false">

                            <li>
                                <a v-on:click="GoTo('/SaleService', 'Sales_token', 'true', 'Service', true)"
                                    href="javascript:void(0);">
                                    <i data-feather="plus-square" class="align-self-center menu-icon"></i>
                                    Service Sale
                                </a>
                            </li>
                            <li>
                                <a v-on:click="GoTo('/addSaleService', 'Sales_token', 'true', 'Service', false)"
                                    href="javascript:void(0);">
                                    <i data-feather="plus-square" class="align-self-center menu-icon"></i>

                                    Add Service Sale
                                </a>
                            </li>
                            <li >

                                <a v-on:click="GoTo('/products', 'Product And Inventory Management_token', 'true', 'Service', true)"
                                    href="javascript:void(0);">
                                    <i data-feather="plus-square" class="align-self-center menu-icon"></i>

                                    Service Product
                                </a>
                                
                            </li>
                            <li>
                        <a href="javascript:void(0);"
                            v-on:click="GoTo('/addPaymentVoucher', 'Sale_token', 'true', 'BankReceipt',true)">

                            <i data-feather="trending-up" class="align-self-center menu-icon"></i><span>Service  Payment</span>
                        </a>

                    </li>


                        </ul>
                    </li>
                    <li v-if="havePermission('CaseInvoice')">
                        <a href="javascript:void(0);">
                            <i data-feather="package" class="align-self-center menu-icon"></i><span>
                                Case Invoice
                            </span><span class="menu-arrow"><i class="mdi mdi-chevron-right"></i></span>
                        </a>

                        <ul class="nav-second-level" aria-expanded="false">


                            <li>
                                <a v-on:click="GoTo('/ServiceProformaInvoice', 'Sales_token', 'true', 'ServiceProformaInvoice', true)"
                                    href="javascript:void(0);">
                                    <i data-feather="plus-square" class="align-self-center menu-icon"></i>

                                    Case Invoice
                                </a>
                            </li>
                            <li >
                                <a v-on:click="GoTo('/products', 'Product And Inventory Management_token', 'true', 'Case', true)"
                                    href="javascript:void(0);">
                                    <i data-feather="plus-square" class="align-self-center menu-icon"></i>

                                    Case Product
                                </a>
                            </li>

                        </ul>
                    </li>
                    <li>



                        <a href="javascript:void(0);">
                            <i data-feather="package" class="align-self-center menu-icon"></i><span>
                                Products
                            </span><span class="menu-arrow"><i class="mdi mdi-chevron-right"></i></span>
                        </a>

                        <ul class="nav-second-level" aria-expanded="false">

                            <li v-if="havePermission('Product')">
                                <a v-on:click="GoTo('/products', 'Product And Inventory Management_token', 'true', 'Product', false)"
                                    href="javascript:void(0);"> Product</a>
                            </li>
                            <li v-if="havePermission('Product')">
                                <a v-on:click="GoTo('/Size', 'Product And Inventory Management_token', 'true', 'Variant')"
                                    href="javascript:void(0);"> Variant</a>
                            </li>

                           
                           
                          
                            <li v-if="havePermission('Product')">
                                <a v-on:click="GoTo('/category', 'Product And Inventory Management_token', 'true')"
                                    href="javascript:void(0);"> Category</a>
                            </li>
                            <li>
                                <a v-on:click="GoTo('/Warranty', 'Product And Inventory Management_token', 'true', 'Product', false)"
                                    href="javascript:void(0);"> All Warranty</a>
                            </li>
                            <!-- <li v-if="havePermission('Product')">
                                <a v-on:click="GoTo('/subcategory', 'Product And Inventory Management_token', 'true')"
                                    href="javascript:void(0);">Sub Category</a>
                            </li> -->
                            <!-- <li v-if="havePermission('Product')">
                                <a v-on:click="GoTo('/unit', 'Product And Inventory Management_token', 'true')"
                                    href="javascript:void(0);"> Unit</a>
                            </li> -->
                            <li v-if="havePermission('Product')">
                                <a href="javascript:void(0);" v-on:click="GetSizeValue">
                                    <i class="ti-control-record"></i>Variant<span class="menu-arrow left-has-menu">
                                        <i class="mdi mdi-chevron-right"></i>
                                    </span>
                                </a>
                                <ul class="nav-second-level" aria-expanded="false">


                                    <template>
                                        <li v-for="item in sizelist" :key="item.id">
                                            <a v-on:click="GoTo('/Size', 'Product And Inventory Management_token', 'true', item.name, item.valueType)"
                                                href="javascript:void(0);">
                                                {{ item.name }}
                                            </a>
                                        </li>
                                    </template>

                                </ul>
                            </li>








                        </ul>
                    </li>
                    <li>
                        <a href="javascript:void(0);" v-on:click="GoTo('/customer', 'Sale_token', 'true', 'Customer')">

                            <i data-feather="trending-up" class="align-self-center menu-icon"></i><span>Customer</span>
                        </a>

                    </li>
                    <li>
                        <a href="javascript:void(0);"
                            v-on:click="GoTo('/paymentVoucherList', 'Sale_token', 'true', 'BankReceipt')">

                            <i data-feather="trending-up" class="align-self-center menu-icon"></i><span>Customer
                                Payment</span>
                        </a>

                    </li>
                    <li>
                        <a href="javascript:void(0);"
                            v-on:click="GoTo('/paymentVoucherList', 'Sale_token', 'true', 'AdvanceReceipt')">

                            <i data-feather="trending-up" class="align-self-center menu-icon"></i><span>
                            Advance Payment</span>
                        </a>

                    </li>
                    <li>
                        <a href="javascript:void(0);" v-on:click="GoTo('/origin', 'Purchase_token', 'true')">

                            <i data-feather="trending-up" class="align-self-center menu-icon"></i><span>With Draw Balance</span>
                        </a>

                    </li>
                    <li>
                        <a href="javascript:void(0);" v-on:click="GoTo('/PaymentGateWay', 'Purchase_token', 'true')">

                            <i data-feather="trending-up" class="align-self-center menu-icon"></i><span>Payment Gate Way</span>
                        </a>

                    </li>
                    <li>
                        <a href="javascript:void(0);" v-on:click="GoTo('/PurchaseOrder', 'Purchase_token', 'true')">

                            <i data-feather="trending-up" class="align-self-center menu-icon"></i><span>Purchase Order</span>
                        </a>

                    </li>
                    <li>
                        <a href="javascript:void(0);" v-on:click="GoTo('/purchase', 'Purchase_token', 'true')">

                            <i data-feather="trending-up" class="align-self-center menu-icon"></i><span>Purchase</span>
                        </a>

                    </li>
                    <li>
                        <a href="javascript:void(0);" v-on:click="GoTo('/PurchaseReturn', 'Purchase_token', 'true')">

                            <i data-feather="trending-up" class="align-self-center menu-icon"></i><span>Purchase Return</span>
                        </a>

                    </li>
                    <li>
                        <a href="javascript:void(0);" v-on:click="GoTo('/customer', 'Purchase_token', 'true', 'Supplier')">

                            <i data-feather="trending-up" class="align-self-center menu-icon"></i><span>Supplier</span>
                        </a>

                    </li>
                    <!-- <li>
                        <a href="javascript:void(0);" v-on:click="GoTo('/supplier', 'Purchase_token', 'true')">

                            <i data-feather="trending-up" class="align-self-center menu-icon"></i><span>Supplier</span>
                        </a>

                    </li> -->
                    <li>
                        <a href="javascript:void(0);"
                            v-on:click="GoTo('/paymentVoucherList', 'Sale_token', 'true', 'BankPay')">

                            <i data-feather="trending-up" class="align-self-center menu-icon"></i><span>Supplier
                                Payment</span>
                        </a>

                    </li>

                    <li>

                        <a v-on:click="GoTo('/SaleServiceOrder', 'Sales_token', 'true', 'Quotation', false)"
                            href="javascript:void(0);">
                            <i data-feather="trending-up" class="align-self-center menu-icon"></i><span>Quotation</span>

                        </a>

                    </li>


                    <li>

                        <a href="javascript:void(0);">
                            <i data-feather="plus-square" class="align-self-center menu-icon"></i><span>
                                Expenditure
                            </span><span class="menu-arrow"><i class="mdi mdi-chevron-right"></i></span>
                        </a>

                        <ul class="nav-second-level" aria-expanded="false">


                            <li>
                                <a v-on:click="GoTo('/City', 'Product And Inventory Management_token')"
                                    href="javascript:void(0);"> Expense Category</a>
                            </li>

                            <li>
                                <a v-on:click="GoTo('/SubCategory', 'Product And Inventory Management_token')"
                                    href="javascript:void(0);"> Expense SubCategory</a>
                            </li>
                            <li>
                                <a v-on:click="GoTo('/DailyExpense', 'Product And Inventory Management_token')"
                                    href="javascript:void(0);"> Expenditure</a>
                            </li>

                            <!-- <li>
                                <a v-on:click="GoTo('/InvoiceReport', 'Product And Inventory Management_token')"
                                    href="javascript:void(0);"> Invoice Report</a>
                            </li> -->




                        </ul>
                    </li>
                    <!-- Report Management Menu Bar Code  -->

                    <li>

                        <a href="javascript:void(0);">
                            <i data-feather="monitor" class="align-self-center menu-icon"></i><span>
                                {{ $t('Dashboard.ReportManagement') }}
                            </span><span class="menu-arrow"><i class="mdi mdi-chevron-right"></i></span>
                        </a>

                        <ul class="nav-second-level" aria-expanded="false">

                            <!-- <li v-if="isValid('CanViewStockReport') || isValid('CanViewProductInventoryReport')">
                            <a href="javascript:void(0);">
                                <i class="ti-control-record"></i>{{ $t('Dashboard.InventoryReports') }}<span class="menu-arrow left-has-menu">
                                    <i class="mdi mdi-chevron-right"></i>
                                </span>
                            </a>

                            <ul class="nav-second-level" aria-expanded="false">
                                <li v-if="isValid('CanViewStockReport')">
                                    <a v-on:click="GoTo('/StockReport', 'Reporting_token', 'true')" href="javascript:void(0);"> {{ $t('Dashboard.StockReport') }}</a>
                                </li>
                                <li v-if="isValid('CanViewProductInventoryReport')">
                                    <a v-on:click="GoTo('/ProductInventoryReport', 'Reporting_token', 'true')" href="javascript:void(0);"> {{ $t('Dashboard.ProductInventoryReport') }}</a>
                                </li>
                                <li>
                                    <a v-on:click="GoTo('/SupplierPurchaseReport', 'Reporting_token', 'true')" href="javascript:void(0);"> {{ $t('Dashboard.SupplierPurchaseReport') }}</a>
                                </li>
                                <li>
                                    <a v-on:click="GoTo('/ProductComparisonReport', 'Reporting_token', 'true')" href="javascript:void(0);"> {{ $t('Dashboard.ProductWiseAccountReports') }} </a>
                                </li>
                                <li>
                                    <a v-on:click="GoTo('/ProductLedgerReport', 'Reporting_token', 'true')" href="javascript:void(0);"> {{ $t('Dashboard.ProductLedgerReport') }}</a>
                                </li>
                                <li>
                                    <a v-on:click="GoTo('/ComparisionLedgerReport', 'Reporting_token', 'true')" href="javascript:void(0);"> {{ $t('Dashboard.ProductComparisionReport') }}</a>
                                </li>
                                <li>
                                    <a v-on:click="GoTo('/AdvanceIInventoryItem', 'Reporting_token', 'true')" href="javascript:void(0);"> {{ $t('Advance Inventory Item Report') }}</a>
                                </li>
                                <li>
                                    <a v-on:click="GoTo('/AdvanceQuantityWiseInventoryItem', 'Reporting_token', 'true')" href="javascript:void(0);"> {{ $t('Advance Quantity Wise Inventory Item') }}</a>
                                </li>
                                <li>
                                    <a v-on:click="GoTo('/AdvanceStockReport', 'Reporting_token', 'true')" href="javascript:void(0);"> {{ $t('Advance Stock Report') }}</a>
                                </li>

                            </ul>
                        </li> -->

                        <li>
                                        <a v-on:click="GoTo('/OneDayReport', 'Reporting_token', 'true','false')"
                                            href="javascript:void(0);">Day Wise Report</a>
                                    </li>
                                    <li v-if="isValid('CanViewSaleInvoiceReport')">
                                        <a v-on:click="GoTo('/InvoiceReport', 'Reporting_token', 'true', 'Sale')"
                                            href="javascript:void(0);"> {{ $t('Dashboard.SaleInvoiceReport') }}</a>
                                    </li>

                                    <li v-if="isValid('CanViewPurchaseInvoiceReport')">
                                        <a v-on:click="GoTo('/InvoiceReport', 'Reporting_token', 'true', 'Purchase')"
                                            href="javascript:void(0);"> {{ $t('Dashboard.PurchaseInvoiceReport') }}</a>
                                    </li>



                                    <li>
                                        <a v-on:click="GoTo('/DailyExpenseReport', 'Reporting_token', 'true')"
                                            href="javascript:void(0);"> {{ $t('Dashboard.DailyExpenseReport') }}</a>
                                    </li>
                                    <li>
                                        <a v-on:click="GoTo('/CustomerLedgerReport', 'Reporting_token', 'true','true')"
                                            href="javascript:void(0);">Customer Due Report</a>
                                    </li>
                                    <li>
                                        <a v-on:click="GoTo('/CustomerLedgerReport', 'Reporting_token', 'true','false')"
                                            href="javascript:void(0);">Supplier Due Report</a>
                                    </li>
                                    <li>
                                        <a v-on:click="GoTo('/ProductInventoryReport', 'Reporting_token', 'true','false')"
                                            href="javascript:void(0);">Product Inventory Report</a>
                                    </li>
                                    <li>
                                        <a v-on:click="GoTo('/StockReport', 'Reporting_token', 'true','false')"
                                            href="javascript:void(0);">Stock Report</a>
                                    </li>


                        </ul>
                    </li>





                    <!-- Account Management Menu Bar Code  -->

                    <li>

                        <a href="javascript:void(0);">
                            <i data-feather="credit-card" class="align-self-center menu-icon"></i><span>
                                {{ $t('Dashboard.Accounts') }}
                            </span><span class="menu-arrow"><i class="mdi mdi-chevron-right"></i></span>
                        </a>

                        <ul class="nav-second-level" aria-expanded="false">


                            <li v-if="isValid('CanViewJV') || isValid('CanDraftJV')">
                                <a v-on:click="GoTo('/journalvoucherview', 'Accounting_token', 'true', 'JournalVoucher')"
                                    href="javascript:void(0);">Inter Transfer</a>
                            </li>
                            <li v-else-if="isValid('CanAddJV')">
                                <a v-on:click="GoTo('/addjournalvoucher', 'Accounting_token', 'true', 'JournalVoucher')"
                                    href="javascript:void(0);"> Inter Transfer</a>
                            </li>
                            <li v-if="isValid('CanViewOC') || isValid('CanDraftOC')">
                                <a v-on:click="GoTo('/journalvoucherview', 'Accounting_token', 'true', 'OpeningCash')"
                                    href="javascript:void(0);"> {{ $t('Dashboard.OpeningCash') }}</a>
                            </li>
                            <li v-else-if="isValid('CanAddOC')">
                                <a v-on:click="GoTo('/addjournalvoucher', 'Accounting_token', 'true', 'OpeningCash')"
                                    href="javascript:void(0);"> {{ $t('Dashboard.OpeningCash') }}</a>
                            </li>

                            <li v-if="isValid('CanViewCOA')">
                                <a v-on:click="GoTo('/coa', 'Accounting_token', 'true')" href="javascript:void(0);"> {{
                                    $t('Dashboard.ChartofAccount-COA') }}</a>
                            </li>


                            <li>
                                <a href="javascript:void(0);">
                                    <i class="ti-control-record"></i>{{ $t('Dashboard.Setup') }}<span
                                        class="menu-arrow left-has-menu">
                                        <i class="mdi mdi-chevron-right"></i>
                                    </span>
                                </a>
                                <ul class="nav-second-level" aria-expanded="false">


                                    <li v-if="isValid('CanViewCurrency')">
                                        <a v-on:click="GoTo('/currency', 'Accounting_token', 'true')"
                                            href="javascript:void(0);">{{ $t('Dashboard.SetupCurrency') }}</a>
                                    </li>
                                    <li v-if="isValid('CanViewBank')">
                                        <a v-on:click="GoTo('/Bank', 'Accounting_token', 'true')"
                                            href="javascript:void(0);">{{ $t('Dashboard.RegisterBank') }}</a>
                                    </li>

                                    <li v-if="isValid('CanViewVatRate')">
                                        <a v-on:click="GoTo('/taxrate', 'Accounting_token', 'true')"
                                            href="javascript:void(0);">{{ $t('Dashboard.VATSetup') }}</a>
                                    </li>
                                    <li v-if="isValid('CanViewFinancialYear')">
                                        <a v-on:click="GoTo('/FinancialYear', 'Accounting_token', 'true')"
                                            href="javascript:void(0);">{{ $t('Dashboard.FinancialYear') }}</a>
                                    </li>
                                </ul>
                            </li>


                        </ul>
                    </li>





                    <!-- System Management Menu Bar Code  -->

                    <li v-if="isValid('CanViewUserRole') || isValid('CanViewUserPermission') || isValid('CanViewSignUpUser') || isValid('CanViewTerminal')
                        || isValid('CanViewPosTerminal') || isValid('CanUpdateCompanyInfo') || isValid('CanPushRecord') || isValid('CanPullRecord') || isValid('CanFlushDatabase')
                        || isValid('CanResetDatabase')">

                        <a href="javascript:void(0);">
                            <i data-feather="settings" class="align-self-center menu-icon"></i><span>
                                {{ $t('Dashboard.SystemManagement') }}
                            </span><span class="menu-arrow"><i class="mdi mdi-chevron-right"></i></span>
                        </a>

                        <ul class="nav-second-level" aria-expanded="false">

                            <li v-if="isValid('CanUpdateCompanyInfo')">
                                <a v-on:click="GoTo('/CompanyProfile', 'Setups And Configuration_token', 'true')"
                                    href="javascript:void(0);"> {{ $t('Dashboard.CompanyInfo') }}</a>
                            </li>
                            <li v-if="isValid('CanUpdateCompanyInfo')">
                                <a v-on:click="GoTo('/Permission')" href="javascript:void(0);"> Company Permission</a>
                            </li>


                            <li>
                                <a v-on:click="GoTo('/PrintSetting', 'Sales_token', 'true')"
                                    href="javascript:void(0);">Print Setting</a>
                            </li>
                            <li>
                                <a v-on:click="GoTo('/warehouse', 'Sales_token', 'true')" href="javascript:void(0);">Ware
                                    House</a>
                            </li>



                            <li
                                v-if="isValid('CanViewUserRole') || isValid('CanViewUserPermission') || isValid('CanViewSignUpUser')">
                                <a href="javascript:void(0);">
                                    <i class="ti-control-record"></i>{{ $t('Dashboard.UserSetup') }}<span
                                        class="menu-arrow left-has-menu">
                                        <i class="mdi mdi-chevron-right"></i>
                                    </span>
                                </a>

                                <ul class="nav-second-level" aria-expanded="false">
                                    <li v-if="isValid('CanViewSignUpUser')">
                                        <a v-on:click="GoTo('/signUp', 'Settings And Permission_token', 'true')"
                                            href="javascript:void(0);"> {{ $t('Dashboard.SignUpUser') }}</a>
                                    </li>
                                    <li v-if="isValid('CanViewUserRole')">
                                        <a v-on:click="GoTo('/Roles', 'Settings And Permission_token', 'true')"
                                            href="javascript:void(0);"> {{ $t('Dashboard.UserRoles') }}</a>
                                    </li>
                                    <!-- <li v-if="isValid('CanViewPermission')">
                                            <a v-on:click="GoTo('/CompanyOption', 'Settings And Permission_token', 'true')" href="javascript:void(0);">  {{ $t('Dashboard.CompanyOption') }}</a>
                                        </li> -->

                                </ul>
                            </li>
                        </ul>
                    </li>
                    <!-- Human Resource Management Menu Bar Code  -->

                <li v-if="isValid('HRSetup') ||isValid('HRReport') ||isValid('AttendanceManagement') ||isValid('EmployeePortal') ||isValid('EmployeeTasks')
                    ||isValid('AssetsManagementI') ||isValid('DocumentManagement') ||isValid('CanViewRunPayroll') || isValid('CanAddRunPayroll') || isValid('CanDraftRunPayroll')
                    || isValid('CanViewEmployeeReg') || isValid('CanViewAllowanceType') || isValid('CanViewAllowance') || isValid('CanViewDeduction')
                    || isValid('CanViewContribution') || isValid('CanViewPayRollSchedule') || isValid('CanViewSaleryTemplate') || isValid('CanViewEmployeeSalary')
                    || isValid('CanViewLoanPayment') || isValid('CanViewSalaryTaxSlab')">

                    <a href="javascript:void(0);">
                        <i data-feather="user" class="align-self-center menu-icon"></i><span>
                            {{ $t('Dashboard.HumanResources') }}
                        </span><span class="menu-arrow"><i class="mdi mdi-chevron-right"></i></span>
                    </a>

                    <ul class="nav-second-level" aria-expanded="false">

                        <li v-if="isValid('CanViewEmployeeReg')">
                            <a v-on:click="GoTo('/employeeRegistration', 'HR And PayRoll_token', 'true')" href="javascript:void(0);"> {{ $t('Dashboard.EmployeeProfile') }}</a>
                        </li>
                        <li v-else-if="isValid('CanAddEmployeeReg')">
                            <a v-on:click="GoTo('/addEmployeeRegistration', 'HR And PayRoll_token', 'true')" href="javascript:void(0);"> {{ $t('Dashboard.EmployeeProfile') }}</a>
                        </li>
                        <li v-if="isValid('HRReport')">
                            <a v-on:click="GoTo('/CommingSoon')" href="javascript:void(0);"> {{ $t('Dashboard.Reports') }}</a>
                        </li>
                        <li>
                            <a v-on:click="GoTo('/CommingSoon')" href="javascript:void(0);"> {{ $t('Dashboard.Setup') }}</a>
                        </li>
                        <li v-if="isValid('CanViewRunPayroll') || isValid('CanAddRunPayroll') || isValid('CanDraftRunPayroll') || isValid('CanViewAllowanceType')
                            || isValid('CanViewAllowance') || isValid('CanViewDeduction') || isValid('CanViewContribution') || isValid('CanViewPayRollSchedule')
                            || isValid('CanViewSaleryTemplate') || isValid('CanViewEmployeeSalary') || isValid('CanViewLoanPayment') || isValid('CanViewSalaryTaxSlab')">
                            <a href="javascript:void(0);">
                                <i class="ti-control-record"></i>{{ $t('Dashboard.PayrollManagement') }}<span class="menu-arrow left-has-menu">
                                    <i class="mdi mdi-chevron-right"></i>
                                </span>
                            </a>

                            <ul class="nav-second-level" aria-expanded="false">
                                <li>
                                    <a href="javascript:void(0);">
                                        <i class="ti-control-record"></i>Setup<span class="menu-arrow left-has-menu">
                                            <i class="mdi mdi-chevron-right"></i>
                                        </span>
                                    </a>

                                    <ul class="nav-second-level" aria-expanded="false">
                                        <li v-if="isValid('CanViewAllowanceType')">
                                            <a v-on:click="GoTo('/AllowanceType', 'HR And PayRoll_token', 'true')" href="javascript:void(0);"> {{ $t('Dashboard.AllowanceType') }}</a>
                                        </li>
                                        <li v-if="isValid('CanViewAllowance')">
                                            <a v-on:click="GoTo('/Allowance', 'HR And PayRoll_token', 'true')" href="javascript:void(0);"> {{ $t('Dashboard.Allowance') }}</a>
                                        </li>
                                        <li v-if="isValid('CanViewContribution')">
                                            <a v-on:click="GoTo('/Contribution', 'HR And PayRoll_token', 'true')" href="javascript:void(0);"> {{ $t('Dashboard.Contribution') }}</a>
                                        </li>
                                        <li v-if="isValid('CanViewDeduction')">
                                            <a v-on:click="GoTo('/Deduction', 'HR And PayRoll_token', 'true')" href="javascript:void(0);"> {{ $t('Dashboard.Deduction') }}</a>
                                        </li>
                                        <li v-if="isValid('CanViewSaleryTemplate')">
                                            <a v-on:click="GoTo('/SalaryTemplate', 'HR And PayRoll_token', 'true')" href="javascript:void(0);"> {{ $t('Dashboard.SalaryTemplate') }}</a>
                                        </li>
                                        <li v-if="isValid('CanViewPayRollSchedule')">
                                            <a v-on:click="GoTo('/PayrollSchedule', 'HR And PayRoll_token', 'true')" href="javascript:void(0);"> {{ $t('Dashboard.PayrollSchedule') }}</a>
                                        </li>

                                        <li v-if="isValid('CanViewEmployeeSalary')">
                                            <a v-on:click="GoTo('/EmployeeSalary', 'HR And PayRoll_token', 'true')" href="javascript:void(0);"> {{ $t('Dashboard.EmployeeSalary') }}</a>
                                        </li>
                                        <li v-if="isValid('CanViewLoanPayment')">
                                            <a v-on:click="GoTo('/LoanPayment', 'HR And PayRoll_token', 'true')" href="javascript:void(0);"> {{ $t('Dashboard.LoanPayment') }}</a>
                                        </li>
                                        <li v-if="isValid('CanViewSalaryTaxSlab')">
                                            <a v-on:click="GoTo('/SalaryTaxSlab', 'HR And PayRoll_token', 'true')" href="javascript:void(0);"> {{ $t('Dashboard.SalaryTaxSlab') }}</a>
                                        </li>

                                    </ul>
                                </li>

                                <li v-if="isValid('CanViewRunPayroll')">
                                    <a v-on:click="GoTo('/RunPayroll', 'HR And PayRoll_token', 'true')" href="javascript:void(0);"> {{ $t('Dashboard.Payroll') }}</a>
                                </li>
                                <li>
                                    <a v-on:click="GoTo('/CommingSoon')" href="javascript:void(0);"> {{ $t('Dashboard.Payslip') }}</a>
                                </li>
                                <li>
                                    <a v-on:click="GoTo('/CommingSoon')" href="javascript:void(0);"> {{ $t('Dashboard.Reports') }}</a>
                                </li>

                            </ul>
                        </li>
                        <li>
                            <a href="javascript:void(0);">
                                <i class="ti-control-record"></i>Shift Management<span class="menu-arrow left-has-menu">
                                    <i class="mdi mdi-chevron-right"></i>
                                </span>
                            </a>

                            <ul class="nav-second-level" aria-expanded="false">
                                <li>
                                    <a v-on:click="GoTo('/Shift', 'HR And PayRoll_token', 'true')" href="javascript:void(0);"> Shift</a>
                                </li>
                                <li>
                                    <a v-on:click="GoTo('/ShiftAssign', 'HR And PayRoll_token', 'true')" href="javascript:void(0);"> Shift Assign</a>
                                </li>
                                <li>
                                    <a v-on:click="GoTo('/HolidaySetup', 'HR And PayRoll_token', 'true')" href="javascript:void(0);"> Holidays</a>
                                </li>

                            </ul>
                        </li>
                        <li v-if="isValid('AttendanceManagement')">
                            <a href="javascript:void(0);">
                                <i class="ti-control-record"></i>{{ $t('Dashboard.AttendanceManagement') }}<span class="menu-arrow left-has-menu">
                                    <i class="mdi mdi-chevron-right"></i>
                                </span>
                            </a>

                            <ul class="nav-second-level" aria-expanded="false">
                                <li v-if="isValid('CanViewManualAttendance')">
                                    <a v-on:click="GoTo('/ManualAttendance', 'HR And PayRoll_token', 'true')" href="javascript:void(0);"> {{ $t('Dashboard.ManualAttendece') }}</a>
                                </li>
                                <li v-if="isValid('CanAddTodayAttendance')">
                                    <a v-on:click="GoTo('/EmployeeTodayAttendence', 'HR And PayRoll_token', 'true')" href="javascript:void(0);"> {{ $t('Dashboard.TodayAttendece') }} </a>
                                </li>
                                <li v-if="isValid('CanViewAttendanceReport')">
                                    <a v-on:click="GoTo('/AttendanceReport', 'HR And PayRoll_token', 'true')" href="javascript:void(0);"> {{ $t('Dashboard.Reports') }}</a>
                                </li>
                                <li v-if="isValid('CanAddHolidaySetup')">
                                    <a v-on:click="GoTo('/AddHolidayOfMonth', 'HR And PayRoll_token', 'true')" href="javascript:void(0);"> {{ $t('Dashboard.Setup') }}</a>
                                </li>

                            </ul>
                        </li>

                       
                        
                       
                    </ul>
                </li>

                </ul>

            </div>
        </div>
        <!--end left-sidenav-->

        <div class="page-wrapper">
            <!--Top Bar Start-->
            <div class="topbar">
                <!--Navbar-->
                <nav class="navbar-custom">
                    <ul class="list-unstyled topbar-nav float-end mb-0">

                        <li class="dropdown hide-phone">
                            <a class="nav-link dropdown-toggle arrow-none waves-light waves-effect  "
                                data-bs-toggle="dropdown" href="javascript:void(0)" role="button" aria-haspopup="false"
                                aria-expanded="false">
                                Quick Acess <i class="fab fa-xing-square"></i>
                            </a>

                            <div class="dropdown-menu dropdown-menu-end dropdown-lg p-0">
                                <!-- Top Search Bar -->
                                <div class="app-search-topbar">
                                    <ul class="nav nav-tabs ">
                                        <li class="nav-item">
                                            <a class="nav-link " v-on:click="AddCustomer"> <i
                                                    class="fas fa-angle-right"></i>&nbsp; Customer</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" @click="AddProduct" href="javascript:void(0);"> <i
                                                    class="fas fa-angle-right"></i>&nbsp; Product</a>
                                        </li>

                                        <li class="nav-item">
                                            <a class="nav-link"
                                                v-on:click="GoTo('/addSaleService', 'Sales_token', 'true', 'SimpleInvoice', false)"
                                                href="javascript:void(0);"> <i class="fas fa-angle-right"></i>
                                                &nbsp;Invoice</a>
                                        </li>

                                        <li class="nav-item">
                                            <a class="nav-link"
                                                v-on:click="GoTo('/addSaleService', 'Sales_token', 'true', 'ServiceProformaInvoice', true)"
                                                href="javascript:void(0);"> <i class="fas fa-angle-right"></i> &nbsp;Service
                                                Invoice</a>
                                        </li>

                                    </ul>
                                    <ul class="nav nav-tabs ">
                                        <li class="nav-item">
                                            <a class="nav-link " v-on:click="GoTo('/addsupplier', 'Purchase_token', 'true')"
                                                href="javascript:void(0);"><i class="fas fa-angle-right"></i>&nbsp;
                                                Supplier</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" href="#" v-on:click="AddExpense"> &nbsp;<i
                                                    class="fas fa-angle-right"></i> &nbsp;Expense</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" href="#" v-on:click="AddPurchaseOrder"><i
                                                    class="fas fa-angle-right"></i>&nbsp; Purchase</a>
                                        </li>

                                    </ul>



                                </div>
                            </div>
                        </li>

                        <li class="dropdown" v-if="isValid('EmployeePortalActivation') && isHrPortal" hidden>
                            <a class="nav-link dropdown-toggle waves-effect waves-light nav-user pe-auto"
                                v-on:click="PortalFunc(false)" data-bs-toggle="dropdown" href="javascript:void(0)"
                                role="button" aria-haspopup="false" aria-expanded="false">
                                <i class="fas fa-user"></i>
                                HR Portal
                            </a>
                        </li>
                        <li class="dropdown" v-if="isValid('EmployeePortalActivation') && !isHrPortal" hidden>
                            <a class="nav-link dropdown-toggle waves-effect waves-light nav-user pe-auto"
                                v-on:click="PortalFunc(true)" data-bs-toggle="dropdown" href="javascript:void(0)"
                                role="button" aria-haspopup="false" aria-expanded="false">

                                <i class="fas fa-user"></i>
                                HR Portal
                            </a>
                        </li>


                        <li class="dropdown">
                            <a class="nav-link dropdown-toggle waves-effect waves-light nav-user" data-bs-toggle="dropdown"
                                href="javascript:void(0)" role="button" aria-haspopup="false" aria-expanded="false">

                                <i class="fas fa-globe"></i>
                                Language
                            </a>
                            <div class="dropdown-menu dropdown-menu-end">
                                <a v-if="english == 'true'" @click="setLocale('en')" class="dropdown-item"
                                    href="javascript:void(0)">
                                    English
                                </a>
                                <a v-if="arabic == 'true'" @click="setLocale('ar')" class="dropdown-item"
                                    href="javascript:void(0)">
                                    Arabic
                                </a>
                                <a v-if="portugues == true" @click="setLocale('pt')" class="dropdown-item"
                                    href="javascript:void(0)">
                                    Portugues
                                </a>

                            </div>
                        </li>

                        <li class="dropdown">
                            <a class="nav-link dropdown-toggle waves-effect waves-light nav-user" data-bs-toggle="dropdown"
                                href="javascript:void(0)" role="button" aria-haspopup="false" aria-expanded="false">
                                <span class="mx-1 nav-user-name hidden-sm">{{ DisplayUserName }}</span>
                                <img src="assets/images/users/user-5.jpg" alt="profile-user"
                                    class="rounded-circle thumb-xs" />
                            </a>
                            <div class="dropdown-menu dropdown-menu-end">
                                <a v-on:click="UserProfile" class="dropdown-item" href="javascript:void(0)">
                                    <i data-feather="user" class="align-self-center icon-xs icon-dual me-1"></i> {{
                                        $t('Dashboard.MyProfile')
                                    }}
                                </a>

                                <div class="dropdown-divider mb-0"></div>
                                <a class="dropdown-item" href="javascript:void(0)" v-on:click="logout()">
                                    <i data-feather="power" class="align-self-center icon-xs icon-dual me-1"></i> {{
                                        $t('Dashboard.LogOut')
                                    }}
                                </a>
                            </div>
                        </li>
                    </ul>

                    <ul class="list-unstyled topbar-nav mb-0">
                        <li>
                            <button class="nav-link button-menu-mobile">
                                <i data-feather="menu" class="align-self-center topbar-icon"></i>
                            </button>
                        </li>

                    </ul>
                </nav>
                <!--end navbar-->
            </div>
            <!--Top Bar End-->
            <!--Page Content-->
            <div class="page-content">
                <router-view @input="logoutUser"></router-view>
                <div v-if="dashboard == '/dashboard'">
                    <dashboard></dashboard>
                </div>

                <footer class="footer text-center text-sm-start">
                    <span>
                        &copy;
                        2024 <a href="https://techplus.dev/" target="_blank" class="fw-normal">Software developed by Tech Plus</a>
                    </span>

                    <span class="text-muted d-none d-sm-inline-block float-end">
                        Version 1.2.9.5 Last Updated March 23, 2024
                    </span>
                </footer>
            </div>
        </div>
        <departmentmodel :currency="newCurrency" :show="show" v-if="show" @close="show = false" :type="type" />

        <loading :active.sync="loading" :can-cancel="true" :is-full-page="true"></loading>
        
    </body>
</template>

<script>
import clickMixin from '@/Mixins/clickMixin'
import language from 'element-ui/lib/locale'
import ar from 'element-ui/lib/locale/lang/ar'
import en from 'element-ui/lib/locale/lang/en'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    mixins: [clickMixin],
    name: 'locale-changer',
    components: {
        Loading
    },
    data() {
        return {
            type:'',
            newCurrency: {
                id: '00000000-0000-0000-0000-000000000000',
                name: '',
                nameArabic: '',
                sign: '',
                type: '',
                image: '',
                isActive: true,
                isDisable: false,
            },
            show: false,
            isCaseUser: false,
            isCaseSystem: false,
            mainBranch: false,
            sizelist: [],
            isHrPortal: false,
            aboutUsDetail: '',
            createDocument: false,
            loading: false,
            IsDailyExpense: false,
            noblePermissions: '',
            paymentLimitInterval: '',
            companyId: '',
            langs: ['en', 'ar', 'pt'],
            invoiveItem: false,
            invoiveBarCode: false,
            invoiveBarCodeItem: false,
            saleOrderPerm: false,
            WholeSale: '',
            purchaseOrder: false,
            isMouseover: false,
            expenseBill: false,
            IsExpenseAccount: false,
            DisplayUserName: '',
            role: '',
            dashboard: '',
            ur: '',
            isAccount: '',
            isDayStart: '',
            arabic: '',
            english: '',
            isMasterProduct: false,
            nobleRole: '',
            //show: false,
            loginHistory: {
                userId: '',
                isLogin: false,
                companyId: ''
            },
            dayStart: '',
            propValvue: '',
            saleMenu: false,
            inventoryMenu: false,
            wareHouseMenu: false,
            startOperationMenu: false,
            startOpSetupMenu: false,
            accountMenu: false,
            purchaseMenu: false,
            settingMenu: false,
            humanMenu: false,
            financialMenu: false,
            contractMenu: false,
            logisticMenu: false,
            importExportMenu: false,
            systemMenu: false,
            productionMenu: false,
            inquiryMenu: false,
            leftToRight: false,

            AllSale: false,
            applicationName: '',
            branchName: '',
        }
    },
    methods: {

        AddCurrency: function () {
            this.newCurrency = {
                id: '00000000-0000-0000-0000-000000000000',
                name: '',
                nameArabic: '',
                sign: '',
                type: '',
                image: '',
                isActive: true,
                isDisable: false,
            };
            this.show = !this.show;
            this.type = "Add";
        },
        GoTo: function (link, token, fromDashboard, formName, fromService) {
            localStorage.setItem('IsService', fromService);

            this.$router.push({
                path: link,
                query: {
                    token_name: token,
                    fromDashboard: fromDashboard,
                    formName: formName,
                    fromService: fromService,
                }
            });
        },

        StartScreen: function () {
            this.$router.push('/StartScreen')
        },
        commingSoonPage: function () {
            this.$router.push('/CommingSoon')
        },



        logoutUser: function (user) {

            this.propValvue = user;
            this.saleMenu = false;
            this.inventoryMenu = false;
            this.wareHouseMenu = false;
            this.startOperationMenu = false;
            this.startOpSetupMenu = false;
            this.purchaseMenu = false;
            this.accountMenu = false;
            this.settingMenu = false;
            this.humanMenu = false;
            this.financialMenu = false;
            this.contractMenu = false;
            this.logisticMenu = false;
            this.importExportMenu = false;
            this.systemMenu = false;
            this.productionMenu = false;
            this.inquiryMenu = false;

            if (this.propValvue == 'AddSale' ||
                this.propValvue == 'AddCustomer2' ||
                this.propValvue == 'Customer' ||
                this.propValvue == 'AddSaleOrder' ||
                this.propValvue == 'SaleOrder' ||
                this.propValvue == 'AddQuotation' ||
                this.propValvue == 'Quotation' ||
                this.propValvue == 'AddSaleReturn' ||
                this.propValvue == 'SaleReturn' ||
                this.propValvue == 'Sales' ||
                this.propValvue == 'AddSaleService' ||
                this.propValvue == 'SaleService' ||
                this.propValvue == 'addPaymentVoucherBankReceipt' ||
                this.propValvue == 'SaleServiceOrder' ||
                this.propValvue == 'AddSaleServiceOrder' ||
                this.propValvue == 'ServiceQuotation' ||
                this.propValvue == 'AddServiceQuotation' ||
                this.propValvue == 'PaymentVoucherListBankReceipt' ||
                this.propValvue == 'PrintSetting' ||
                this.propValvue == 'InquirySetup' ||
                this.propValvue == 'Inquiry') {
                this.saleMenu = true;
            }
            if (this.propValvue == 'ProductMaster' ||
                this.propValvue == 'AddProduct' ||
                this.propValvue == 'InventoryBlind' ||
                this.propValvue == 'InventoryBlindList' ||
                this.propValvue == 'AddBundles' ||
                this.propValvue == 'Bundles' ||
                this.propValvue == 'AddPromotion' ||
                this.propValvue == 'Promotion' ||
                this.propValvue == 'Product' ||
                this.propValvue == 'Category' ||
                this.propValvue == 'Brand' ||
                this.propValvue == 'Origin' ||
                this.propValvue == 'Size' ||
                this.propValvue == 'Color' ||
                this.propValvue == 'Unit' ||
                this.propValvue == 'SubCategory' ||
                this.propValvue == 'ProductManagement') {
                this.inventoryMenu = true;
            }
            if (this.propValvue == 'AddWareHouseTransfer' ||
                this.propValvue == 'WareHouseTransfer' ||
                this.propValvue == 'AddStockValueStockIn' ||
                this.propValvue == 'StockValueStockIn' ||
                this.propValvue == 'StockValueStockOut' ||
                this.propValvue == 'AddStockValueStockOut' ||
                this.propValvue == 'AddWarehouse' ||
                this.propValvue == 'Warehouse') {
                this.wareHouseMenu = true;
            }
            if (this.propValvue == 'StartOperation' ||
                this.propValvue == 'StartOperationSetup' ||
                this.propValvue == 'StartOperationReport') {
                this.startOperationMenu = true;
            }
            if (this.propValvue == 'UserSetup' ||
                this.propValvue == 'CounterSetup' ||
                this.propValvue == 'AdditionalSetup') {
                this.startOpSetupMenu = true;
            }
            if (this.propValvue == 'Addpurchase' ||
                this.propValvue == 'purchase' ||
                this.propValvue == 'Addpurchaseorder' ||
                this.propValvue == 'GoodReceive' ||
                this.propValvue == 'goodReceives' ||
                this.propValvue == 'AddGoodReceive' ||
                this.propValvue == 'AddPurchaseReturn' ||
                this.propValvue == 'PurchaseReturn' ||
                this.propValvue == 'AddAutoPurchaseTemplate' ||
                this.propValvue == 'autoPurchaseTemplate' ||
                this.propValvue == 'AddSupplier' ||
                this.propValvue == 'Supplier' ||
                this.propValvue == 'addPaymentVoucherBankPay' ||
                this.propValvue == 'PaymentVoucherListBankPay' ||
                this.propValvue == 'purchaseorder') {
                this.purchaseMenu = true;
            }
            if (this.propValvue == 'addPaymentVoucherPettyCash' ||
                this.propValvue == 'AddJournalVoucherJournalVoucher' ||
                this.propValvue == 'JournalVoucherViewJournalVoucher' ||
                this.propValvue == 'AddJournalVoucherOpeningCash' ||
                this.propValvue == 'JournalVoucherViewOpeningCash' ||
                this.propValvue == 'AddTemporaryCashRequest' ||
                this.propValvue == 'TemporaryCashRequest' ||
                this.propValvue == 'AddTemporaryCashIssue' ||
                this.propValvue == 'TemporaryCashIssue' ||
                this.propValvue == 'TemporaryCashAllocation' ||
                this.propValvue == 'AddTemporaryCashAllocation' ||
                this.propValvue == 'PaymentVoucherListPettyCash' ||
                this.propValvue == 'Currency' ||
                this.propValvue == 'Bank' ||
                this.propValvue == 'AddBank' ||
                this.propValvue == 'MonthlyCost' ||
                this.propValvue == 'coa' ||
                this.propValvue == 'PaymentOptions' ||
                this.propValvue == 'DenominationSetup' ||
                this.propValvue == 'TaxRate' ||
                this.propValvue == 'ExpenseType' ||
                this.propValvue == 'FinancialSetup') {
                this.accountMenu = true;
            }
            if (this.propValvue == 'ProductMaster' ||
                this.propValvue == 'AddSignUp' ||
                this.propValvue == 'SignUp' ||
                this.propValvue == 'Roles' ||
                this.propValvue == 'Permissions' ||
                this.propValvue == 'UserSetup') {
                this.settingMenu = true;
            }
            if (this.propValvue == 'EmployeeRegistration' ||
                this.propValvue == 'AddEmployeeRegistration' ||
                this.propValvue == 'City' ||
                this.propValvue == 'GeographicalSetup' ||
                this.propValvue == 'Region') {
                this.humanMenu = true;
            }
            if (this.propValvue == 'EmployeeRegistration' ||
                this.propValvue == 'AddEmployeeRegistration' ||
                this.propValvue == 'City' ||
                this.propValvue == 'GeographicalSetup' ||
                this.propValvue == 'Region') {
                this.financialMenu = true;
            }
            if (this.propValvue == 'EmployeeRegistration' ||
                this.propValvue == 'AddEmployeeRegistration' ||
                this.propValvue == 'City' ||
                this.propValvue == 'GeographicalSetup' ||
                this.propValvue == 'Region') {
                this.contractMenu = true;
            }
            if (this.propValvue == 'ManualAttendance') {
                this.humanMenu = true;
            }
            if (this.propValvue == 'EmployeeTodayAttendence') {
                this.humanMenu = true;
            }
            if (this.propValvue == 'AttendanceReport') {
                this.humanMenu = true;
            }
            if (this.propValvue == 'AddHolidayOfMonth') {
                this.humanMenu = true;
            }

            if (this.propValvue == 'LogisticsListTransporter' ||
                this.propValvue == 'AddLogisticsTransporter' ||
                this.propValvue == 'LogisticsListClearanceAgent' ||
                this.propValvue == 'AddLogisticsClearanceAgent' ||
                this.propValvue == 'AddLogisticsShippingLinear' ||
                this.propValvue == 'LogisticsListShippingLinear') {
                this.logisticMenu = true;
            }

            if (this.propValvue == 'CompanyProfile' ||
                this.propValvue == 'Restore' ||
                this.propValvue == 'PushRecords' ||
                this.propValvue == 'PullRecords' ||
                this.propValvue == 'FlushDatabase' ||
                this.propValvue == 'Terminal' ||
                this.propValvue == 'BankPosTerminal' ||
                this.propValvue == 'MultiBarcodePrinting' ||
                this.propValvue == 'RackBarcodeCreate' ||
                this.propValvue == 'ApplicationUpdate' ||
                this.propValvue == 'Backup' || this.propValvue == 'aboutUs') {
                this.systemMenu = true;
            }
            if (this.propValvue == 'SaleOrder' ||
                this.propValvue == 'AddRecipeNo' ||
                this.propValvue == 'RecipeNo' ||
                this.propValvue == 'AddProductionBatch' ||
                this.propValvue == 'ProductionBatch' ||
                this.propValvue == 'AddDispatchNote' ||
                this.propValvue == 'DispatchNotes' ||
                this.propValvue == 'AddSaleOrder') {
                this.productionMenu = true;
            }

            localStorage.removeItem("BarcodeScan");
        },
        GetSizeValue: function () {

            this.sizelist = this.$store.state.sizeList;

        },
        AddCustomer: function () {
            this.$router.push({
                path: '/addCustomer',
                query: { formName: this.customerType }
            })
        },
        AddExpense: function () {


            this.$router.push({
                path: '/adddailyexpense',
                query: {
                    formName: this.formName
                }

            });



        },
        AddPurchaseOrder: function () {
            this.$router.push('/addpurchase');
        },
        AddProduct: function () {
            var root = this;
            {
                root.$router.push({
                    path: '/addproduct',
                    query: {
                        formName: 'Product',
                    }
                })
            }
        },



        GetSizeData: function () {
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            root.$https.get('Product/SizeList?isActive=true' + '&docType=' + '' + '&isVariant=true', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                if (response.data != null) {
                    root.$store.dispatch('GetSizeList', response.data.results.sizes);
                    root.sizelist = response.data.results.sizes;

                }
                root.loading = false;
            });
        },
        setLocale(locale) {

            this.$i18n.locale = locale;
            if (locale == 'en') {
                language.use(en)
            } else {
                language.use(ar)
            }

            localStorage.setItem('locales', locale);
            this.$router.go(this.$router.currentRoute.fullPath)

        },

        UserProfile: function () {
            this.$router.push('/RegisterUser');
        },

        logout: function () {
            var root = this;
            var getLocale = localStorage.getItem('locales');

            root.$session.destroy();
            localStorage.clear();

            localStorage.setItem('locales', getLocale);

            root.$router.push('/')
            var url = '/account/logout';
            this.$https.post(url, this.login).then(function (response) {

                if (response.data == "Success") {

                    var getLocale = localStorage.getItem('locales');

                    root.$session.destroy();
                    localStorage.clear();

                    localStorage.setItem('locales', getLocale);

                    root.$router.push('/')

                } else {
                    root.$swal.fire({
                        icon: 'error',
                        title: 'Error Logging Out'
                    });
                }

            });

        },

    },
    created() {
        this.GetSizeData();
        this.portugues = localStorage.getItem('Portugues') == "true" ? true : false;
        this.leftToRight = localStorage.getItem('LeftToRight') == "true" ? true : false;
        this.isHrPortal = localStorage.getItem('HrPortal') == "true" ? true : false;
        this.applicationName = localStorage.getItem('ApplicationName');

        this.role = localStorage.getItem('RoleName');
    },
    mounted: function () {

        this.companyId = localStorage.getItem('CompanyID')

        if (this.$session.exists()) {

            this.IsExpenseAccount = localStorage.getItem('IsExpenseAccount') == 'true' ? true : false;
            this.isCaseUser = localStorage.getItem('IsCaseUser') == 'true' ? true : false;
            this.isCaseSystem = localStorage.getItem('IsCaseSystem') == 'true' ? true : false;
            this.createDocument = localStorage.getItem('CreateDocument') == 'true' ? true : false;

            this.invoiveItem = localStorage.getItem('invoiveItem') == "true" ? true : false;
            this.invoiveBarCode = localStorage.getItem('invoiveBarCode') == "true" ? true : false;
            this.invoiveBarCodeItem = localStorage.getItem('invoiveBarCodeItem') == "true" ? true : false;
            this.saleOrderPerm = localStorage.getItem('saleOrderPerm') == "true" ? true : false;
            this.dayStart = localStorage.getItem('DayStart') == "true" ? true : false;
            this.isMasterProduct = localStorage.getItem('IsMasterProductPermission') == 'true' ? true : false;
            this.IsDailyExpense = localStorage.getItem('IsDailyExpense') == 'true' ? true : false;
            this.expenseBill = localStorage.getItem('expenseBill') == 'true' ? true : false;
            this.importExportSale = localStorage.getItem('ImportExportSale') == 'true' ? true : false;

            this.WholeSale = localStorage.getItem('BankDetail');

            this.DisplayUserName = localStorage.getItem('UserName');
            this.purchaseOrder = localStorage.getItem('PurchaseOrder') == 'true' ? true : false;

            this.isAccount = localStorage.getItem('isAccount');
            this.dashboard = this.$router.options.routes;

            this.isDayStart = localStorage.getItem('DayStart');
            this.nobleRole = localStorage.getItem('NobleRole');

        }
        if (!this.$session.exists()) {
            this.$router.push('/')
        }
        this.english = 'true';
        this.arabic = localStorage.getItem('Arabic');
        this.IsSimpleInvoice = localStorage.getItem('IsSimpleInvoice') == 'true' ? true : false;
        this.AllSale = localStorage.getItem('AllSale') == 'true' ? true : false;

        this.branchName = localStorage.getItem('BranchName');
        this.allowBranches = localStorage.getItem('AllowBranches') == 'true' ? true : false;
        this.mainBranch = localStorage.getItem('MainBranch') == 'true' ? true : false;
    },

}
</script>
