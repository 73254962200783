﻿
<template>
    <body class="account-body accountbg">

        <!-- Log In page -->
        <div class="container">
            <div class="row vh-100 d-flex justify-content-center">
                <div class="col-12 align-self-center">
                    <div class="row">
                        <div class="col-lg-5 mx-auto">
                            <div class="card">
                                <div class="card-body p-0 auth-header-box bg-white">
                                    <div class="text-center p-3">
                                        <a href="javascript:void(0);" class="logo logo-admin">
                                            <img src="favicon.png" height="50" alt="logo" class="auth-logo">
                                        </a>
                                        <h4 class="mt-3 mb-1 fw-semibold font-18" style="color: #0C213A;"> ERP</h4>
                                        <p class="text-muted  mb-0">{{ $t('login.WelcomePleaselogintoyouraccount') }}</p>
                                    </div>
                                </div>
                                <div class="card-body p-0">
                                    <div class="tab-content">
                                        <div class="tab-pane active p-3" id="LogIn_Tab" role="tabpanel">
                                            <div class="form-horizontal auth-form">

                                                <div class="form-group mb-2">
                                                    <label class="form-label">{{ $t('login.UsernameorEmail') }}</label>
                                                    <div class="input-group">
                                                        <input v-model="$v.login.email.$model" type="text"
                                                            class="form-control" @keyup.enter="userlogin"
                                                            :placeholder="$t('login.Enteryourusernameoremail')">
                                                    </div>
                                                </div><!--end form-group-->

                                                <div class="form-group mb-2">
                                                    <label class="form-label">{{ $t('login.RegisterUser_Password')
                                                    }}</label>
                                                    <div class="input-group ">
                                                        <input v-model="$v.login.password.$model" :type="password"
                                                            class="form-control" @keyup.enter="userlogin"
                                                            :placeholder="$t('login.EnteryourPassword')">
                                                        <div style=" position: absolute; top: 24%; " @click="showPassword"
                                                            v-bind:style="$i18n.locale == 'en'  ? 'left: 94%' : 'left: 11px'">
                                                            <i class="fas fa-eye" v-if="eyeValue == false"></i>
                                                            <i class="fas fa-eye-slash" v-if="eyeValue == true"></i>
                                                        </div>
                                                    </div>

                                                </div><!--end form-group-->

                                                <div class="form-group row my-3">
                                                    <div class="col-sm-6">
                                                        <div class="custom-control custom-switch switch-success">
                                                            <input v-model="login.rememberMe" type="checkbox"
                                                                class="custom-control-input" id="customSwitchSuccess">
                                                            <label class="form-label text-muted mx-1"
                                                                for="customSwitchSuccess">{{ $t('login.Rememberme')
                                                                }}</label>
                                                        </div>
                                                    </div><!--end col-->
                                                    <div class="col-sm-6 text-end">
                                                        <a href="javascript:void(0)" v-on:click="openmodel"
                                                            class="text-muted font-13"><i class="dripicons-lock"></i> {{
                                                                $t('login.ForgotPassword?') }}</a>
                                                    </div><!--end col-->
                                                </div><!--end form-group-->

                                                <div class="form-group mb-0 row">
                                                    <div class="m-3 text-center text-muted">
                                                     <p class="mb-0">Don't have an account ?  <a v-on:click="GotoPage('/Register')" class="text-primary ms-2">Free Resister</a></p>
                                                    </div>
                                                    <div class="col-12">
                                                        <small class="text-danger">{{ customError }}</small>
                                                        <button v-on:click="userlogin"
                                                            class="btn btn-primary w-100 waves-effect waves-light"
                                                            type="button">
                                                            <i v-if="loading"
                                                                class="la la-refresh text-secondary la-spin progress-icon-spin"
                                                                style="font-size:.8125rem;"></i>
                                                            <span v-else>{{ $t('login.Login1') }} <i
                                                                    class="fas fa-sign-in-alt ms-1"></i></span>
                                                        </button>
                                                    </div><!--end col-->
                                                </div> <!--end form-group-->
                                            </div><!--end form-->
                                        <!--<div class="m-3 text-center text-muted">
                                                <p class="mb-0">Don't have an account ?  <a href="auth-register.html" class="text-primary ms-2">Free Resister</a></p>
                                                    </div>-->
                                            <div class="account-social">
                                                <h6 class="mb-3 " style="color:transparent;">-</h6>
                                            </div>
                                            <div class="btn-group w-100">
                                                <a type="button"
                                                    class="btn btn-sm btn-outline-secondary" href="https://techplus.dev/">Support</a>
                                                <a type="button" href="https://techplus.dev/" class="btn btn-sm btn-outline-secondary">Contact
                                                    Us</a>
                                                <a type="button" href="https://techplus.dev/"  class="btn btn-sm btn-outline-secondary">Visit
                                                    Us</a>
                                            </div>
                                        </div>
                                    </div>
                                </div><!--end card-body-->
                                <div class="card-body bg-light-alt text-center">
                                    <span class="text-muted d-none d-sm-inline-block">
                                        <small>Version 1.0.0.0 Last Updated March 23, 2024. <br><a href="https://techplus.dev/" target="_blank" class="fw-normal">Software developed by Tech Plus</a></small>
                                    </span>
                                </div>
                            </div><!--end card-->
                        </div><!--end col-->
                    </div><!--end row-->
                </div><!--end col-->
            </div><!--end row-->
        </div><!--end container-->
        <!-- End Log In page -->

        <!-- <forgotPassword :show="show" v-if="show" @close="show = false" /> -->
    </body>
</template>
<script>

import { required } from 'vuelidate/lib/validators';

export default {

    data() {
        return {
            isPortuguese: false,
            langs: ['en', 'ar'],
            login: {
                email: '',
                password: '',
                rememberMe: true
            },
            password: "password",
            eyeValue: false,
            roles: [],
            open: false,
            website: '',
            error: '',
            show: false,
            customError: '',
            loading: false,
            isEmployee: true,
            isAccount: false,
            IsProceed: false,
            heading: '',
            description: '',
            companyName: '',
            applicationName: '',
            email: '',
            favName: '',
            terms: false,
            os: '',
            loginHistory: {
                userId: '',
                isLogin: true,
                operatingSystem: '',
                companyId: ''
            },
            isPayment: false,
            expirationMsg: '',

        }
    },
    validations: {
        login:
        {
            email:
            {
                required
            },
            password:
            {
                required
            }
        }
    },
    
    methods: {

        GotoPage: function (link) {
                this.$router.push({ path: link });
            },
        showPassword() {
            if (this.password === "password") {
                this.password = "text";
                this.eyeValue = true;
            }
            else {
                this.password = "password"
                this.eyeValue = false;
            }

        },
        hidepassword() {
            this.password = "password"
            this.eyeValue = false;
        },
        setLocale(locale) {

            this.$i18n.locale = locale
            localStorage.setItem('locales', locale);
        },

        gotoWebSite: function () {
            window.location.href = this.website;
        },

        openmodel: function () {
            this.show = !this.show;
        },


        userlogin: function () {
            var root = this;
            root.customError = '';
            root.loading = true;
            root.$session.start();
            localStorage.clear();
            localStorage.setItem('locales', 'en');






            var url = '/account/login';

            debugger
            this.$https.post(url, this.login).then(function (response) {
                 //eslint-disable-line

                {

                    if (response.data.companyId == "00000000-0000-0000-0000-000000000000") {

                        root.loading = false;
                        root.customError = 'Invalid Login Attempt.';

                    }
                    else if (response.data.isUseMachine) {
                        root.$session.start();
                        localStorage.setItem('CompanyID', response.data.companyId)
                        localStorage.setItem('token', response.data.token);
                        root.$router.push({
                            path: '/NotPermission',
                            query: { data: response.data.expiration, machine: true }
                        });
                    }
                    else if (response.data.isNotPayment) {
                        root.$session.start();
                        localStorage.setItem('CompanyID', response.data.companyId)
                        localStorage.setItem('token', response.data.token);
                        root.$router.push({
                            path: '/NotPermission',
                            query: { data: response.data.expiration }
                        });
                    }
                    else if (response.data.expiration != "" && response.data.expiration != null && response.data.isNotPayment == false && response.data.isPayment == false) {
                        root.$session.start();
                        localStorage.setItem('CompanyID', response.data.companyId)
                        localStorage.setItem('token', response.data.token);
                        root.$router.push({
                            path: '/NotPermission',
                            query: { data: response.data.expiration }
                        });
                    }

                    else {

                        root.loginHistory.userId = response.data.userId;
                        root.loginHistory.companyId = response.data.companyId;

                        root.isPayment = response.data.isPayment
                        root.expirationMsg = response.data.expiration

                        root.$session.start();


                        localStorage.setItem('token', response.data.token);
                        localStorage.setItem('UserId', response.data.userId);
                        localStorage.setItem('UserName', response.data.userName);
                        localStorage.setItem('LoginUserName', response.data.loginUserName);
                        localStorage.setItem('RoleName', response.data.roleName);
                        localStorage.setItem('ImagePath', response.data.imagePath);
                        localStorage.setItem('CompanyID', response.data.companyId);
                        localStorage.setItem('UserID', response.data.userId);
                        localStorage.setItem('FullName', response.data.fullName);
                        localStorage.setItem('PrinterName', response.data.printerName);
                        localStorage.setItem('IsHeaderFooter', response.data.isHeaderFooter);
                        localStorage.setItem('CashAccountId', response.data.cashAccountId);
                        localStorage.setItem('BankAccountId', response.data.bankAccountId);
                        localStorage.setItem('branchId',response.data.branchId);












                        localStorage.setItem('IsCaseSystem', response.data.isProduction);

                        /*PrintHeaderDetail*/
                        localStorage.setItem('TermsInAr', response.data.termsInAr);
                        localStorage.setItem('TermsInEng', response.data.termsInEng);

                        localStorage.setItem('Step1', response.data.step1);
                        localStorage.setItem('Step2', response.data.step2);
                        localStorage.setItem('Step3', response.data.step3);
                        localStorage.setItem('Step4', response.data.step4);
                        localStorage.setItem('Step5', response.data.step5);
                        localStorage.setItem('IsExpenseAccount',false);
                        localStorage.setItem('Permissions', response.data.permissions);
                        localStorage.setItem('ProductPrice', response.data.productPrice);
                        localStorage.setItem('UnitId', response.data.unitId);
                        localStorage.setItem('IsProceed', response.data.isProceed);
                        localStorage.setItem('IsCaseUser', response.data.isCaseCustomer);
                        localStorage.setItem('TermsCondition', response.data.termsCondition);
                        localStorage.setItem('companyNameEnglish', response.data.companyNameEnglish);
                        localStorage.setItem('companyEmail', response.data.companyEmail);
                        localStorage.setItem('nameEnglish', response.data.nameEnglish);
                        localStorage.setItem('companyPhoneNo', response.data.companyPhoneNo);
                        localStorage.setItem('addressEnglish', response.data.addressEnglish);
                        localStorage.setItem('logoPath', response.data.logoPath);
                        localStorage.setItem('WareHouseId', response.data.wareHouseId);

                        localStorage.setItem('NobleRole', response.data.nobleRole);

                        
                        root.terms = response.data.termsCondition;
                        
                        
                        localStorage.setItem('currency', response.data.currency);
                        localStorage.setItem('TaxRateId', response.data.taxRateId);
                        localStorage.setItem('taxMethod', response.data.taxMethod);


                        if(response.data.roleName=='Noble Admin')
                        {
                            root.$router.push('/StartScreen');
                        }
                        else
                        {
                            if (response.data.isProceed) {
                            root.$router.push('/StartScreen');
                        }

                        else {
                            root.$router.push('/setup');
                        }

                        }




                      

                       



                    }
                }

            }).catch(error => {
                root.customError = JSON.stringify(error.response.data.error);
                root.loading = false;
            })
        },

       


    },
    created: function () {
        localStorage.setItem('locales', 'en');

    }
}
</script>

