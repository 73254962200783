<template>
    <div class="row"
         v-if="isValid('CanViewSaleReturn')">

        <div class="col-lg-12" >
            <div class="row">
                <div class="col-sm-12">
                    <div class="page-title-box">
                        <div class="row">
                            <div class="col">
                                <h4 class="page-title">{{ $t('SaleReturn.SaleReturn') }}</h4>
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="javascript:void(0);">{{ $t('SaleReturn.Home') }}</a></li>
                                    <li class="breadcrumb-item active">
                                        {{ $t('SaleReturn.SaleReturn') }}
                                    </li>
                                </ol>
                            </div>

                            <div class="col-auto align-self-center">
                                <a v-if="isValid('CanAddSaleReturn')"
                                   v-on:click="AddSaleReturn" href="javascript:void(0);"
                                   class="btn btn-sm btn-outline-primary mx-1">
                                    <i class="align-self-center icon-xs ti-plus"></i>
                                    {{ $t('SaleReturn.AddNew') }}
                                </a>
                                <a v-on:click="GotoPage('/StartScreen')" href="javascript:void(0);"
                                   class="btn btn-sm btn-outline-danger">
                                    {{ $t('SaleReturn.Close') }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-lg-4" style="padding-top:20px">
                            <div class="input-group">
                                <button class="btn btn-soft-primary" type="button" id="button-addon1"><i class="fas fa-search"></i></button>
                                <input v-model="search" type="text" class="form-control" :placeholder="$t('SaleReturn.SearchOfInvoice')" aria-label="Example text with button addon" aria-describedby="button-addon1">

                            </div>
                        </div>

                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>{{ $t('Sale.FromDate') }}</label>
                                <datepicker v-model="fromDate" />
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>{{ $t('Sale.ToDate') }}</label>
                                <datepicker v-model="toDate" />
                            </div>
                        </div>
                    </div>


                </div>
                <div class="card-body">




                    <div class="tab-content">
                        <div class="tab-pane pt-3" id="profile" role="tabpanel" v-bind:class="{ active: active == 'Approved' }">
                            <div class="table-responsive">
                                <table class="table mb-0">
                                    <thead class="thead-light table-hover">
                                        <tr>
                                            <th>
                                                #
                                            </th>
                                            <th>
                                                {{ $t('SaleReturn.SaleReturnNo') }}
                                            </th>
                                            <th>
                                                {{ $t('SaleReturn.InvoiceNo') }}
                                            </th>
                                            <th>
                                                {{ $t('SaleReturn.Type') }}
                                            </th>
                                            <th>
                                                {{ $t('SaleReturn.Date') }}
                                            </th>
                                            <th>
                                                {{ $t('SaleReturn.CustomerName') }}
                                            </th>
                                            <th>
                                                {{ $t('SaleReturn.CreatedBy') }}
                                            </th>
                                            <th v-if="isDayStarts=='true'">
                                                {{ $t('SaleReturn.CounterId') }}
                                            </th>
                                            <th>
                                                {{ $t('SaleReturn.NetAmount') }}
                                            </th>
                                            <th style="width: 70px;" class="text-end"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(sale,index) in saleList" v-bind:key="index">
                                            <td v-if="currentPage === 1">
                                                {{index+1}}
                                            </td>
                                            <td v-else>
                                                {{((currentPage*10)-10) +(index+1)}}
                                            </td>
                                            <td>
                                                {{sale.registrationNumber}}
                                            </td>
                                            <td>
                                                {{sale.invoiceNo}}
                                            </td>
                                            <td>
                                                {{sale.isCredit ? 'Credit':'Cash'}}
                                            </td>
                                            <td>
                                                {{getDate(sale.date)}}
                                            </td>
                                            <td>
                                                <a href="javascript:void(0)" v-on:click="ViewCustomerInfo(sale.customerId, sale.cashCustomerId )" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">{{sale.customerName == null?sale.customerNameArabic : sale.customerName}}</a>
                                            </td>
                                            <td>
                                                {{sale.userName}}
                                            </td>
                                            <td v-if="isDayStarts=='true'">
                                                {{sale.counterName}}
                                            </td>
                                            <td>
                                                {{currency}} {{parseFloat(sale.netAmount).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                            </td>
                                            <td class="text-end">

                                                <button type="button" class="btn btn-light dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">{{ $t('SaleReturn.Action') }} <i class="mdi mdi-chevron-down"></i></button>
                                                <div class="dropdown-menu">
                                                    <a class="dropdown-item" href="javascript:void(0)" v-on:click="PrintRdlc(sale.id, 'print')" v-if="isValid('CanViewDetailSaleReturn')">{{ $t('SaleReturn.ViewInvoice') }}</a>
                                                    <a class="dropdown-item" href="javascript:void(0)" v-on:click="PrintRdlc(sale.id, 'print')" v-if="isValid('CanPrintInvoice')">{{ $t('SaleReturn.A4-Print') }}</a>
                                                    <a class="dropdown-item" href="javascript:void(0)" v-on:click="PrintInvoicePdf(sale.id)" v-if="isValid('CanPrintInvoice')">{{ $t('SaleReturn.PdfDownload') }}</a>


                                                </div>



                                                <!--<button class="btn btn-icon  btn-danger" @click="RemoveSale(sale.id)"><i class="fa fa-trash"></i></button>-->
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <hr />
                            <div class="row">
                                <div class="col-lg-6">
                                    <span v-if="currentPage === 1 && rowCount === 0">
                                        {{
 $t('Pagination.ShowingEntries')
                                        }}
                                    </span>
                                    <span v-else-if="currentPage === 1 && rowCount < 10">
                                        {{ $t('Pagination.Showing') }}
                                        {{ currentPage }} {{ $t('Pagination.to') }} {{ rowCount }} {{ $t('Pagination.of') }}
                                        {{ rowCount }} {{ $t('Pagination.entries') }}
                                    </span>
                                    <span v-else-if="currentPage === 1 && rowCount >= 11">
                                        {{ $t('Pagination.Showing') }}
                                        {{ currentPage }} {{ $t('Pagination.to') }} {{ currentPage * 10 }} {{
                                            $t('Pagination.of')
                                        }} {{ rowCount }} {{ $t('Pagination.entries') }}
                                    </span>
                                    <span v-else-if="currentPage === 1">
                                        {{ $t('Pagination.Showing') }} {{ currentPage }} {{
                                        $t('Pagination.to')
                                        }} {{ currentPage * 10 }} of {{ rowCount }} {{
        $t('Pagination.entries')
                                        }}
                                    </span>
                                    <span v-else-if="currentPage !== 1 && currentPage !== pageCount">
                                        {{
                                        $t('Pagination.Showing')
                                        }} {{ (currentPage * 10) - 9 }} {{ $t('Pagination.to') }}
                                        {{ currentPage * 10 }} {{ $t('Pagination.of') }} {{ rowCount }} {{
                                            $t('Pagination.entries')
                                        }}
                                    </span>
                                    <span v-else-if="currentPage === pageCount">
                                        {{ $t('Pagination.Showing') }}
                                        {{ (currentPage * 10) - 9 }} {{ $t('Pagination.to') }} {{ rowCount }} {{
                                            $t('Pagination.of')
                                        }} {{ rowCount }} {{ $t('Pagination.entries') }}
                                    </span>
                                </div>
                                <div class=" col-lg-6">
                                    <div class=" float-end" v-on:click="getPage()">
                                        <b-pagination pills size="sm" v-model="currentPage"
                                                      :total-rows="rowCount"
                                                      :per-page="10"
                                                      :first-text="$t('Table.First')"
                                                      :prev-text="$t('Table.Previous')"
                                                      :next-text="$t('Table.Next')"
                                                      :last-text="$t('Table.Last')">
                                        </b-pagination>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>
            </div>

           <sale-invoice-pdf-download :printDetails="printDetails" :headerFooter="headerFooter" v-if="printDetails.length != 0 && pdfShow" v-bind:key="printpdfRender" :formName="'SaleReturn'" />

           <invoice-report-print :printDetails="printDetails" :isSaleOrder="true" :isTouchScreen="'SaleReturn'"
            @close="isPrint = false" :show="isPrint" v-if="printDetails.length != 0 && isPrint && !download"
            v-bind:key="printRender" />


            <!-- <saleInvoice :printDetails="printDetails" :headerFooter="headerFooter" v-if="printDetails.length != 0  && show" v-bind:key="printRender" :formName="'SaleReturn'" /> -->

        </div>
       

        <div class="offcanvas offcanvas-end px-0" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
            <div class="offcanvas-header">
                <h5 id="offcanvasRightLabel" class="m-0">Customer Info ({{customerInformation.runningBalance}})</h5>
                <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body">
                <div class="row">
                    <div class="col-lg-12 form-group" v-if="english=='true'">
                        <label>{{ $t('Sale.CustomerName') |englishLanguage}}</label>
                        <input class="form-control" type="text" v-model="customerInformation.englishName" disabled />
                    </div>
                    <div class="col-lg-12 form-group" v-if="isOtherLang()">
                        <label>{{ $t('Sale.CustomerNameArabic') }}</label>
                        <input class="form-control" type="text" v-model="customerInformation.arabicName" disabled />
                    </div>
                    <div class="col-lg-12 form-group">
                        <label>{{ $t('AddCustomer.CustomerPhone') }}</label>
                        <input class="form-control" type="text" v-model="customerInformation.contactNo1" disabled />
                    </div>
                    <div class="col-lg-12 form-group">
                        <label>{{ $t('AddCustomer.CommercialRegistrationNo') }}</label>
                        <input class="form-control" type="text" v-model="customerInformation.commercialRegistrationNo" disabled />
                    </div>
                    <div class="col-lg-12 form-group">
                        <label>{{ $t('AddCustomer.VAT/NTN/Tax No') }}</label>
                        <input class="form-control" type="text" v-model="customerInformation.vatNo" disabled />
                    </div>
                    <div class="col-lg-12 form-group text-center">
                        <button v-if="expandSale" v-on:click="expandSale=false" type="button" class="btn btn-outline-info btn-icon-circle btn-icon-circle-sm"><i class="ti-angle-double-up"></i></button>
                        <button v-else v-on:click="expandSale=true" type="button" class="btn btn-outline-info btn-icon-circle btn-icon-circle-sm"><i class="ti-angle-double-down"></i></button>
                    </div>
                    <div v-if="expandSale" class="col-lg-12 form-group">
                        <p v-for="(sale,index) in customerInformation.invoiceList" v-bind:key="index" style="border-bottom: 1px solid #cbcbcb; ">
                            <a href="javascript:void(0);" data-bs-dismiss="offcanvas" aria-label="Close" v-on:click="ViewInvoice(sale.id)">
                                <span>{{index+1}}- {{sale.registrationNumber}} </span>
                                <span class="float-end">{{currency}} {{parseFloat(sale.netAmount).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span>
                            </a>
                            <br />
                            <small>{{getDate(sale.date)}}</small>
                        </p>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div v-else>
        <acessdenied></acessdenied>
    </div>

</template>

<script>
    import clickMixin from '@/Mixins/clickMixin'
    import moment from "moment";
    export default {
        mixins: [clickMixin],
        name: "SaleReturn",
        data: function () {
            return {
                bankDetail:false,
                thermalShow:false,
                show:false,
                pdfShow:false,
                saleReturnRegNo: '',
                fromDate: '',
                toDate: '',
                active: 'Draft',
                search: '',
                searchQuery: '',
                saleList: [],
                purchasePostList: [],
                currentPage: 1,
                pageCount: '',
                rowCount: '',
                currency: '',

                selected: [],
                selectAll: false,
                updateApprovalStatus: {
                    id: '',
                    approvalStatus: ''
                },
                CompanyID: '',
                UserID: '',
                employeeId: '',
                isPrint: false,
                isDayAlreadyStart: false,
                saleReturnDesign: false,
                IsProduction: false,
                isDayStarts: '',
                rendr: 0,
                counter: 0,
                printDetails: [],
                printDetailsPos: [],
                printRender: 0,
                printpdfRender: 0,
                printRenderPos: 0,
                headerFooter: {
                    footerEn: '',
                    footerAr: '',
                    company: ''
                },
                isDisabled: false,
                overWrite: false,
                businessLogo: '',
                businessNameArabic: '',
                businessNameEnglish: '',
                businessTypeArabic: '',
                businessTypeEnglish: '',
                companyNameArabic: '',
                companyNameEnglish: '',

                english: '',
                arabic: '',

                customerInformation: '',
                expandSale: false,
            }
        },
        watch: {
            search: function (val) {
                this.getData(val, 1, this.active, this.fromDate, this.toDate);
            },
            fromDate: function (fromDate) {
                this.counter++;
                if (this.counter != 1) {
                    localStorage.setItem('fromDate', fromDate);
                    this.getData(this.search, this.currentPage, this.active, fromDate, this.toDate);
                }
            },
            toDate: function (toDate) {
                this.counter++;
                if (this.counter != 2) {
                    localStorage.setItem('toDate', toDate);
                    this.getData(this.search, this.currentPage, this.active, this.fromDate, toDate);
                }
            }
        },
        methods: {
            PrintRdlc: function (value, prop) {
            debugger;

            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            root.$https.get("/Sale/SaleDetail?id=" + value + '&simpleQuery=true', {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            })
                .then(function (response) {
                    if (response.data != null) {
                        debugger;

                        root.printDetails = response.data;

                        if (prop == 'download') {
                            root.download = true;
                            root.isPrint = false;
                        } else {
                            root.download = false;
                            root.isPrint = true;
                            root.printRender++;
                        }
                    }
                });
        },
            ViewCustomerInfo: function (id, cashCustomerId) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                var cashCustomer = false;
                if (id == null) {
                    cashCustomer = true;
                    id = cashCustomerId;
                }
                root.$https.get('/Contact/ContactLedgerDetail?id=' + id + '&documentType=' + "SaleReturn" + '&cashCustomer=' + cashCustomer + '&isService=false', { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null) {
                            root.customerInformation = response.data;
                        }
                    },
                        function (error) {
                            console.log(error);
                        });
            },


            GotoPage: function (link) {
                this.$router.push({ path: link });
            },
           

           
            getDate: function (date) {
                return moment(date).format('LLL');
            },
           
            EditSale: function (id) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                root.$https.get('/Sale/SaleDetail?Id=' + id + '&isReturn=' + true, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null) {
                            root.$router.push({
                                path: '/AddSaleReturn',
                                query: { data: response.data }
                            })
                        }
                    },
                        function (error) {
                            console.log(error);
                        });
            },
            ViewInvoice: function (id) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                var batch = localStorage.getItem('openBatch')
                var openBatch = 0;
                if (batch != undefined && batch != null && batch != "") {
                    openBatch = batch
                }
                else {
                    openBatch = 1
                }
                var isFifo = localStorage.getItem('fIFO') == 'true' ? true : false;
                root.$https.get('/Sale/SaleDetail?Id=' + id + '&isFifo=' + isFifo + '&openBatch=' + openBatch, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {

                        if (response.data != null) {
                            response.data.saleItems.forEach(function (x) {
                                x.remainingQuantity = parseInt(x.quantity);
                            });
                            root.$router.push({
                                path: '/ViewSaleReturn',
                                query: {
                                    data: response.data,
                                    page: root.currentPage,
                                    active: root.active
                                }
                            })
                        }
                    },
                        function (error) {
                            console.log(error);
                        });
            },
           
           
           
           

           

            goToPaymentDetail: function (id) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Sale/SaleDetail?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null) {
                            root.$router.push({
                                path: '/SalePaymentDetail',
                                query: { data: response.data }
                            })
                        }
                    },
                        function (error) {
                            console.log(error);
                        });
            },
            makeActive: function (item) {
                this.active = item;
                this.getData(this.search, 1, item, this.fromDate, this.toDate);
            },
            getPage: function () {
                this.getData(this.search, this.currentPage, this.active, this.fromDate, this.toDate);
            },

            getData: function (search, currentPage, status, fromDate, toDate) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                localStorage.setItem('currentPage', this.currentPage);

                var isSaleReturnPost = status == 'Draft' ? false : true;
                this.$https.get('/Sale/SaleList?status=' + 'SaleReturn' + '&searchTerm=' + search + '&pageNumber=' + currentPage + '&isSaleReturnPost=' + isSaleReturnPost + '&fromDate=' + fromDate + '&toDate=' + toDate, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null) {
                            root.saleList = response.data.results.sales;
                            root.pageCount = response.data.pageCount;
                            root.rowCount = response.data.rowCount;
                            root.currentPage = response.data.currentPage;
                            root.rendr++;
                        }
                    });
            },

            RemoveSale: function (id) {
                var root = this;
                this.$swal({
                    title: (this.$i18n.locale == 'en' || this.isLeftToRight()) ? 'Are you sure?' : 'هل أنت متأكد؟', 
                    text: (this.$i18n.locale == 'en' || this.isLeftToRight()) ? 'You will not be able to recover this!' : 'لن تتمكن من استرداد هذا!', 
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: (this.$i18n.locale == 'en' || this.isLeftToRight()) ? 'Yes, delete it!' : 'نعم ، احذفها!', 
                    closeOnConfirm: false,
                    closeOnCancel: false
                }).then(function (result) {
                    if (result) {

                        var token = '';
                        if (root.$session.exists()) {
                            token = localStorage.getItem('token');
                        }
                        root.$https.get('/Sale/DeleteSale?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } })
                            .then(function (response) {
                                if (response.data.message.id != '00000000-0000-0000-0000-000000000000') {

                                    root.saleList.splice(root.saleList.findIndex(function (i) {
                                        return i.id === response.data.message.id;
                                    }), 1);
                                    root.$swal({
                                        title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Deleted!' : 'تم الحذف!',
                                        text: response.data.message.isAddUpdate,
                                        type: 'success',
                                        confirmButtonClass: "btn btn-success",
                                        buttonsStyling: false
                                    });
                                } else {
                                    root.$swal({
                                        title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                                        text: response.data.message.isAddUpdate,
                                        type: 'error',
                                        confirmButtonClass: "btn btn-danger",
                                        buttonsStyling: false
                                    });
                                }
                            },
                                function () {

                                    root.$swal({
                                        title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                                        text: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Delete UnSuccessfully' : 'حذف غير ناجح',
                                        type: 'error',
                                        confirmButtonClass: "btn btn-danger",
                                        buttonsStyling: false
                                    });
                                });
                    }
                    else {
                        this.$swal('Cancelled', 'Your invoice is still intact', 'info');
                    }
                });
            },
            AddSaleReturn: function () {
                this.$router.push('/AddSaleReturn');
            }
        },
        created: function () {
            
            if (this.$route.query.data == 'AddSaleReturns') {
                this.$emit('input', 'AddSaleReturns');

            }
            else {
                this.$emit('input', this.$route.name);

            }

        },
        mounted: function () {
            this.overWrite = localStorage.getItem('overWrite') == 'true' ? true : false;
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');

                this.active = 'Approved';
                this.getData(this.search, this.currentPage, this.active, this.fromDate, this.toDate);
           
           

        }
    }
</script>