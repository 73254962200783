<template>
    <div class="row">
        <div class="col-lg-12 ">
            <div class="row">
                <div class="col-4">
                    <h4 class="mb-0" v-if="newCustomer.id == '00000000-0000-0000-0000-000000000000'">{{
                        'New ' + newCustomer.customerType }}
                        <span>{{ contactCode }}</span>
                    </h4>
                    <h4 class="mb-0" v-else>Profile</h4>
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">{{ $t('AddCustomer.Home') }}</a></li>
                        <li class="breadcrumb-item active">Profile</li>
                    </ol>
                </div>
            </div>
            <div class="row">

                <div class="card-body">
                    <div class="dastone-profile">
                        <div class="row">
                            <div class="col-lg-4 align-self-center mb-3 mb-lg-0">
                                <div class="dastone-profile-main">
                                    <div class="dastone-profile-main-pic">
                                        <img src="assets/images/users/user-4.jpg" alt="" height="110"
                                            class="rounded-circle">
                                        <span class="dastone-profile_main-pic-change">
                                            <i class="fas fa-camera"></i>
                                        </span>
                                    </div>
                                    <div class="dastone-profile_user-detail">
                                        <h5 class="dastone-user-name">{{ newCustomer.englishName }}</h5>

                                    </div>
                                </div>
                            </div><!--end col-->

                            <div class="col-lg-4 ms-auto align-self-center">


                            </div><!--end col-->
                            <!-- <div class="col-lg-4 align-self-center">
                                                        <div class="row">
                                                            <div class="col-auto text-end border-end">
                                                                <button type="button"
                                                                    class="btn btn-soft-primary btn-icon-circle btn-icon-circle-sm mb-2">
                                                                    <i class="fab fa-facebook-f"></i>
                                                                </button>
                                                                <p class="mb-0 fw-semibold">Facebook</p>
                                                                <h4 class="m-0 fw-bold">25k <span
                                                                        class="text-muted font-12 fw-normal">Followers</span>
                                                                </h4>
                                                            </div>
                                                            <div class="col-auto">
                                                                <button type="button"
                                                                    class="btn btn-soft-info btn-icon-circle btn-icon-circle-sm mb-2">
                                                                    <i class="fab fa-twitter"></i>
                                                                </button>
                                                                <p class="mb-0 fw-semibold">Twitter</p>
                                                                <h4 class="m-0 fw-bold">58k <span
                                                                        class="text-muted font-12 fw-normal">Followers</span>
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    </div> -->
                        </div><!--end row-->
                    </div><!--end f_profile-->
                </div><!--end card-body-->

            </div>

        </div>
        <div class="card">
            <div class="card-body">


                <div class="row ">
                    <div class="col-lg-12">
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" data-bs-toggle="tab" href="#home" role="tab"
                                    aria-selected="true">Information</a>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link" data-bs-toggle="tab" href="#Remarks" role="tab"
                                    aria-selected="false">Case Type</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-bs-toggle="tab" href="#contract" role="tab"
                                    aria-selected="false">Authority Letter</a>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link" data-bs-toggle="tab" href="#PaymentVoucher" role="tab"
                                    v-on:click="paymentVoucherClick()" aria-selected="false">Payment</a>
                            </li>
                            <li class="nav-item"
                                v-if="newCustomer.id != '00000000-0000-0000-0000-000000000000' && havePermission('Contact Email') && !isCaseUser">
                                <a class="nav-link" data-bs-toggle="tab" href="#email" role="tab"
                                    aria-selected="false">Email</a>
                            </li>

                        </ul>
                        <div class="tab-content">
                            <div class="tab-pane p-3 active" id="home" role="tabpanel">
                                <div class="card-body">
                                    <div class="dastone-profile">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <table class="body-wrap"
                                                    style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; font-size: 14px; width: 100%; background-color: transparent; margin: 0;"
                                                    bgcolor="transparent">
                                                    <tbody>
                                                        <tr>
                                                            <td valign="top"></td>
                                                            <td class="container" width="600"
                                                                style="display: block !important; max-width: 600px !important; clear: both !important;"
                                                                valign="top">
                                                                <div class="content" style="padding: 20px;">
                                                                    <table class="main" width="100%" cellpadding="0"
                                                                        cellspacing="0">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td class="content-wrap aligncenter"
                                                                                    style="padding: 20px; background-color: transparent;"
                                                                                    align="center" valign="top">
                                                                                    <table width="100%" cellpadding="0"
                                                                                        cellspacing="0">
                                                                                        <tbody>
                                                                                            
                                                                                            <tr>
                                                                                                <td class="content-block aligncenter"
                                                                                                    style="padding: 0 0 20px;"
                                                                                                    align="center"
                                                                                                    valign="top">
                                                                                                    <table
                                                                                                        class="invoice"
                                                                                                        style="width: 80%;">
                                                                                                        <tbody>
                                                                                                            
                                                                                                            <tr>
                                                                                                                <td style="padding: 5px 0;"
                                                                                                                    valign="top">
                                                                                                                    <table
                                                                                                                        class="invoice-items"
                                                                                                                        cellpadding="0"
                                                                                                                        cellspacing="0"
                                                                                                                        style="width: 100%;">
                                                                                                                        <tbody>
                                                                                                                            <tr>
                                                                                                                                <td style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; font-size: 14px; vertical-align: top;   margin: 0; padding: 10px 0;"
                                                                                                                                    valign="top">Name
                                                                                                                                </td>
                                                                                                                                <td class="alignright"
                                                                                                                                    style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; font-size: 14px; vertical-align: top; text-align: right;   margin: 0; padding: 10px 0;"
                                                                                                                                    align="right"
                                                                                                                                    valign="top">
                                                                                                                                    {{ newCustomer.englishName }}
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                            <tr>
                                                                                                                                <td style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; font-size: 14px; vertical-align: top; border-top-width: 1px; border-top-color: #eee; border-top-style: solid; margin: 0; padding: 10px 0;"
                                                                                                                                    valign="top">
                                                                                                                                    Date of Birth
                                                                                                                                </td>
                                                                                                                                <td class="alignright"
                                                                                                                                    style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; font-size: 14px; vertical-align: top; text-align: right; border-top-width: 1px; border-top-color: #eee; border-top-style: solid; margin: 0; padding: 10px 0;"
                                                                                                                                    align="right"
                                                                                                                                    valign="top">{{ newCustomer.registrationDate }}
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                            <tr>
                                                                                                                                <td style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; font-size: 14px; vertical-align: top; border-top-width: 1px; border-top-color: #eee; border-top-style: solid; margin: 0; padding: 10px 0;"
                                                                                                                                    valign="top">
                                                                                                                                    Phone
                                                                                                                                </td>
                                                                                                                                <td class="alignright"
                                                                                                                                    style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; font-size: 14px; vertical-align: top; text-align: right; border-top-width: 1px; border-top-color: #eee; border-top-style: solid; margin: 0; padding: 10px 0;"
                                                                                                                                    align="right"
                                                                                                                                    valign="top">{{ newCustomer.contactNo1 }}
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                            <tr>
                                                                                                                                <td style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; font-size: 14px; vertical-align: top; border-top-width: 1px; border-top-color: #eee; border-top-style: solid; margin: 0; padding: 10px 0;"
                                                                                                                                    valign="top">
                                                                                                                                    Email
                                                                                                                                </td>
                                                                                                                                <td class="alignright"
                                                                                                                                    style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; font-size: 14px; vertical-align: top; text-align: right; border-top-width: 1px; border-top-color: #eee; border-top-style: solid; margin: 0; padding: 10px 0;"
                                                                                                                                    align="right"
                                                                                                                                    valign="top">{{ newCustomer.email }}
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                            <tr>
                                                                                                                                <td style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; font-size: 14px; vertical-align: top; border-top-width: 1px; border-top-color: #eee; border-top-style: solid; margin: 0; padding: 10px 0;"
                                                                                                                                    valign="top">
                                                                                                                                    Address
                                                                                                                                </td>
                                                                                                                                <td class="alignright"
                                                                                                                                    style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; font-size: 14px; vertical-align: top; text-align: right; border-top-width: 1px; border-top-color: #eee; border-top-style: solid; margin: 0; padding: 10px 0;"
                                                                                                                                    align="right"
                                                                                                                                    valign="top">{{ newCustomer.billingAddress }}
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                            
                                                                                                                        </tbody>
                                                                                                                    </table>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </td>
                                                                                            </tr>
                                                                                            
                                                                                        </tbody>
                                                                                    </table><!--end table-->
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table><!--end table-->
                                                                </div><!--end content-->
                                                            </td>
                                                            <td style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; font-size: 14px; vertical-align: top; margin: 0;"
                                                                valign="top"></td>
                                                        </tr>
                                                    </tbody>
                                                </table><!--end table-->
                                            </div>

                                        </div><!--end row-->
                                    </div><!--end f_profile-->
                                </div><!--end card-body-->

                            </div>
                            <div class="tab-pane p-3" id="profile" role="tabpanel">


                            </div>
                            <div class="tab-pane p-3" id="settings" role="tabpanel">

                                //Details

                            </div>
                            <div class="tab-pane p-3" id="Address" role="tabpanel">

                                //Address

                            </div>


                            <div class="tab-pane p-3" id="Remarks" role="tabpanel">

                                <div class="row">
                                    <div class="row">
                                        <div class="form-group col-4">
                                            <label>
                                                Signature Type :
                                            </label>
                                            <div>
                                                <multiselect v-model="newCustomer.signatureType"
                                                    :preselect-first="false" :options="['name', 'image']"
                                                    :show-labels="false" placeholder="Select Signature Type">
                                                </multiselect>
                                            </div>
                                        </div>
                                        <div class="form-group col-4">
                                            <label>
                                                Case Type :
                                            </label>
                                            <div>
                                                <productMasterdropdown :values="newCustomer.productId"
                                                    v-model="newCustomer.productId">
                                                </productMasterdropdown>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">


                                        <div v-if="newCustomer.signatureType == 'name'" class="form-group col-4">
                                            <label>Name</label>
                                            <input class="form-control" v-model="newCustomer.signatureName"
                                                type="text" />
                                        </div>

                                        <div class="form-group col-4" v-if="newCustomer.signatureType == 'image'">
                                            <div :key="renderImg">
                                                <div class="input-group mb-3"
                                                    v-if="!((imageSrc == '' && newCustomer.signaturePath != '') || (imageSrc != '' && newCustomer.signaturePath == '') || (imageSrc != '' && newCustomer.signaturePath != ''))">
                                                    <input ref="imgupload" type="file" class="form-control"
                                                        id="inputGroupFile02" @change="uploadImage()" accept="image/*"
                                                        name="image">
                                                </div>



                                                <div class="text-right " v-if="imageSrc != ''">
                                                    <img v-if="imageSrc != ''" class="float-right" :src="imageSrc"
                                                        width="100" />
                                                </div>
                                                <div v-else class="text-right ">
                                                    <img v-if="newCustomer.signaturePath != null && newCustomer.signaturePath != ''"
                                                        class="float-right"
                                                        :src="'data:image/png;base64,' + newCustomer.signaturePath"
                                                        width="100" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-sm-12"
                                            v-if="imageSrc != '' || newCustomer.signaturePath != ''">
                                            <div class="text-right">
                                                <button class="btn btn-danger  btn-sm" v-on:click="removeImage()">{{
                        $t('AddCurrency.Remove')
                    }}</button>
                                            </div>
                                        </div>



                                    </div>
                                </div>

                            </div>
                            <div class="tab-pane p-3" id="contract" role="tabpanel">
                                <div class="row">
                                    <div class="modal-header">
                                        <button type="button" class="btn btn-primary"
                                            v-on:click="generatePDF()">PDF</button>
                                        <button type="button" class="btn-close" v-on:click="close()"></button>
                                    </div>
                                    <div style="max-width: 800px;
                                        margin: auto;
                                        background: #fff;
                                        padding-top: 20px;
                                        padding-bottom: 30px;
                                        padding-left: 80px;
                                        padding-right: 80px;
                                        " ref="mychildcomponent" id='inventoryDetailReport'>
                                        <div style="text-align: center;
  margin-bottom: 20px;">
                                            <strong>{{ company.name }}</strong>
                                            <div v-html="company.address"></div>
                                            <div>Phone:{{ company.phoneNo }}</div>
                                            <div>Email: {{ company.companyEmail }}</div>
                                        </div>

                                        <hr style="color:black">

                                        <div style="text-decoration: underline;
  text-align: center;
  margin-bottom: 20px;">
                                            LETTER OF AUTHORITY
                                        </div>

                                        <div style="margin-bottom: 20px;">

                                            <table width='100%' class="icon-info-table">
                                                <tbody>

                                                    <tr>
                                                        <td
                                                            style="width:5%;text-align:right !important;font-size: 16px;">
                                                            I,</td>

                                                        <td
                                                            style="width:60%;text-align:center !important;border-bottom: 1px solid black;">
                                                            <span style="font-weight: bold; font-size: 16px;"></span>{{
                        newCustomer.name }}
                                                        </td>

                                                        <td style="width:5%;text-align:right !important">
                                                            D.O.B</td>

                                                        <td
                                                            style="width:30%;text-align:center !important;border-bottom: 1px solid black;">
                                                            <span style=" font-size: 16px;">{{ newCustomer.dob }}</span>
                                                        </td>



                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style="width:5%;text-align:left !important;padding-top:20px;font-size: 14px;">
                                                            Address:</td>

                                                        <td colspan="3"
                                                            style="width:95%;text-align:left !important;padding-top:20px;padding-left:10px">
                                                            <span style=" font-size: 14px;"></span>{{
                        newCustomer.address
                    }}
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style="width:5%;text-align:left !important;padding-top:20px;font-size: 14px;">
                                                            Mobile:</td>

                                                        <td colspan="3"
                                                            style="width:95%;text-align:left !important;padding-top:20px;padding-left:10px">
                                                            <span style=" font-size: 14px;"></span>{{ newCustomer.mobile
                                                            }}
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td
                                                            style="width:5%;text-align:left !important;padding-top:20px;font-size: 14px;">
                                                            Email:</td>

                                                        <td colspan="3"
                                                            style="width:95%;text-align:left !important;padding-top:20px;padding-left:10px">
                                                            <span style=" font-size: 14px;"></span>{{ newCustomer.email
                                                            }}
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td colspan="4"
                                                            style="width:95%;text-align:left !important;padding-top:20px;font-size: 14px;letter-spacing: inherit;">
                                                            <span style=" font-size: 15px;"></span>Hereby authorise
                                                            {{ company.name }} of {{ company.address }}
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td colspan="4" style="width:95%;text-align:left !important;">
                                                            <span style=" font-size: 15px;"></span>4DU to deal with my
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td colspan="3"
                                                            style="width:90%;text-align:left !important;border-bottom: 1px solid black;">
                                                            <span style=" font-size: 15px;"></span>{{ newCustomer.matter
                                                            }}
                                                        </td>
                                                        <td
                                                            style="width:5%;text-align:left !important;padding-left:10px">
                                                            <span style=" font-size: 15px;">I also authorised</span>
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td colspan="4"
                                                            style="width:95%;text-align:left !important;padding-left:10px">
                                                            <span style=" font-size: 14px;"></span>J Saimon Ltd to pass
                                                            my details where necessary for the
                                                            application.
                                                        </td>

                                                    </tr>


                                                </tbody>
                                            </table>

                                            <table width='100%' style="margin-top: 40px;width: 100%;"
                                                class="icon-info-table">

                                                <tr>
                                                    <td
                                                        style="width:5%;text-align:left !important;padding-top:130px;font-size: 14px;">
                                                        Signature:</td>

                                                    <td
                                                        style="width:35%;border-bottom: 1px solid;text-align:center !important;padding-top:87px;padding-left:10px">
                                                        <img :src="newCustomer.signature"
                                                            style="width:auto;max-width:88px; max-height:94px">

                                                    </td>
                                                    <td colspan="2" style="width:60%;">

                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        style="width:5%;text-align:left !important;padding-top:20px;font-size: 14px;">
                                                        Name:</td>

                                                    <td
                                                        style="width:35%;border-bottom: 1px solid;text-align:left !important;padding-top:20px;padding-left:10px">
                                                        <span style=" font-size: 14px;"></span>{{
                        newCustomer.printedName
                    }}
                                                    </td>
                                                    <td colspan="2" style="width:60%;">

                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        style="width:5%;text-align:left !important;padding-top:20px;font-size: 14px;">
                                                        Date:</td>

                                                    <td
                                                        style="width:35%;border-bottom: 1px solid;
                                                                        text-align:left !important;padding-top:20px;padding-left:10px">
                                                        <span style=" font-size: 14px;"></span>{{ newCustomer.date }}
                                                    </td>
                                                    <td colspan="2" style="width:60%;">

                                                    </td>
                                                </tr>
                                            </table>

                                        </div>




                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane p-3" id="Sale" role="tabpanel">
                                <div class="row">



                                </div>
                            </div>
                            <div class="tab-pane p-3" id="PaymentVoucher" role="tabpanel">


                                //Payment

                            </div>


                        </div>
                        <div class="tab-content tab-space tab-subcategories">

                        </div>

                    </div>
                </div>

                <loading :active.sync="loading" :can-cancel="true" :is-full-page="true"></loading>


            </div>
            <div v-if="!loading" class=" col-lg-12 invoice-btn-fixed-bottom">
                <div class="row">
                    <div v-if="!loading" class=" col-md-12">
                        <div class="button-items"
                            v-if="newCustomer.id == '00000000-0000-0000-0000-000000000000' && isValid('CanAddCustomer')">
                            <button class="btn btn-outline-primary" v-bind:disabled="$v.newCustomer.$invalid"
                                v-if="newCustomer.id == '00000000-0000-0000-0000-000000000000' && isValid('CanAddCustomer')"
                                v-on:click="SaveCustomer"><i class="far fa-save "></i> {{ $t('AddCustomer.btnSave')
                                }}</button>
                            <button class="btn btn-danger" v-on:click="Cancel">{{ $t('AddCustomer.Cancel') }}</button>
                        </div>
                        <div class="button-items" v-else-if="roleName == 'User' && formName == 'Profile'">
                            <button class="btn btn-outline-primary" v-bind:disabled="$v.newCustomer.$invalid"
                                v-if="newCustomer.id != '00000000-0000-0000-0000-000000000000' && isValid('CanEditCustomer')"
                                v-on:click="SaveCustomer"><i class="far fa-save "></i> {{ $t('AddCustomer.btnUpdate')
                                }}</button>
                        </div>
                        <div class="button-items" v-else>

                            <button class="btn btn-outline-primary" v-bind:disabled="$v.newCustomer.$invalid"
                                v-if="newCustomer.id != '00000000-0000-0000-0000-000000000000' && isValid('CanEditCustomer')"
                                v-on:click="SaveCustomer"><i class="far fa-save "></i> {{ $t('AddCustomer.btnUpdate')
                                }}</button>
                            <button class="btn btn-danger" v-on:click="Cancel">{{ $t('AddCustomer.Cancel') }}</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import clickMixin from '@/Mixins/clickMixin'
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import moment from 'moment'
import Multiselect from 'vue-multiselect'
import {
    required,
    maxLength,
} from 'vuelidate/lib/validators';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default ({
    mixins: [clickMixin],
    components: {
        Loading,
        Multiselect
    },
    data: function () {
        return {
            printPurchaseRender: 0,
            showPurchasePrint: false,
            allowBranches: false,
            showPV: false,
            saleList: [],
            vouchersList: [],
            rendr: 0,

            company: {
                logoPath: '',
                businessName: localStorage.getItem('nameEnglish'),
                name: localStorage.getItem('companyNameEnglish'),
                phoneNo: localStorage.getItem('companyPhoneNo'),
                companyEmail: localStorage.getItem('companyEmail'),
                address: localStorage.getItem('addressEnglish'),
            },


            folderList: [{
                name: 'All',
                isActive: true,
            }],
            attachmentListFolder: [],
            imageSrc: '',
            renderImg: 0,
            roleName: '',
            formName: '',
            priceLabelId: '',
            isCustomerPriceLabel: false,
            arabic: '',
            show: false,
            isFolder: false,
            show1: false,
            show2: false,
            multipleAddress: false,
            type: '',
            isCashCustomer: false,
            b2b: false,
            b2c: false,
            english: '',
            contactCode: '',
            cashCustomerCode: '',
            colorObj: '',
            download: false,
            isPrint: false,
            printDetails: [],
            isSkype: false,
            printRender: 0,
            salutatioRender: 0,
            randerInput: 0,
            randerDate: 0,
            newAddress: {
                id: '',
                area: '',
                address: '',
                city: '',
                country: '',
                billingZipCode: '',
                latitude: '',
                langitutue: '',
                fromTime: '',
                toTime: '',
                billingPhone: '',
                deliveryHolidays: [],
                type: '',
                add: 'Add',
                isActive: false,
                isDefault: false,
                isOffice: false,
                allHour: false,
                allDaySelection: false,
            },

            newCustomer: {
                id: '00000000-0000-0000-0000-000000000000',
                code: '',
                multipleAddress: false,
                isAddressOnAll: false,
                priceLabelId: '',
                prefix: '',
                englishName: '',
                arabicName: '',
                companyNameEnglish: '',
                companyNameArabic: '',
                customerDisplayName: '',
                telephone: '',
                email: '',
                registrationDate: '',
                category: '',
                customerType: '',
                customerGroup: '',
                contactNo1: '',
                accountId: '',

                commercialRegistrationNo: '',
                vatNo: '',
                customerGroupId: '',
                currencyId: '',
                regionId: '',
                taxRateId: '',
                customerCode: '',
                website: '',

                billingAttention: '',
                billingCountry: '',
                billingZipCode: '',
                billingPhone: '',
                billingArea: '',
                billingAddress: '',
                billingCity: '',
                billingFax: '',

                shippingAttention: '',
                shippingCountry: '',
                shippingZipCode: '',
                shippingPhone: '',
                shippingArea: '',
                shippingAddress: '',
                shippingCity: '',
                shippingFax: '',

                contactPersonList: [{
                    prefix: '',
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    mobile: ''
                }],
                deliveryAddressList: [],
                productList: [],
                remarks: '',
                isCustomer: true,
                isVendor: false,
                isActive: true,
                isCashCustomer: true,

                paymentTerms: '',
                deliveryTerm: '',
                creditLimit: '',
                creditPeriod: '',

                attachmentList: [],
                signatureType: '',
                signatureName: '',
                signaturePath: '',
                productId: '',
                profileImage: '',
                isCaseCustomer: true
            },
            loading: false,
            language: 'Nothing',
            showView: false,
            documentpath: '',
            emailCompoese: {
                EmailTo: [],
                subject: '',
                description: '',
                companyName: '',
                buttonName: '',
                emailPath: '',
            },
            ccOptions: [],
            isCaseUser: false
        }
    },
    validations: {

        newCustomer: {
            customerType: {
            },
            englishName: {
                required,

                maxLength: maxLength(250)
            },
            arabicName: {
                maxLength: maxLength(250)
            },
            vatNo: {

                maxLength: maxLength(250)
            },
            paymentTerms: {
            },
            customerDisplayName: {
            },
        }
    },

    methods: {
        addProduct: function (productId) {
            this.newCustomer.productId = productId;
        },

        removeImage: function () {
            this.imageSrc = '';
            this.currency.image = '';
            this.renderImg++;

        },
        uploadImage: function () {
            var root = this;
            var token = '';
            if (root.$session.exists()) {
                token = localStorage.getItem('token');
            }

            var file = this.$refs.imgupload.files;

            var fileData = new FormData();

            for (var k = 0; k < file.length; k++) {
                fileData.append("files", file[k]);
            }

            this.imageSrc = URL.createObjectURL(this.$refs.imgupload.files[0]);

            root.$https.post('/Company/UploadFilesAsync', fileData, { headers: { "Authorization": `Bearer ${token}` } })
                .then(function (response) {
                    if (response.data != null) {

                        root.currency.image = response.data;
                    }
                },
                    function () {
                        this.loading = false;
                        root.$swal({
                            title: root.$t('AddCurrency.Error'),
                            text: root.$t('AddCurrency.SomethingWrong'),
                            type: 'error',
                            confirmButtonClass: "btn btn-danger",
                            buttonsStyling: false
                        });
                    });
        },
        async generatePDF() {
            const html = document.querySelector('#inventoryDetailReport'); // Element to be converted
            const canvas = await html2canvas(html);
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'pt',
                format: 'a4'
            });
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
            pdf.save('download.pdf');
        },



        onClose: function () {
            this.showPv = false;
            this.paymentVoucherClick();
        },


        CloseModel: function () {
            this.showView = false;
        },
        getDate: function (date) {
            return moment(date).format('LLL');
        },




        PrintView: function () {
            this.show2 = true;

        },



        GetPriceLabeling: function (val) {
            this.newCustomer.priceLabelId = val.id;
        },
        toDecimal: function (price) {
            return parseFloat(price).toFixed(3).slice(0, -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
        },

        isCashCustomerFunc: function () {

            if (this.newCustomer.isCashCustomer) {

                this.newCustomer.customerType = 'Individual';
                this.newCustomer.paymentTerms = 'Cash';

            } else {
                this.newCustomer.customerType = '';
                this.newCustomer.paymentTerms = 'Credit';
            }
        },
        OpenFolder: function () {
            debugger;

            this.isFolder = true;
            this.show = true;
        },


        GetAutoCodeGenerator: function () {
            var root = this;
            var token = '';
            if (root.$session.exists()) {
                token = localStorage.getItem('token');
            }

            root.$https
                .get('/Contact/AutoGenerateCode?customerType=' + this.formName, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                }).then(function (response) {
                    if (response.data != null) {

                        root.contactCode = response.data;
                        root.randerInput++;

                    }
                });
        },



        SaveCustomer: function () {

            this.newCustomer.code = this.contactCode;
            this.newCustomer.customerDisplayName = this.newCustomer.englishName;

            if (this.newCustomer.id == '00000000-0000-0000-0000-000000000000') {
                localStorage.setItem('ContactPage', 1);
                localStorage.setItem('CurrentActiveContact', this.newCustomer.isCustomer ? true : false);
            }
            this.loading = true;
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }



            this.newCustomer.isCaseCustomer = true;
            root.$https
                .post('/Contact/SaveContact', this.newCustomer, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then(response => {
                    if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.action == "Add") {
                        root.loading = false
                        root.info = response.data.bpi

                        root.$swal({
                            title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved Successfully' : 'حفظ بنجاح',
                            text: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved' : 'تم الحفظ',
                            type: 'success',
                            confirmButtonClass: "btn btn-success",
                            buttonStyling: false,
                            icon: 'success',
                            timer: 1500,
                            timerProgressBar: true,

                        }).then(function (ok) {
                            if (ok != null) {
                                if (root.isValid('CanViewCustomer')) {
                                    root.$router.push({
                                        path: '/Customer',
                                        query: { formName: root.formName }
                                    })
                                } else {
                                    root.$router.go();
                                }
                            }
                        });
                    } else if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.action == "Update") {
                        root.loading = false
                        root.info = response.data.bpi

                        root.$swal({
                            title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved Successfully' : 'حفظ بنجاح',
                            text: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved' : 'تم الحفظ',
                            type: 'success',
                            confirmButtonClass: "btn btn-success",
                            buttonStyling: false,
                            icon: 'success',
                            timer: 1500,
                            timerProgressBar: true,

                        }).then(function (ok) {
                            if (ok != null) {
                                if (root.isValid('CanViewCustomer')) {
                                    root.$router.push({
                                        path: '/Customer',
                                        query: { formName: root.formName }
                                    })
                                } else {
                                    root.$router.go();
                                }
                            }
                        });
                    } else {
                        root.$swal({
                            title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                            text: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'There is something wrong. Please contact to support.' : 'هناك شيء ما خاطئ. يرجى الاتصال للدعم.',
                            type: 'error',
                            confirmButtonClass: "btn btn-danger",
                            icon: 'error',
                            timer: 1500,
                            timerProgressBar: true,
                        });
                    }

                })
                .catch(error => {
                    console.log(error)
                    root.$swal.fire({
                        icon: 'error',
                        title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                        text: error.response.data,
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    });

                    root.loading = false
                })
                .finally(() => root.loading = false)
        },

        AddRow: function () {

            this.newCustomer.contactPersonList.push({
                prefix: '',
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                mobile: ''
            });

        },

        createUUID: function () {

            var dt = new Date().getTime();
            var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = (dt + Math.random() * 16) % 16 | 0;
                dt = Math.floor(dt / 16);
                return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
            return uuid;
        },
        RemoveRow: function (index) {
            this.newCustomer.contactPersonList.splice(index, 1);
        },



        RemoveRow1: function (index) {
            this.newCustomer.deliveryAddressList.splice(index, 1);
        },

        Cancel: function () {
            this.$router.push({
                path: '/Customer',
                query: { formName: this.formName }
            })

        },

        getCustomerDetail: function () {
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            var email = localStorage.getItem('UserName');

            this.$https.get('/Contact/ContactDetail?caseCustomer=true' + '&email=' + email, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                if (response.data != null) {
                    debugger;
                    console.log(response.data)
                    root.newCustomer = response.data;
                    root.formName = 'Profile';
                    root.newCustomer.customerType = 'Profile'
                    root.newCustomer.name = response.data.englishName;
                    root.newCustomer.dob = response.data.registrationDate;
                    root.newCustomer.address = response.data.billingAddress;
                    root.newCustomer.mobile = response.data.telephone;
                    root.newCustomer.email = response.data.email;
                    root.newCustomer.matter = response.data.remarks;
                    root.newCustomer.signature = 'data:image/png;base64,' + response.data.signaturePath;
                    root.newCustomer.printedName = response.data.signatureName;
                    root.newCustomer.date = response.data.signatureDate;
                    //root.newCustomer.signatureType = (response.data.signatureType == undefined || response.data.signatureType == null)?'':response.data.signatureType;
                    // root.newCustomer.signatureName = (response.data.signatureName == undefined || response.data.signatureName == null)?'':response.data.signatureName;
                    // root.newCustomer.signaturePath = response.data.signaturePath;
                    // root.newCustomer.productId = response.data.productId;
                    // root.newCustomer.profileImage = response.data.profileImage;
                }

            });
        },





    },
    created: function () {


        this.roleName = localStorage.getItem('RoleName');
        if (this.$route.query.formName == 'CaseCustomer' && this.roleName == 'User') {
            this.getCustomerDetail();




        }
        else {
            this.formName = this.$route.query.formName;
            this.newCustomer.customerType = this.formName
            this.$emit('input', this.$route.name)
            if (this.$route.query.data != undefined) {
                this.newCustomer = this.$route.query.data;
                this.cashCustomerCode = this.newCustomer.code;
                this.contactCode = this.newCustomer.code;
                this.newCustomer.attachmentList.forEach(x => {
                    const existingFolder = this.folderList.find(folder => folder.name === x.description);

                    // If not found, push to folderList
                    if (!existingFolder) {
                        this.folderList.push({
                            name: x.description,
                            isActive: false
                        });
                    }

                });
                this.randerInput++;

            } else {
                this.GetAutoCodeGenerator();
                this.newCustomer.registrationDate = moment().format('llll');
                if (localStorage.getItem('IsCustomeCredit') != 'true') {
                    this.newCustomer.paymentTerms = (this.$i18n.locale == 'en' || this.isLeftToRight()) ? 'Cash' : 'نقد'
                } else {
                    this.newCustomer.paymentTerms = (this.$i18n.locale == 'en' || this.isLeftToRight()) ? 'Credit' : 'آجل'
                }


            }

        }


    },
    mounted: function () {
        this.newCustomer.isCustomer = this.formName == 'Customer' ? true : false;
        this.newCustomer.isCashCustomer = this.newCustomer.isCustomer ? this.newCustomer.isCashCustomer : false
        this.isCaseUser = localStorage.getItem('IsCaseUser') == 'true' ? true : false;
        this.language = this.$i18n.locale;
        this.english = 'true';
        this.arabic = localStorage.getItem('Arabic');

        this.multipleAddress = localStorage.getItem('MultipleAddress') == 'true' ? true : false;

        this.isCustomerPriceLabel = localStorage.getItem('IsCustomerPriceLabel') == 'true' ? true : false;

    }
})
</script>
